import {useState, useCallback, useEffect} from "react";

const storageName = 'userData';

export const useAuth = () => {
    const [jwt, setJwt] = useState(null);
    const [userId, setUserId] = useState(null);
    const [ready, setReady] = useState(false);
    const [hasActiveTariff, setHasActiveTariff] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [isDevShow, setIsDevShow] = useState(process.env.REACT_APP_DOMAIN === 'dev.wolbe.ru');

    const login = useCallback((jwtToken, id, activeTariff = true) => {
        setJwt(jwtToken);
        setUserId(id);
        setHasActiveTariff(activeTariff);
        console.log('setAuth, activeTariff ==> ', activeTariff);
        localStorage.setItem(storageName, JSON.stringify({
            userId: id, jwt: jwtToken, activeTariff: activeTariff
        }))
        // errorMessage: "JWT Token expired"
    }, [])

    const logout = useCallback(() => {
        setJwt(null);
        setUserId(null);
        localStorage.removeItem(storageName);
        document.location.href = '/';
    }, [])

    const user = useCallback((data) => {
        setUserInfo(data);
    }, [])

    const setShowDev = useCallback(() => {
        setIsDevShow((prev) => !prev);
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))
        if (data && data.jwt) {
            login(data.jwt, data.userId, data.activeTariff);
        }
        setReady(true)
    }, [login])

    return {login, logout, jwt, hasActiveTariff, userId, ready, user, userInfo, isDevShow, setShowDev};
}
