import React from "react";
import { connect } from 'react-redux';
import * as actions from '../actions/index'
import { createSelector } from "reselect";
import {Link} from "react-router-dom";

const getItems = (state, itemKey, filterName, storeName) => {
    if(state.searchParams[storeName].filtersState[filterName] === undefined) {
        return false;
    }
    return state.searchParams[storeName].filtersState[filterName][itemKey] !== undefined ? state.searchParams[storeName].filtersState[filterName][itemKey] : false;
};

const inputValSelector = createSelector(
    [getItems],
    (item) => {
        return item;
    }
)

const getStateByName = (state, stateLocation, storeName) => {
    return state[stateLocation][storeName];
}
const stateByName = createSelector(
    [getStateByName],
    (items) => {
        return {...items};
    }
)

const mapStateToProps = (state, props) => {
    const storeName = props.storeName;
    const open = props.open;
    const setOpen = props.setOpen;
    const allParamsResponse = stateByName(state, 'searchParams', storeName);
    const allParams = allParamsResponse.allParams;

    const varsCount = props.varsCount;
    const paramName = props.paramName;
    const filterName = props.filterName;

    const inputVal = inputValSelector(state, paramName, filterName, storeName);

    return {inputVal, paramName, allParams, varsCount, open, setOpen};
};

const actionCreators = {
    setSearchParam: actions.setSearchParam
};

class FilterCheckbox extends React.Component {
    handleChange = (itemKey, currentVal, filterName, varsCount, storeName) => () => {
        if(varsCount === 0) {
            return
        }
        const {setSearchParam} = this.props;
        this.props.setOpen(filterName, true);
        setSearchParam({itemKey, currentVal, filterName, storeName});
    }

    render() {
        let { inputVal, paramName, filterName, allParams, varsCount, storeName } = this.props;
        // const isActive = uiState.active.filter((key) => key === filterName).length > 0;
        // console.log(isActive);
        let newAllParams = [...allParams];
        newAllParams = newAllParams.filter((param) => param.indexOf('page') < 0 && param.indexOf('limit') < 0);
        if(inputVal || varsCount === 0) {
            newAllParams = newAllParams.filter((param) => param.indexOf(paramName) < 0);
        } else {
            newAllParams.push(`${filterName}=${paramName}`)
        }
        return (
            <div className={`drop-filter-item ${varsCount === 0 ? 'not-active' : ''}`}>
                {varsCount === 0 &&
                    <span className={`check option`} >
                        <input type="checkbox" className="checkbox" id={paramName} required="" checked={inputVal} onChange={() => {}}/>
                        <label htmlFor={paramName} className="checkbox-a">{`${paramName} (${varsCount})`}</label>
                    </span>
                }
                {varsCount !== 0 &&
                    <Link to={`?${newAllParams.join('&')}`} className={`check option`} onClick={this.handleChange(paramName, inputVal, filterName, varsCount, storeName)}>
                        <input type="checkbox" className="checkbox" id={paramName} required="" checked={inputVal} onChange={() => {}}/>
                        <label htmlFor={paramName} className="checkbox-a">{`${paramName} (${varsCount})`}</label>
                    </Link>
                }

            </div>
        )
    }
}

export default connect(mapStateToProps, actionCreators)(FilterCheckbox);
