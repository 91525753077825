import React from "react";

const getColoredValue = (value) => {
  if (value > 0) {
    return (
      <span className='color-green'>
        {value}
      </span>
    );
  } else if (value < 0) {
    return (
      <span className='color-red'>
        {value}
      </span>
    );
  } else {
    return (
      <span>
        {value}
      </span>
    );
  }
}

const getColoredLabel = (label, value) => {
  if (value > 0) {
    return (
      <span className='color-green'>
        {label}
      </span>
    );
  } else if (value < 0) {
    return (
      <span className='color-red'>
        {label}
      </span>
    );
  } else {
    return (
      <span>
        {label}
      </span>
    );
  }
}

export const Profit = ({ profit, profitDiff, fees, feesDiff }) => {

  return (
    <div className="dash-card-main-wrap">
      <div className="flex-col round-dash-card flex-center">
        <h4>
          Остатки
        </h4>

        <div className="dash-card-inner-wrap flex-col">
          <div className="flex-space-between">
            <span>
              {getColoredLabel('прибыль', profitDiff)}
            </span>
            <span>
              {profit}
            </span>
          </div>

          <div className="flex-end profit-additional-span">
            {getColoredValue(profitDiff)}
          </div>

          <div className="flex-space-between">
            <span>
              {getColoredLabel('комиссия', feesDiff)}
            </span>
            <span>
              {fees}
            </span>
          </div>

          <div className="flex-end profit-additional-span">
            {getColoredValue(feesDiff)}
          </div>
        </div>

      </div>
    </div>
  )
}