import React, { useState } from 'react';

const GoodsImg = React.memo(({ img }) => {
    console.log('pichka', img)
  const [imgActive, setActive] = useState(false);
  return (
      <React.Fragment>
          <img className="goods-img" src={img} alt="" onClick={() => setActive(true)} />
          {imgActive &&
              <div className="popup img-popup">
                  <div className="popup-content">
                      <img src={`${img.split('/tm').join('/c252x336')}`} alt="" onClick={() => setActive(true)} />
                  </div>
                  <div className="popup-bg" onClick={() => setActive(false)} />
              </div>
          }
      </React.Fragment>
  )
})

export default GoodsImg;