// @ts-check
import React from 'react';
import { render } from 'react-dom';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk'
import {applyMiddleware, compose, createStore} from "redux";
import reducers from './reducers/index.js';
import App from './App';

const store = createStore(
    reducers, compose(
        applyMiddleware(
            thunk
        ),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('wolbe-container'),
);
