import React, { useState, useContext, useEffect } from 'react';
import GoodsImg from '../GoodsImg';

export const GoodItem = ({ data }) => {

  return (
    <div className="an-row">
      <div className="an-col-6 goods-img-block">
        <div className="goods-item-img">
          <GoodsImg img={data.img} />
        </div>
        <div className="goods-description">
          <div className="prop-item">
            название: {data.name}
          </div>
          <div className="prop-item">
            id: {data.id}
          </div>
        </div>
      </div>

      <div className="an-col-5">
        <div className="goods-description">
          <div className="prop-item">
            {data.costPrice}
          </div>
        </div>
      </div>
      
      <div className="an-col-5">
        <div className="goods-description">
          <div className="prop-item">
            {data.price}
          </div>
        </div>
      </div>
      
      <div className="an-col-4">
        <div className="goods-description">
          <div className="prop-item">
            {data.profit}
          </div>
        </div>
      </div>
      
      <div className="an-col-4">
        <div className="goods-description">
          <div className="prop-item">
            {data.count} шт.
          </div>
        </div>
      </div>
    </div>
  )
}
