import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {createSelector} from "reselect";
import * as actions from "../actions";
import {Link} from "react-router-dom";
import DatePickerCalendarWolbe from "./DatePickerCalendarWolbe";
import {BsArrowDownUp, BsArrowDown, BsArrowUp} from 'react-icons/bs';

const SalesTypeSelector = ({cardConfig, keyConfig, uiState: {selectors}, setSelectorsUiState}) => {
    let selectorConfig = {};
    cardConfig[keyConfig].CardItems.map((item) => Object.keys(item).map((key) => {
        if(key === 'salesTypeSelector') {selectorConfig = item[key]}
    }))
    if(Object.keys(selectors).length === 0) {
        let firstActive = Object.keys(selectorConfig.uiStateValues)[0]
        // setSelectorsUiState({selector: selectorConfig.uiStateName, value: firstActive})
        return null
    }
    const filterStateValue = selectors[selectorConfig.uiStateName]
    if(!selectorConfig.uiStateValues) {
        return null
    }
    return (
        <div className="sales-type-selector">
            {Object.keys(selectorConfig.uiStateValues).map((key) => (
                <div className={`type-selector-item ${filterStateValue === key ? 'active' : ''}`}
                     key={`salesTypeSelector-${key}`}
                     onClick={() => filterStateValue !== key ? setSelectorsUiState({selector: selectorConfig.uiStateName, value: key}) : null}>
                    {selectorConfig.uiStateValues[key].name}
                </div>
            ))}
        </div>
    )
};
const CheckAll = ({onClick, name, subName, itemKeys, storeName, searchParams}) => {
    const [checkAll, setCheckAll] = useState(false);
    useEffect(() => {
        setCheckAll(false)
    },[searchParams])
    const clickHandler = () => {
        onClick(itemKeys, storeName, checkAll)
        setCheckAll((prev) => !prev)
    }
    return (
        // <div className="sort-check-all">
        //     <div className="goods-item-check">
        //         <div className="check option">
        //             <input type="checkbox" className="checkbox" id={`sort-check`} checked={checkAll} onChange={() => clickHandler()}/>
        //             <label htmlFor={`sort-check`} className="checkbox-a">{}</label>
        //         </div>
        //     </div>
        //     <div className="sorting-action under-dashed" onClick={() => clickHandler()}>{!checkAll ? name : subName}</div>
        // </div>
        <div className="sorting-action under-dashed" onClick={() => clickHandler()}>{!checkAll ? name : subName}</div>
    )

};

const RangeSort = ({cardConfig, keyConfig, uiState, setUiState}) => {
    const isActive = uiState.active.filter((key) => key === 'rangeCost').length > 0;
    return (
        <>
            {isActive &&
            <div className="sorting-cost-range-bg" onClick={() => setUiState('rangeCost')}>{}</div>
            }
            <div className="sorting-action">
                <span className="action-val under-dashed" onClick={() => setUiState('rangeCost')}>{cardConfig[keyConfig].sortingItem.sortingAction.name}</span>
                {isActive &&
                <div className="sorting-cost-range">
                    <div className="input-block">
                        <div className="range-input">
                            <div className="range-label">от</div>
                            <input type="text" />
                        </div>
                        <div className="range-input">
                            <div className="range-label">до</div>
                            <input type="text" />
                        </div>
                    </div>
                </div>

                }
            </div>
        </>

    )
}

const CalendarWrapper = ({allSearchParams, setDateParams, storeName, uiState, setUiState}) => {
    const handleClick = (filterName) => {
        setUiState(filterName);
    }

    return (
        <DatePickerCalendarWolbe setOpen={handleClick}
                                 uiState={uiState}
                                 minDate={false}
                                 dateFrom={''}
                                 dateTo={''}
                                 allSearchParams={allSearchParams}
                                 setDateParams={setDateParams}
                                 storeName={storeName}
                                 type={'sort'}
                                 key={'sort'}
                                 filterType={'calendar'}
        />
    )
}

const PredictDaysWrapper = ({actionVal, allSearchParams, setPredictDaysParams, defaultVal, predictDays, storeName, uiState, setUiState}) => {
    let [predictDaysVal, setPredictDays] = useState(!!predictDays ? predictDays : defaultVal);
    let [predictCustomDaysVal, setPredictCustomDays] = useState(defaultVal);
    const handleClick = () => {
        setUiState('predict_days');
    }
    let newAllSearchParams = [...allSearchParams];
    newAllSearchParams = newAllSearchParams.filter((param) =>  param.indexOf('predict_days') < 0 && param.indexOf('page') < 0 && param.indexOf('limit') < 0);
    const isActive = uiState.active.filter((key) => key === 'predict_days').length > 0;
    return (
        <div className="filter-item">
            <span className={`action-val cal-right under-dashed ${isActive ? 'filter-open' : ''}`} onClick={() => handleClick()}>Прогноз на: {predictDaysVal === 'customRange' ? predictCustomDaysVal : predictDaysVal} д.</span>
            <div className="drop-filter">
                <div className="drop-filter-bg" onClick={() => handleClick()}>{}</div>
                <div className="drop-filter-cal">
                    <div className="cal-radio-group">
                        {actionVal.map((item) => {
                            if (item !== 'customRange') {
                                return (
                                   <div className="radio-block" key={`radio-${item}`}>
                                       <input type="radio"
                                              name={`predict-radio`}
                                              className="custom-radio"
                                              id={`radio-${item}`}
                                              checked={predictDaysVal === item}
                                              onChange={() => setPredictDays(item)}
                                       />
                                       <label htmlFor={`radio-${item}`}>
                                           <div className="label-group">
                                               <div className="label-h">{item} д.</div>
                                               {/*<div className="label-txt">Для сезонных товаров</div>*/}
                                           </div>
                                       </label>
                                   </div>
                                )
                            }
                            if (item === 'customRange') {
                                return (
                                    <div className="radio-block with-input" key={`radio-${item}`}>
                                        <div>
                                            <input type="radio"
                                                   name={`predict-radio`}
                                                   className="custom-radio"
                                                   id={`radio-${item}`}
                                                   checked={predictDaysVal === item}
                                                   onChange={() => setPredictDays(item)}
                                            />
                                            <label htmlFor={`radio-${item}`}>
                                                <div className="label-group">
                                                    <div className="label-h">Свой период</div>
                                                    {/*<div className="label-txt">Для сезонных товаров</div>*/}
                                                </div>
                                            </label>
                                        </div>
                                        <div className="range-input">
                                            <div className="range-label">на</div>
                                            <input type="text" value={predictCustomDaysVal} onChange={(e) => {
                                                setPredictDays(item);
                                                setPredictCustomDays(e.target.value);
                                            }}/>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return <></>
                            }
                        })}
                    </div>
                    <div className="cal-btn-block">
                        {/*<Link to={`?${newAllSearchParams.join('&')}&date_from=${reFormatDate(startDate)}&date_to=${reFormatDate(endDate)}`}*/}
                        {/*      className="date-submit btn btn-blue"*/}
                        {/*      onClick={() => {*/}
                        {/*          setDateParams({*/}
                        {/*              date_from: reFormatDate(startDate),*/}
                        {/*              date_to: reFormatDate(endDate),*/}
                        {/*              storeName: storeName*/}
                        {/*          });*/}
                        {/*          setOpen(filterType, true)}*/}
                        {/*      }*/}
                        {/*>Применить</Link>*/}
                        <Link to={`?${newAllSearchParams.join('&')}&predict_days=${predictDaysVal === 'customRange' ? predictCustomDaysVal : predictDaysVal}`}
                              className="date-submit btn btn-blue"
                              onClick={() => {
                                  handleClick()
                                  setPredictDaysParams(
                                      {
                                          storeName: storeName,
                                          predictDays: predictDaysVal === 'customRange' ? predictCustomDaysVal : predictDaysVal
                                        }
                                  );
                              }}
                        >
                            Применить
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const getGoods = (state, stateLocation, storeName) => state[stateLocation][storeName].goods

const SimpleSorting = ({confParamVal, question, sortDirection, sortParam, allSearchParams, onClick, name, storeName}) => {
    let newSortDirection = sortParam === confParamVal ? sortDirection : '';
    const isActive = sortParam.indexOf(confParamVal) >= 0; // переписать проверку. может быть -ByName1 - тогда единица будет мешать
    const sortLink = `sort=${newSortDirection === '' ? '-' : ''}${confParamVal}`;
    const isDescending = sortDirection === '' ? false : true;
    let newAllSearchParams = [...allSearchParams];
    newAllSearchParams = newAllSearchParams.filter((param) => param.indexOf('sort') < 0 && param.indexOf('page') < 0 && param.indexOf('limit') < 0);
    let newLink = newAllSearchParams;
    newLink.push(sortLink);

    return (
        <div className={'sorting-block'}>
            <Link to={`?${newLink.join('&')}`}
                  onClick={onClick(confParamVal, isActive, newAllSearchParams, storeName)}
                  className={`sorting-toggle`} >
                <span>{`${name}`}</span>
                <div className="sorting-direction">
                    {!isActive && <BsArrowDownUp />}
                    {isActive && isDescending && <BsArrowDown />}
                    {isActive && !isDescending && <BsArrowUp />}
                </div>
            </Link>
            {!!question && <span className="question" data-txt={question}><i
                className="an-ico an-ico-question">{}</i></span>}
        </div>
    )
}
const MultiSorting = ({storeName, sortParam, allSearchParams, confParamValues, onClick, sortDirection, uiState, name, setUiState}) => {
    const isDropActive = uiState.active.filter((key) => key === `multiSort${name}`).length > 0;
    const sortParams = Object.keys(confParamValues);
    let newAllSearchParams = [...allSearchParams];
    newAllSearchParams = newAllSearchParams.filter((param) => param.indexOf('sort') < 0 && param.indexOf('page') < 0 && param.indexOf('limit') < 0);
    const newLink = newAllSearchParams;
    const isAnyActive = sortParams.indexOf(sortParam) >= 0;
    const activeSortParam = isAnyActive ? sortParam : null;
    const newSortDirection = sortParam === activeSortParam ? sortDirection : '';
    const isDescending = newSortDirection === '' ? false : true;
    return (
        <div className="multi-sort">
            <i className="an-ico an-ico-tune" onClick={() => setUiState(`multiSort${name}`)}/>
            {!!activeSortParam &&
            <Link to={`?${newLink.join('&')}&sort=${sortDirection === '' ? '-' : ''}${activeSortParam}`}
                  onClick={onClick(activeSortParam, isAnyActive, newAllSearchParams, storeName)}
                  className={`sorting-toggle`} >
                <span>{confParamValues[activeSortParam]}</span>
                <div className="sorting-direction">
                    {!isAnyActive && <BsArrowDownUp />}
                    {isAnyActive && isDescending && <BsArrowDown />}
                    {isAnyActive && !isDescending && <BsArrowUp />}
                </div>
            </Link>
            }
            {!activeSortParam &&
            <span onClick={() => setUiState(`multiSort${name}`)}
                  className={`sorting-toggle`} >
                <span>{name}</span>
                <div className="sorting-direction">
                    {!isAnyActive && <BsArrowDownUp />}
                    {isAnyActive && isDescending && <BsArrowDown />}
                    {isAnyActive && !isDescending && <BsArrowUp />}
                </div>
            </span>
            }
            {isDropActive &&
            <div className="drop-filter">
                <div className="drop-filter-bg" onClick={() => setUiState(`multiSort${name}`)} />
                <div className="drop-sort-select">
                    {sortParams.map((param, index) => {
                        const isActive = sortParams.indexOf(activeSortParam) >= 0 && param === sortParam;
                        const sortLink = `sort=${isActive ? sortDirection === '' ? '-' : '' : '-'}${param}`;
                        return (
                            <Link key={`${param}-${index}`} to={`?${newLink.join('&')}&${sortLink}`} onClick={onClick(param, isActive, newAllSearchParams, storeName)} className={isActive ? 'color-blue' : ''}>
                                {confParamValues[param]}
                            </Link>
                        )
                    })}
                </div>
            </div>
            }
        </div>
    )
}

const goodsKeys = createSelector(
    [getGoods],
    (goods) => {
        let goodsKeys = [];
        goods.forEach((goodsGroup) => {
            if(Object.keys(goodsGroup).indexOf('products') > 0) {
                goodsGroup['products'].forEach((product) => {
                    goodsKeys.push(product['uniq_key']);
                })
            } else {
                goodsKeys.push(goodsGroup.uniq_key);
            }
        })
        return goodsKeys
    }
)

const getStateByName = (state, stateLocation, storeName) => {
    return state[stateLocation][storeName];
}
const stateByName = createSelector(
    [getStateByName],
    (items) => {
        return {...items};
    }
)

const mapStateToProps = (state, props) => {

    const storeName = props.settings.storeName;
    const itemKeys = goodsKeys(state, 'listingPage', storeName);
    const uiState = state.uiState;

    const loading = state.listingPage.loading; // НАДО УБРАТЬ

    const searchParams = stateByName(state, 'searchParams', storeName);
    const sortParam = searchParams.sort;
    const allSearchParams = searchParams.allParams;
    const predictDays = searchParams.predictDays;
    const sortDirection = searchParams.sortDirection;

    return {itemKeys, uiState, loading, sortParam, sortDirection, allSearchParams, storeName, predictDays};
};

const actionCreators = {
    setCheckboxVal: actions.setCheckboxVal,
    fetchData: actions.fetchData,
    setSortParam: actions.setSortParam,
    forceClose: actions.forceClose,
    setDateParams: actions.setDateParams,
    setPredictDaysParams: actions.setPredictDaysParams,
    setUiState: actions.setUiState,
    setSelectorsUiState: actions.setSelectorsUiState,
};

class SortingBar extends React.Component {

    handleCheckAll = (itemKeys, storeName, currentVal = false) => {
        const {setCheckboxVal} = this.props;
        itemKeys.forEach((itemKey) => {
            setCheckboxVal({itemKey, currentVal, storeName})
        })
    }

    handleUiChange = (filterName) => {
        const {setUiState} = this.props;
        setUiState({selector: filterName});
    }

    handleClick = (search, isActive, newAllSearchParams, storeName) => () => {
        const {setSortParam, forceClose} = this.props;
        forceClose()
        setSortParam({search, isActive, newAllSearchParams, storeName});
    }
    render() {
        const {itemKeys, cardConfig, sortDirection, uiState, predictDays, allSearchParams, setPredictDaysParams, setDateParams, sortParam, storeName, setSelectorsUiState, loading} = this.props;
        return (
            <div className="sorting-bar">
                <div className="an-row">
                    {Object.keys(cardConfig).map((keyConfig) => {
                        return (
                            <div className={cardConfig[keyConfig].class} key={`sort-col-${keyConfig}`}>
                                <div className="btn-sorting">
                                    {cardConfig[keyConfig].sortingItem.type === 'simple' &&
                                    <SimpleSorting
                                        storeName={storeName}
                                        allSearchParams={allSearchParams}
                                        sortDirection={sortDirection}
                                        confParamVal={cardConfig[keyConfig].sortingItem.val}
                                        sortParam={sortParam}
                                        onClick={this.handleClick}
                                        name={cardConfig[keyConfig].sortingItem.name}
                                        question={cardConfig[keyConfig].sortingItem.question}
                                        />
                                    }
                                    {cardConfig[keyConfig].sortingItem.type === 'multi' &&
                                    <MultiSorting confParamValues={cardConfig[keyConfig].sortingItem.val}
                                                  storeName={storeName}
                                                  sortParam={sortParam[0] === '-' ? sortParam.slice(1) : sortParam}
                                                  allSearchParams={allSearchParams}
                                                  onClick={this.handleClick}
                                                  name={cardConfig[keyConfig].sortingItem.name}
                                                  sortDirection={sortDirection}
                                                  uiState={uiState}
                                                  setUiState={this.handleUiChange}
                                    />
                                    }
                                    {cardConfig[keyConfig].sortingItem.sortingAction.actionType === "checkAll" &&
                                        <CheckAll onClick={(itemKeys, storeName, currentVal) => this.handleCheckAll(itemKeys, storeName, currentVal)}
                                                  name={cardConfig[keyConfig].sortingItem.sortingAction.name}
                                                  subName={cardConfig[keyConfig].sortingItem.sortingAction.subName}
                                                  itemKeys={itemKeys}
                                                  storeName={storeName}
                                                  searchParams={allSearchParams} />
                                    }
                                    {cardConfig[keyConfig].sortingItem.sortingAction.actionType === "rangeCost" &&
                                        <RangeSort
                                            cardConfig={cardConfig}
                                            keyConfig={keyConfig}
                                            uiState={uiState}
                                            setUiState={this.handleUiChange}
                                        />
                                    }

                                    {cardConfig[keyConfig].sortingItem.sortingAction.actionType === "salesTypeSelector" &&
                                        <SalesTypeSelector cardConfig={cardConfig} keyConfig={keyConfig} uiState={uiState} setSelectorsUiState={setSelectorsUiState}/>
                                    }
                                    {cardConfig[keyConfig].sortingItem.sortingAction.actionType === "calendar" &&
                                        <CalendarWrapper
                                            allSearchParams={allSearchParams}
                                            setDateParams={setDateParams}
                                            storeName={storeName}
                                            uiState={uiState}
                                            setUiState={this.handleUiChange}
                                        />
                                    }
                                    {cardConfig[keyConfig].sortingItem.sortingAction.actionType === "predict-days" &&
                                        <PredictDaysWrapper
                                            actionVal={cardConfig[keyConfig].sortingItem.sortingAction.actionVal}
                                            allSearchParams={allSearchParams}
                                            defaultVal={cardConfig[keyConfig].sortingItem.sortingAction.defaultVal}
                                            setPredictDaysParams={setPredictDaysParams}
                                            predictDays={predictDays}
                                            storeName={storeName}
                                            uiState={uiState}
                                            setUiState={this.handleUiChange}
                                        />
                                    }
                                    {/*{cardConfig[keyConfig].sortingItem.sortingAction.actionType !== "checkAll" &&*/}
                                    {/*    <div className="sorting-action">{cardConfig[keyConfig].sortingItem.sortingAction.name}</div>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, actionCreators)(SortingBar)
