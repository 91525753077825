import React, {useState, useContext, useEffect} from 'react'
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import {useParams} from "react-router-dom";

export const ActivatePage = () => {
    const auth = useContext(AuthContext);
    const {request} = useHttp();
    const activateToken = useParams().id;
    const [form, setForm] = useState('activate');
    console.log(activateToken);
    const activateHandler = async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/activate/${activateToken}`, 'POST', {})
            if(data === 'reSend') {
                setForm(data)
            } else if (!!data) {
                auth.login(data.jwt, data.userId) // другие параметры
                localStorage.setItem('isNewAccount', 'true');
                setForm('confirm');
            } else {
                document.location.href = '/';
            }
        } catch (e) {
            console.log('error => ', e);
        }
    }
    const reSendHandler = async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/resend-activation/${activateToken}`, 'POST', {})
           if (!!data) {
               setForm('validate')
            } else {
                // document.location.href = '/';
            }
        } catch (e) {
            console.log('error => ', e);
        }
    }
    useEffect(() => {
        activateHandler();
    }, [])
    return (
        <div className="onboarding-wrapper">
            <div className="onboarding">
                <div className="onboarding-h">
                    Активация акаунта
                </div>
                <div className="onboarding-form">
                    {form === 'activate' &&
                        <p className="color-dark-blue">Пожалуйста подождите</p>
                    }
                    {form === 'reSend' &&
                        <>
                            <p className="color-dark-blue">Время действия ссылки истекло (по умолчанию токен действует 24 часа). Ссылка придет на указанный при регистрации e-mail.</p>
                            <div className="btn-block">
                                <button className="btn btn-blue" onClick={reSendHandler} >Отправить ссылку повторно</button>
                            </div>
                        </>
                    }
                    {form === 'confirm' &&
                        <>
                            <p className="color-dark-blue">Ваш почтовый адрес подтвержден</p>
                            <div className="btn-block">
                                <button className="btn btn-blue" onClick={() => document.location.href = '/'} >Авторизоваться</button>
                            </div>
                        </>
                    }
                    {form === 'validate' &&
                    <>
                        <div className="onboarding-form">
                            <p className="color-dark-blue">Ссылка отправлена повторно</p>
                            <p className="color-dark-blue">Пожалуйста пройдите по ссылке в письме, для активации акаунта</p>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

