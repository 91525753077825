import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHttp } from "../hooks/http.hook";
import { useInterval } from '../hooks/setUnterval.hook';
import { AuthContext } from "../context/AuthContext";
import { TokensContext } from "../context/TokensContext";
import {TariffContext} from "../context/TariffContext";
import {OnboardingContext} from "../context/OnboardingContext";
import LKWildberries from "../assets/lk.png";
import {Loader} from "../components/Loader";
import * as _ from 'lodash';

const keyLength = 48;

export const Tokens = () => {
    const {activeTariff} = useContext(TariffContext);
    const { jwt, isDevShow } = useContext(AuthContext);
    const { tokens, initTokens } = useContext(TokensContext);
    const { account } = useContext(OnboardingContext);
    const { request } = useHttp();
    const [addOpen, setAddOpen] = useState(false);
    const [tokenForm, setTokenForm] = useState('');
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('showAccOnboarding')) {
            setTimeout(() => account.openTour(), 0.5 * 1000);
        }
    }, []);

    const getTokens = useCallback(async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/token/`, 'GET', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            });
            if (!data.keys) {
                setAddOpen(true)
            }
            if (!_.isEqual(_.sortBy(tokens), _.sortBy(data.keys))) {
                initTokens(data.keys);
            }
        } catch (e) { }
    }, [jwt, request, tokens, initTokens]);

    useEffect(() => {
        getTokens()
    }, [getTokens]);

    useInterval(() => {
        getTokens();
    }, 4 * 1000);

    const addToken = async () => {
        if (tokenForm?.length === keyLength) {
            try {
                const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/token/`, 'POST', { key: tokenForm }, { 'Access-Control-Allow-Origin': '*', Authorization: `${jwt}` })
                console.log(data);
                initTokens([...tokens, data]);
                setTokenForm('');
                setAddOpen(false);
            } catch (e) { }
        }
    }
    const tokenHandler = (e) => {
        setTokenForm(e.target.value);
    }
    const delToken = (id) => async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/token/${id}`, 'DELETE', null, { 'Access-Control-Allow-Origin': '*', Authorization: `${jwt}` });
            console.log(data);
            let newTokens = [...tokens];
            newTokens = newTokens.filter((item) => item.id !== id);
            initTokens(newTokens);
        } catch (e) { }
    }

    return (
        <>
            {tokens &&  
                <>
                    <div className="an-col-16">
                        <div className="round-card bg-color-white">
                            {!!activeTariff &&
                                <>
                                    <h3>Ваши кабинеты</h3>
                                    {/*<p>По ключу мы сможем загрузить данные из вашего кабинета в Wolbe. </p>*/}
                                    {/*<div className="color-blue">Ключ для работы с API статистики x64</div>*/}
                                    {active &&
                                        <div className="popup img-popup">
                                            <div className="popup-content">
                                                <img src={LKWildberries} alt="" onClick={() => setActive(false)} />
                                            </div>
                                            <div className="popup-bg" onClick={() => setActive(false)} />
                                        </div>
                                    }
                                    <div className="flex-container">
                                        <p style={{ marginRight: '5px' }}>Скопируйте ключ из кабинета главного пользователя</p>
                                        <div  className="flex-container-row">
                                            <a href="https://seller.wildberries.ru/supplier-settings/access-to-api" target="_blank">
                                                <div className="color-blue cursor-pointer">Личный кабинет WB -> Мой профиль -> Доступ к API -> Ключ x64</div>
                                            </a>
                                            <span
                                                className="simple-question"
                                                style={{ marginBottom: "5px" }}
                                                onClick={() => setActive(true)}
                                            >
                                                <i className="an-ico an-ico-question" />
                                            </span>
                                        </div>
                                    </div>
                                    {/*<div className="color-blue">Ключ для работы с API статистики x64</div>*/}
                                    <div className="onboarding-form token-form">
                                        {tokens.map(token => {
                                            return (
                                                <div key={token.id}>
                                                    <label htmlFor={`wb-token-${token.id}`} className="flex-a-c">
                                                        <img src="/assets/images/wb-logo.svg" alt="" />
                                                        {`Токен WB #${token.id}`}
                                                    </label>
                                                    <div className="del-group">
                                                        <div className="form-input"
                                                            id={`wb-token-${token.id}`}
                                                        >{token.key}</div>
                                                        <i className="an-ico an-ico-close" onClick={delToken(token.id)}>{ }</i>
                                                    </div>
                                                    { 
                                                        isDevShow &&
                                                        token?.dataIsLoading && 
                                                        <div className="api-key-loading">
                                                            <Loader middle />
                                                            <span>
                                                                данные по этому ключу грузятся
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        {addOpen &&
                                            <>
                                                <label htmlFor="new-token">WB token</label>
                                                <div className="add-token">
                                                    <input
                                                        className="form-input new-token"
                                                        type="text"
                                                        id="new-token"
                                                        placeholder="Введите ключ API в формате Base64"
                                                        name="new_token"
                                                        value={tokenForm}
                                                        onChange={tokenHandler}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                addToken();
                                                            }
                                                        }}
                                                    />
                                                    {tokenForm?.length === keyLength ?
                                                        (
                                                            <i className="an-ico an-ico-done" onClick={addToken} />
                                                        ) : null}
                                                </div>
                                            </>
                                        }
                                        {
                                            (tokenForm && tokenForm?.length !== keyLength) && (
                                                <span className="not-valid">длина ключа должна быть {keyLength} символов</span>
                                            )
                                        }
                                        {!!tokens &&
                                            <div 
                                                className={`color-blue m-t-25 c-pointer ${addOpen ? 'd-none' : ''}`} 
                                                onClick={() => setAddOpen(true)}
                                                data-tut="wb_api"
                                            >
                                                + добавить новый
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {!activeTariff &&
                                <>
                                    <h3>Ваши кабинеты</h3>
                                    <p>Управление ключами, доступно на платном тарифе. </p>
                                </>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}
