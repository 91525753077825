import React from "react";

export const Remains = ({ ownPrice, retail, count }) => {

  return (
    <div className="dash-card-main-wrap">
      <div className="flex-col round-dash-card flex-center">
        <h4>
          Остатки
        </h4>
        <div className="dash-card-inner-wrap flex-col">
          <div className="flex-space-between">
            <span>
              себес.
            </span>
            <span>
              {ownPrice}
            </span>
          </div>
          <div className="flex-space-between">
            <span>
              розница
            </span>
            <span>
              {retail}
            </span>
          </div>
          <div className="flex-space-between">
            <span>
              штук
            </span>
            <span>
              {count}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}