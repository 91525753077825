// @ts-check

import { createAction } from 'redux-actions';

export const setRightMenuActive = createAction('SET_RIGHT_MENU_ACTIVE');

export const fetchDataRequest = createAction('FETCH_DATA_REQUEST');
export const fetchDataSuccess = createAction('FETCH_DATA_SUCCESS');
export const fetchDataFailure = createAction('FETCH_DATA_FAILURE');

export const fetchData = (url, storeName, method = 'GET', body = null, headers = {}) => async (dispatch) => {
    dispatch(fetchDataRequest({storeName}));
    headers['Access-Control-Allow-Origin'] = '*';
    // headers['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, DELETE';
    // headers['Origin'] = 'https://dev.wolbe.ru';
    try {
        const resp = await fetch(url, {method, body, headers})
        if(resp.status === 204) {
            dispatch(fetchDataFailure({storeName}));
            return
        }
        const data = await resp.json();
        const result = data.response.data;
        const filters = data.response.filters;
        const minDate = data.response['min_date'];
        const total = data.response.total;
        const totalOutOfStock = data.response['total_out_of_stock'];
        if (!resp.ok) {
            throw new Error(data.message || JSON.stringify(data) || 'что то не так')
        }
        dispatch(fetchDataSuccess({ result, filters, minDate, total, totalOutOfStock, storeName }));
    } catch (e) {
        dispatch(fetchDataFailure({storeName}));
        throw e
    }
};

export const setDemoData = (data) => async (dispatch) => {
    const result = data.response.data;
    const filters = data.response.filters;
    const minDate = data.response.min_date;
    const total = data.response.total;
    const totalOutOfStock = data.response.total_out_of_stock;
    const storeName = 'DeliveryForecast';

    dispatch(fetchDataSuccess({ result, filters, minDate, total, totalOutOfStock, storeName }));
}

export const initSearchStateParams = (search, storeName, pageState) => async (dispatch) => {
    dispatch(initSearchState({search, storeName, pageState}));
}

export const delInitStateParams = (storeName) => async (dispatch) => {
    dispatch(delInitState({storeName}));
}

export const delInputStateParams = (storeName) => async (dispatch) => {
    dispatch(delInputState({storeName}));
}

export const initConfig = createAction('INIT_CONFIG');

export const reloadListingPage = createAction('RELOAD_LISTING_PAGE');
export const setDisplayOption = createAction('SET_DISPLAY_OPTION');
export const setFileUploadTab = createAction('SET_FILE_UPLOAD_TAB');
export const setSimpleView = createAction('SET_SIMPLE_VIEW');

export const setInStockSelector = createAction('SET_IN_STOCK_SELECTOR');

export const setQuantityInput = createAction('SET_QUANTITY_INPUT');
export const setCheckboxVal = createAction('SET_CHECKBOX_VAL');
export const setSearchString = createAction('SET_SEARCH_STRING');

export const initSearchState = createAction('INIT_SEARCH_STATE');
export const delInitState = createAction('DEL_INIT_STATE');

export const delInputState = createAction('DEL_INPUT_STATE');

export const setSearchParam = createAction('SET_SEARCH_PARAM');
export const setSortParam = createAction('SET_SORT_PARAM');

export const setDateParams = createAction('SET_DATE_PARAMS');
export const setPredictDaysParams = createAction('SET_PREDICT_DAYS_PARAMS');

export const setPaginationParam = createAction('SET_PAGINATION_PARAMS');

export const setRegFormOpen = createAction('SET_REG_FORM_OPEN');
export const setAuthFormOpen = createAction('SET_AUTH_FORM_OPEN');

// export const getSearchParamsResult = createAction('GET_SEARCH_PARAMS_RESULT');


export const setUiState = createAction('SET_UI_STATE');
export const forceClose = createAction('FORCE_CLOSE');

export const setSelectorsUiState = createAction('SET_SELECTORS_UI_STATE');
