import React, {useState} from 'react';
import DatePickerCalendarWolbe from "./DatePickerCalendarWolbe";

// const actionCreators = {
//     setUiState: actions.setUiState,
//     initSearchStateParams: actions.initSearchStateParams,
// };

export const FilterBarDashboard = ({allSearchParams, filterDate, minDate, setDateHandler}) => {

    // componentDidMount() {
    //     const {initSearchStateParams} = this.props;
    //     initSearchStateParams(window.location.search, 'DashBoard');
    // }
    const [uiState, setUiState] = useState({active: []});

    const handleUiChange = (filterName) => {
        setUiState((prev) => {
            const isActive = prev.active.filter((key) => key === filterName).length > 0;
            return {
                ...prev,
                active: isActive ? [] : [filterName]
            }
        });
    }

    const storeName = 'DashBoard';

    // const allSearchParams = allParamsResponse.allParams;
    // const allSearchParams = [
    //     "api_key_id=44",
    //     // "date_to=2021-08-24",
    //     // "date_from=2021-08-17"
    // ]


    return (
        <div className="filter-bar">
            <div className="filter-items">
                <div className="filter-block">
                    <DatePickerCalendarWolbe setOpen={handleUiChange}
                                             uiState={uiState}
                                             minDate={minDate}
                                             dateFrom={filterDate['first_interval_date_from']}
                                             searchParamName={'first_interval_'}
                                             dateTo={filterDate['first_interval_date_to']}
                                             allSearchParams={allSearchParams}
                                             setDateParams={setDateHandler}
                                             storeName={storeName}
                                             filterType={'date-simple-first'}
                                             type={'filter'}
                    />
                </div>
                <div className="filter-block">
                    <DatePickerCalendarWolbe setOpen={handleUiChange}
                                             uiState={uiState}
                                             minDate={minDate}
                                             dateFrom={filterDate['second_interval_date_from']}
                                             searchParamName={'second_interval_'}
                                             dateTo={filterDate['second_interval_date_to']}
                                             allSearchParams={allSearchParams}
                                             setDateParams={setDateHandler}
                                             storeName={storeName}
                                             filterType={'date-simple-second'}
                                             type={'filter'}
                    />
                </div>
            </div>
        </div>
    )
}