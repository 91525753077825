import React, {PureComponent, useState} from "react";
import {GoodsCard} from "./GoodsCard";


export class GroupGoodsCard extends PureComponent {
    render() {
        let {
            cardConfig,
            extra,
            getSettingActivity,
            viewType,
            settings,
            cardCheckbox,
            item,
            itemKey,
            reloadListing,
            uiState,
            cardCheckboxItems,
        } = this.props

        const isGroup = Object.keys(item).indexOf('products') >= 0;
        if (isGroup) {
            const productsKeys = isGroup ? item['products'].map((product) => product.uniq_key ) : null;
            const productsInGroupCount = isGroup ? productsKeys.length : null;

            const keysCardCheckboxItems = Object.keys(cardCheckboxItems);
            const currentCheckBoxInGroup = productsKeys.filter((item) => keysCardCheckboxItems.indexOf(item) >= 0);

            const isGroupFull = productsKeys.length === currentCheckBoxInGroup.length

            return (
                <div key={`group-${itemKey}`} className={'goods-group'}>
                    {/*<div>{`Товаров в группе: ${productsInGroupCount}, ID группы: ${itemKey}`}</div>*/}
                    {productsInGroupCount > 1 &&
                    <GoodsCard
                        itemKey={itemKey}
                        cardConfig={cardConfig}
                        extra={extra}
                        getSettingActivity={getSettingActivity}
                        goodItem={item}
                        viewType={viewType}
                        settings={settings}
                        cardCheckbox={cardCheckbox}
                        groupHeader={true}
                        groupUniqKey={itemKey}
                        isGroupFull={isGroupFull}
                        reloadListing={reloadListing}
                        uiState={uiState}
                    />
                    }
                    {item['products'].map((goodItem, index) => (
                        <GoodsCard
                            key={`${itemKey}-${index}`}
                            itemKey={goodItem.uniq_key}
                            cardConfig={cardConfig}
                            extra={extra}
                            getSettingActivity={getSettingActivity}
                            goodItem={goodItem}
                            viewType={viewType}
                            settings={settings}
                            groupUniqKey={itemKey}
                            cardCheckbox={cardCheckbox}
                            reloadListing={reloadListing}
                            uiState={uiState}
                        />
                    ))}
                </div>
            )
        } else {
            return (
                <GoodsCard
                    itemKey={item.uniq_key}
                    cardConfig={cardConfig}
                    extra={extra}
                    getSettingActivity={getSettingActivity}
                    goodItem={item}
                    viewType={viewType}
                    settings={settings}
                    cardCheckbox={cardCheckbox}
                    reloadListing={reloadListing}
                    uiState={uiState}
                />
            )
        }
    }
}
