import React from 'react';

export const TableFilters = () => {

  return (
    <div className="an-row">
      <div className="an-col-6 goods-img-block">
        <div className="goods-description">
          <div className="search-input-block">
            <span onClick={() => console.log('search clicked')}>
                <i className="an-ico an-ico-search" />
            </span>
            <input
                className="btn-filter-input"
                placeholder='поиск товара'
                // onChange={(e) => setValue(e.target.value)}
                value={null}
                onKeyPress={(key) => {
                    if (key.key === "Enter") {
                      console.log('search clicked Enter')
                    }
                }}
            />

          </div>

        </div>
      </div>

      <div className="an-col-5">
        <div className="goods-description">
          <div className="prop-item">
          </div>
        </div>
      </div>
      
      <div className="an-col-5">
        <div className="goods-description">
          <div className="prop-item">
          </div>
        </div>
      </div>
      
      <div className="an-col-4">
        <div className="goods-description">
          <div className="prop-item">
          </div>
        </div>
      </div>
      
      <div className="an-col-4">
        <div className="goods-description">
          <div className="prop-item">
          </div>
        </div>
      </div>
    </div>
  )
}