import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { TokensContext } from "../context/TokensContext";
import { AuthContext } from "../context/AuthContext";
import { OnboardingContext } from "../context/OnboardingContext";

export const Navbar = () => {
    const { activeToken } = useContext(TokensContext);
    const { isDevShow } = useContext(AuthContext);
    const { account } = useContext(OnboardingContext);

    const [slim, setSlim] = useState(false)
    console.log('NAVBAR_render');
    return (
        <nav className={`navbar ${slim ? 'slim' : ''}`}>
            <div className="nav-header">
                {!!activeToken && !!activeToken.id &&
                    <NavLink to={`/delivery-forecast/?api_key_id=${activeToken.id}`}>
                        <img src="/assets/images/logo.svg" alt="" className="main-logo" />
                    </NavLink>
                }
                {!!activeToken && !activeToken.id &&
                    <img src="/assets/images/logo.svg" alt="" className="main-logo" />
                }
                <img src="/assets/images/mini-logo.svg" alt="" className="mini-logo" />
                <i className="an-ico an-ico-exit" onClick={() => setSlim((prev) => (!prev))}>{ }</i>
            </div>
            <div data-tut="choose_menu" className="nav-items">
                {(!!activeToken || account.isOpenTour) &&
                    <>
                        <div className="nav-item">
                            <i className="an-ico an-ico-category">{ }</i>
                            {!isDevShow &&
                                <div className="drop-title nav-disable under-construction">Панель управления</div>
                            }
                            {isDevShow &&
                                <NavLink to="/dashboard" className="nav-disable">Панель управления</NavLink>
                            }
                        </div>
                        <div className="nav-item nav-drop drop-open">
                            <i className="an-ico an-ico-buy">{ }</i>
                            <div className="drop-title">Поставки<i className="an-ico an-ico-drop-arrow">{ }</i></div>
                            <div className="drop-items">
                                <NavLink data-tut="delivery_forecast" to={`/delivery-forecast/?api_key_id=${activeToken?.id}`}>Прогноз поставок</NavLink>
                                {isDevShow &&
                                    <NavLink to={`/delivery-analytic/?api_key_id=${activeToken?.id}`}>Анализ логистики</NavLink>
                                }
                                {!isDevShow &&
                                    <div className="drop-title nav-disable under-construction">Анализ логистики</div>
                                }
                            </div>
                        </div>
                        {isDevShow && (
                            <div className="nav-item nav-drop drop-open">
                                <i className="an-ico an-ico-activity opacity-5" />
                                {!isDevShow &&
                                    <div className="drop-title under-construction">Анализ стоимости<i className="an-ico an-ico-drop-arrow">{ }</i></div>
                                }
                                {isDevShow &&
                                    <div className="drop-title">Анализ стоимости<i className="an-ico an-ico-drop-arrow">{ }</i></div>
                                }                           
                                <div className="drop-items">
                                    <NavLink to={`/price-report/?api_key_id=${activeToken?.id}`}>Анализ показателей</NavLink>
                                    <NavLink to={`/logistic-analytic/?api_key_id=${activeToken?.id}`}>Поиск цены</NavLink>
                                </div>
                            </div>
                        )}
                        {isDevShow && (
                            <div className="nav-item">
                                <i className="an-ico opacity-5 an-ico-work" />
                                {isDevShow &&
                                    <div className={'drop-title nav-disable'}>Конкурентный<br />анализ</div>
                                }
                                {/*<NavLink to="/">Конкурентный<br/>анализ</NavLink>*/}
                            </div>
                        )}
                    </>
                }
            </div>
            <div className="nav-footer">
                <small>Для связи с нами:</small>
                <div className="social-items">
                    <a href="https://t.me/Wolbe24" target="_blank" className="social-item"><i className="an-ico an-ico-f-telegram" /></a>
                    <a href="https://wa.me/79772812277" target="_blank" className="social-item"><i className="an-ico an-ico-WhatsApp" /></a>
                    <a href="mailto: hello@wolbe.ru" target="_blank" className="social-item"><i className="an-ico an-ico-email" /></a>
                    {/*<a href="/" className="social-item"><i className="an-ico an-ico-f-vk" /></a>*/}
                    {/*<a href="/" className="social-item"><i className="an-ico an-ico-f-facebook" /></a>*/}

                </div>
                <div style={{ paddingTop: 10 }}><small>E-mail: <a href="mailto: hello@wolbe.ru"></a>hello@wolbe.ru</small></div>
            </div>
        </nav>
    )
}
