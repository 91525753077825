import {useCallback, useState} from "react";
import {useHttp} from "./http.hook";

export const useTariff = () => {
  const [activeTariff, setActiveTariff] = useState(null);  
  const {request} = useHttp();

  const getActiveTariff = useCallback(async(jwt) => {
    try {
        const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/user-tariff/`, 'GET', null, {
            'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
        });
        setActiveTariff(data);
    } catch (e) {}
},[request]);

  return {activeTariff, setActiveTariff, getActiveTariff};
}
