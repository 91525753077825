import React, {useContext} from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/index'
import FilterCheckbox from "./FilterCheckbox";
import DatePickerCalendarWolbe from "./DatePickerCalendarWolbe";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import {SearchInput} from "./SearchInput";
import { AuthContext } from "../context/AuthContext";
import {Loader} from "./Loader";


const InStockSelector = ({inStockSelectorParams,
                             storeName,
                             setInStockSelector,
                             allSearchParams,
                             uiState,
                             setUiState}) => {

    const handleClick = (storeName, selector) => {
        setUiState('inStockSelector');
        setInStockSelector(
            {
                storeName,
                selector
            }
        );
    }

    const selectorLang = {
        in_stock: 'В продаже',
        out_of_stock: 'Закончились',
        archived: 'Архивные',
    }

    const isActive = uiState.active.filter((key) => key === 'inStockSelector').length > 0;

    return (
        <div data-tut="finished_in_sale" className="filter-item">
            <div className={`btn-mini-filter ${isActive ? 'filter-open' : ''}`} onClick={() => setUiState('inStockSelector')}>
                {selectorLang[inStockSelectorParams]}
                <i className="an-ico an-ico-drop-arrow">{}</i>
            </div>
            <div className="drop-filter">
                <div className="drop-filter-selection">
                    <div className="radio-group">
                        {Object.keys(selectorLang).map((key) => {
                            let newAllParams = [];
                            newAllParams.push(`selector=${key}`);
                            if (allSearchParams.length > 0) {
                                allSearchParams.forEach(e => e.indexOf('selector') < 0 && newAllParams.push(e))
                            }
                            return (
                                <Link to={`?${newAllParams.filter((param) => param.indexOf('page') < 0 && param.indexOf('limit') < 0).join('&')}`} className={`check option`} onClick={() => handleClick(storeName, key)} key={`selector-${key}`}>
                                    <div className="radio-block" >
                                        <input type="radio"
                                               name="s-radio"
                                               className="custom-radio"
                                               id={`s-${key}`}
                                               checked={inStockSelectorParams === key}
                                               onChange={() => handleClick(key)}
                                        />
                                        <label htmlFor={`s-${key}`}>
                                            <div className="label-group">
                                                <div className="label-h">{selectorLang[key]}</div>
                                            </div>
                                        </label>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
                <div className="drop-filter-bg" onClick={() => setUiState('inStockSelector')} />
            </div>
        </div>
    )
}

const FilterBlock = ({filters, minDate, filterBlockConfig, filterDate, allSearchParams, setDateParams, storeName, uiState, setUiState}) => {

    const { isDevShow } = useContext(AuthContext);
    
    const initFilterStateFn = () => {
        let initFilterState = {};
        // console.log(filterBlockConfig);
        filterBlockConfig.forEach((blockItem) => {
            if(blockItem === 'filter') {
                Object.keys(filters).forEach((filterName) => {
                    initFilterState[filterName] = false;
                });
            } else {
                initFilterState = {
                    ...initFilterState,
                    [blockItem]: false
                }
            }
        })

        // initFilterState.calendar = false;
        return initFilterState;
    }

    let open = initFilterStateFn();
    // console.log(uiState);
    const handleClick = (filterName) => {

        setUiState(filterName);
        // console.log(uiState);
        // console.log(filterName);
        // console.log(forceClose);
        // // let newFilterState = {};
        // // console.log(filters);
        // // let filtersKeys = Object.keys(filters);
        // // filtersKeys.push('calendar');
        // // filtersKeys.forEach((key) => {
        // //     newFilterState[key] = false;
        // //     if(key === filterName) {
        // //         // console.log(forceClose);
        // //         // console.log(forceClose ? false : !open[key]);
        // //         newFilterState[key] = forceClose ? false : !open[key];
        // //     }
        // // });
        // // setOpen(newFilterState);
        // let newFilterState = {};
        // Object.keys(open).forEach((filterItem) => {
        //     newFilterState[filterItem] = false;
        //     if(filterItem === filterName) {
        //         newFilterState[filterItem] = forceClose ? false : !open[filterItem];
        //     }
        // })
        // setOpen({
        //     ...newFilterState
        // })
    }
    const filterLang = {
        brand: 'Бренды',
        category: 'Категории',
        subject: 'Предметы',
        warehouseName: 'Склады'
    }

    return (
        <>
            {filterBlockConfig.map((filterType) => (
                <div className="filter-block" key={`filter-block-${filterType}`}>
                    {filterType === "filter" && Object.keys(filters).map((filterName) => {
                        const isActive = uiState.active.filter((key) => key === filterName).length > 0;
                        if (filterName === 'warehouseName' && !isDevShow) {
                            return null
                        }
                        if (filterName === 'search') {
                            return (
                                <div className="filter-item" key={`filter-block-${filterType}-${filterName}`}>
                                    <SearchInput storeName={storeName}/>
                                </div>
                            )
                        }
                        return (
                            <div className="filter-item" key={`ff-${filterName}`}>
                                <div 
                                    className={`btn-filter active ${isActive ? 'filter-open' : ''}`} 
                                    onClick={() => handleClick(filterName)}
                                >
                                    {filterLang[filterName]}
                                    <i
                                        className="an-ico an-ico-drop-arrow"
                                    >
                                        {}
                                    </i>
                                </div>
                                <div className="drop-filter">
                                    <div className="drop-filter-items">
                                        {Object.keys(filters[filterName]).map((paramName) => {
                                            return(
                                                <FilterCheckbox open={open}
                                                                setOpen={handleClick}
                                                                filterName={filterName}
                                                                paramName={paramName}
                                                                varsCount={filters[filterName][paramName]}
                                                                storeName={storeName}
                                                                key={paramName}/>
                                            )
                                        })}
                                    </div>
                                    <div className="drop-filter-bg" onClick={() => handleClick()}>{}</div>
                                </div>
                            </div>
                        )
                    })}
                    {filterType === "date-space" &&
                        <div className="date-space">{}</div>
                    }
                    {filterType === "date-simple" &&
                    <DatePickerCalendarWolbe setOpen={handleClick}
                                             uiState={uiState}
                                             minDate={minDate}
                                             dateFrom={filterDate.dateFrom}
                                             searchParamName={''}
                                             dateTo={filterDate.dateTo}
                                             allSearchParams={allSearchParams}
                                             setDateParams={setDateParams}
                                             storeName={storeName}
                                             filterType={filterType}
                                             type={'filter'}
                                             key={`data-p-${filterType}`}
                    />
                    }
                    {filterType === "date-simple-first" &&
                    <DatePickerCalendarWolbe setOpen={handleClick}
                                             uiState={uiState}
                                             minDate={minDate}
                                             dateFrom={filterDate.firstIntervalDateFrom}
                                             searchParamName={'first_interval_'}
                                             dateTo={filterDate.firstIntervalDateTo}
                                             allSearchParams={allSearchParams}
                                             setDateParams={setDateParams}
                                             storeName={storeName}
                                             filterType={filterType}
                                             type={'filter'}
                                             key={`data-p-${filterType}`}
                    />
                    }
                    {filterType === "date-simple-second" &&
                    <DatePickerCalendarWolbe setOpen={handleClick}
                                             uiState={uiState}
                                             minDate={minDate}
                                             dateFrom={filterDate.secondIntervalDateFrom}
                                             firstDateFrom={filterDate.firstIntervalDateFrom}
                                             searchParamName={'second_interval_'}
                                             dateTo={filterDate.secondIntervalDateTo}
                                             firstDateTo={filterDate.firstIntervalDateTo}
                                             allSearchParams={allSearchParams}
                                             setDateParams={setDateParams}
                                             storeName={storeName}
                                             filterType={filterType}
                                             type={'filter'}
                                             key={`data-p-${filterType}`}
                    />
                    }
                </div>
            ))}
        </>
    )
}

const getStateByName = (state, stateLocation, storeName) => {
    return state[stateLocation][storeName];
}
const stateByName = createSelector(
    [getStateByName],
    (items) => {
        return {...items};
    }
)

const mapStateToProps = (state, props) => {
    const storeName = props.settings.storeName;
    const filterBarConfig = props.filterBarConfig;
    const uiState = state.uiState;

    const allParamsResponse = stateByName(state, 'searchParams', storeName);
    const filterDate = allParamsResponse.filterDate;
    const allSearchParams = allParamsResponse.allParams;
    const inStockSelectorParams = allParamsResponse.inStockSelectorState;

    const listingPage = stateByName(state, 'listingPage', storeName);
    const filters = listingPage.filters;
    const noData = listingPage.noData;
    const viewType = listingPage.displayConfig.viewType;
    const minDate = listingPage.minDate;
    const total = listingPage.total;
    const loading = listingPage.loading;
    const totalOutOfStock = listingPage.totalOutOfStock;

    return {filters, minDate, total, totalOutOfStock, loading, filterBarConfig, filterDate, allSearchParams, viewType, storeName, inStockSelectorParams, uiState, noData};

}
const actionCreators = {
    setRightMenuActive: actions.setRightMenuActive,
    setDateParams: actions.setDateParams,
    setSimpleView: actions.setSimpleView,
    setInStockSelector: actions.setInStockSelector,
    setUiState: actions.setUiState,
};

class FilterBar extends React.Component {

    handleUiChange = (filterName) => {
        const {setUiState} = this.props;
        setUiState({selector: filterName});
    }

    handleOpenMenu = () => {
        const {setRightMenuActive} = this.props;
        setRightMenuActive({isActive: true, type: 'view-config'});
    }
    handleChangeView = (type, storeName) => () => {
        const {setSimpleView} = this.props;
        setSimpleView({type: type, storeName} )
    }
    render() {
        const {viewType, filters, minDate, total, loading, totalOutOfStock, filterBarConfig, allSearchParams, setDateParams, filterDate, storeName, inStockSelectorParams, setInStockSelector, uiState, noData} = this.props;

        return (
            <div key={`filter-bar`} className="filter-bar">
                {Object.keys(filterBarConfig).map((item) => {
                    if(item === 'filter') {
                        return (
                            <div data-tut="filters" key={`filter-item-${item}`} className="filter-items" key={`view-items-${item}`}>
                                <FilterBlock filters={filters}
                                             minDate={minDate}
                                             filterDate={filterDate}
                                             allSearchParams={allSearchParams}
                                             setDateParams={setDateParams}
                                             storeName={storeName}
                                             filterBlockConfig={filterBarConfig[item]}
                                             uiState={uiState}
                                             setUiState={this.handleUiChange}
                                             key={`filter-${item}`}
                                />
                            </div>
                        )
                    }
                    if(item === 'view-items') {
                        return (
                            <div key={`view-items-${item}`} className="view-items">
                                {filterBarConfig[item].map((viewItem) => (
                                    <div className="view-item" key={`view-item-${viewItem}`}>
                                        {viewItem === 'active' &&
                                            <InStockSelector inStockSelectorParams={inStockSelectorParams}
                                                             uiState={uiState}
                                                             setUiState={this.handleUiChange}
                                                             storeName={storeName}
                                                             setInStockSelector={setInStockSelector}
                                                             allSearchParams={allSearchParams}
                                            />
                                        }
                                        {viewItem === 'count' && !noData &&
                                        <div className="goods-count border-space-left">
                                            {loading ? 
                                                <Loader small />
                                                : <>
                                                    {inStockSelectorParams === 'in_stock' ?
                                                        <>
                                                            <span className={'color-green'}>
                                                                {total}
                                                            </span>

                                                            <span className={'padding-h-10'}>/</span>

                                                            <span className={'color-red'}>
                                                                {totalOutOfStock}
                                                            </span>
                                                        </>
                                                        : <span>
                                                            {total}
                                                        </span>
                                                    }
                                                </>
                                            }
                                            
                                        </div>
                                        }
                                        {viewItem === 'view-toggle' &&
                                        <div data-tut="view_types" className="view-toggle">
                                            <div className={`btn-toggle view-list ${viewType === 'shortView' ? 'active' : ''}`} onClick={this.handleChangeView('shortView', storeName)}>
                                                <i className="an-ico an-ico-view-short" /></div>
                                            <div className={`btn-toggle view-list ${viewType === 'simple' ? 'active' : ''}`} onClick={this.handleChangeView('simple', storeName)}>
                                                <i className="an-ico an-ico-view-list" /></div>
                                            <div className={`btn-toggle view-detail ${viewType === 'full' ? 'active' : ''}`} onClick={this.handleChangeView('full', storeName)}>
                                                <i className="an-ico an-ico-view-detail" /></div>
                                        </div>
                                        }
                                        {viewItem === 'view-settings' &&
                                        <div className="btn-mini-white" onClick={this.handleOpenMenu}>
                                            <i className="an-ico an-ico-setting">{}</i>
                                        </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        )
                    } else {
                        return <></>
                    }
                })}
            </div>
        )
    }
}

export default connect(mapStateToProps, actionCreators)(FilterBar);
