import React, {useContext, useState} from "react";
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";
import {Loader} from "../Loader";

const RadioBtn = ({obj, id, activeRadio, setActiveRadio}) => {
    return (
        <div className="radio-block" >
            <input type="radio" name="report-radio" onChange={() => setActiveRadio(id)} className="custom-radio" id={`report-${id}`} checked={activeRadio === id}/>
            <label htmlFor={`report-${id}`}>
                <div className="label-group">
                    <div className="label-h">{obj.name}</div>
                    <div className="label-txt">{obj.description}</div>
                </div>
            </label>
        </div>
    )
}

export const ReportMenu = ({handleCloseMenu, tabsObj, activeToken, cardCheckbox, quantityInputs, storeName}) => {
    const {request} = useHttp();
    const [loading, setLoading] = useState(false);
    const [activeRadio, setActiveRadio] = useState(Object.keys(tabsObj)[0]);
    const {jwt} = useContext(AuthContext);

    const reportConfig = {
        "selected_products": [],
        "stock_updates": []
    };
    if(Object.keys(cardCheckbox).indexOf(storeName) >= 0) {
        Object.keys(cardCheckbox[storeName]).forEach((key) => {
            reportConfig['selected_products'].push(key);
        })
    }
    if(Object.keys(quantityInputs).indexOf(storeName) >= 0) {
        Object.keys(quantityInputs[storeName]).forEach((key) => {
            let result = {}
            result[key] = quantityInputs[storeName][key]['ship_storage']
            reportConfig['stock_updates'].push(result);
        })
    }
    const getFile = async () => {
        setLoading(true);

        const newReportConfig = {
            selected_products: [
                ...reportConfig.selected_products
            ],
            stock_updates: [
                ...reportConfig.stock_updates.filter((item) => reportConfig.selected_products.indexOf(Object.keys(item)[0]) >= 0)
            ]
        }
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/download/`, 'POST', {
                ...newReportConfig,
                type: activeRadio,
                api_key_id: activeToken.id,
            }, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            }, true);
            if(data) {
                setLoading(false);
                handleCloseMenu();
            }
        } catch (e) {}
    }
    return (
        <>  {loading && <Loader label={false}/>}
            <div className="menu-header">
                <i className="menu-close an-ico an-ico-close" onClick={handleCloseMenu}>{}</i>
                <div>Выгрузить отчет</div>
            </div>
            <div className={`menu-content ${loading ? 'loading' : ''}`}>
                <div className="menu-option-group">
                    <div className="option-group-title">Отчет поставки</div>
                    <div className="report-radio-group">
                        {Object.keys(tabsObj).map((key, index) => (
                            <RadioBtn key={`download-${key}-${index}`}
                                      obj={tabsObj[key]}
                                      id={key}
                                      activeRadio={activeRadio}
                                      setActiveRadio={setActiveRadio}
                            />
                        ))}
                    </div>
                    <div className="report-btn-block">
                        <div className="date-submit btn-submit" onClick={() => getFile()}>Скачать</div>
                    </div>
                </div>
            </div>
        </>
    )
}
