import Dropzone from 'react-dropzone-uploader'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import {Notification} from "../Notification";


const PreviewFile = ({meta: {percent, name, status}}) => {
    console.log(percent);
    return (
        <div className="drop-upload-done">
            <div className="drop-file-info">
                <div className="drop-file-name"><i className="an-ico an-ico-file">{}</i>{name}</div>
                <div className="drop-file-status"><i className={`an-ico ${status === "done" ? "an-ico-done" : "an-ico-close"}`}>{}</i></div>
            </div>
            <div className="drop-upload-status-bar">
                <div className="status-upload-line" style={{right: 100-percent+'%'}}>{}</div>
            </div>
        </div>
    )
}

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length > 0 ? '' : 'Выберите файл'
    return (
        <>
            {files.length < 1 &&
            <>
                <label style={{ backgroundColor: '#fff', color: '#373E4D', cursor: 'pointer', padding: "8px 34px", borderRadius: 3, border: `1px solid #373E4D` }}>
                    {text}
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        accept={accept}
                        multiple
                        onChange={e => {
                            getFilesFromEvent(e).then(chosenFiles => {
                                onFiles(chosenFiles)
                            })
                        }}
                    />
                </label>
                <div style={{marginTop: 10}}>Или перетащите его сюда</div>
            </>
            }
        </>
    )
}

export const CustomDropZone = ({jwt, currentUploadTabMethod, reloadListing, activeToken}) => {

    const handleSubmit = (files, allFiles) => {
        // console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove());
        // reloadListingPage(storeName);

    }
    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }
    const handleChangeStatus = ({xhr}, status) => {
        console.log(status, xhr)
        if(status === 'done') {
            Notification({msg: 'Ваши данные загружены', type: 'success'})
            reloadListing();
        }
    }
    // {"status": "ok", "response": {"status": "Success", "data": {"cont": [{"barcode": "2000667160145", "allow_count": "1000"}], "key": "barcode", "status": null}}}
    return (
        <Dropzone
            accept=".xlsx,.xls"
            getUploadParams={({file, meta}) => {
                const body = new FormData()
                body.append('input', file);
                body.append('api_key_id', activeToken.id);
                //Access-Control-Allow-Origin': '*', Authorization: `${nextProps.jwt}
                return { url: `https://${process.env.REACT_APP_DOMAIN}/api/upload/${currentUploadTabMethod}/`, body, headers: {'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`} }
            }}
            onSubmit={handleSubmit}
            InputComponent={Input}
            submitButtonContent=''
            PreviewComponent={PreviewFile}
            SubmitButtonComponent={() => null}
            onChangeStatus={handleChangeStatus}
            submitButtonDisabled={files => files.length < 1}
            getFilesFromEvent={getFilesFromEvent}
            styles={{
                dropzone: (files) => (files.length > 0 ? {borderColor: '#00B2FF'} : {})
            }}
        />
    )
}
