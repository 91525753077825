// @ts-check
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import useRoutes from './routes';
import {useAuth} from "./hooks/auth.hook";
import {useTariff} from "./hooks/tariff.hook";
import {AuthContext} from "./context/AuthContext";
import {TokensContext} from "./context/TokensContext";
import {TariffContext} from "./context/TariffContext";
import {useTokens} from "./hooks/tokens.hook";
import {useOnboarding} from './hooks/onboarding.hook';
import { OnboardingContext } from './context/OnboardingContext';
import AppContent from './AppContent';

const App = () => {
    const {jwt, login, logout, userId, ready, user, userInfo, hasActiveTariff, isDevShow, setShowDev} = useAuth();
    const {tokens, initTokens, delAllTokens, activeToken, setNewActiveToken, setActiveToken} = useTokens();
    const {activeTariff, setActiveTariff, getActiveTariff} = useTariff();
    const isAuthenticated = !!jwt;
    const routes = new useRoutes({isAuthenticated, activeToken, jwt, setActiveToken, tokens, hasActiveTariff});
    const {account, supplyForecast} = useOnboarding();

    if (!ready) {
        return (
            <div>LOADER</div>
        )
    }
    return (
        <AuthContext.Provider value={{
            jwt, login, logout, userId, isAuthenticated, user, userInfo, hasActiveTariff, isDevShow, setShowDev
        }}>
            <TokensContext.Provider value={{
                tokens, activeToken, initTokens, delAllTokens, setNewActiveToken, setActiveToken
            }}>
                <TariffContext.Provider value={{activeTariff, setActiveTariff, getActiveTariff}}>
                    <OnboardingContext.Provider value={{account, supplyForecast}}>
                        <Router>
                            <AppContent routes={routes} />
                        </Router>
                    </OnboardingContext.Provider>
                </TariffContext.Provider>
            </TokensContext.Provider>
        </AuthContext.Provider>
    )
};
export default App;

