import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux';
import FilterBar from "../components/FilterBar";
import {GoodsCard} from "../components/GoodsCard";
import SortingBar from "../components/SortingBar";
import * as actions from "../actions";
import {createSelector} from "reselect";
import RightSideMenu from "../components/RightSideMenu";
import {Link} from "react-router-dom";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {GroupGoodsCard} from "../components/GroupGoodsCard";
import moment from "moment";
import {Loader} from "../components/Loader";
import {OnboardingContext} from '../context/OnboardingContext';
import {demoData} from '../constants/demoData';

const calcNextDate = (period, date) => {
    let dateOffset = (24*60*60*1000) * period * -1;
    let startDate = new Date();
    date.setHours(0,0,0,0);
    startDate.setHours(0,0,0,0);
    startDate.setTime(startDate.getTime() + dateOffset);
    return startDate;
}
function reFormatDate(date) {
    return format(date, 'yyyy-MM-dd', {
        locale: ru
    });
}

const Pagination = ({total, allParams, storeName, setPaginationParam}) => {
    const makeLink = (newPage, limit, newLink, storeName) => {
        return (<Link
            className={`page ${newPage === parseInt(page) ? 'page-active' : ''}`}
            to={`?${newLink.join('&')}&page=${newPage}&limit=${limit}`} key={`page-${newPage}-${new Date().getSeconds()}`}
            onClick={handleClick(newPage, limit, newLink, storeName)}>{newPage}</Link>)
    }
    const query = new URLSearchParams(window.location.search);
    let page = query.get('page') ? query.get('page') : 1;
    let limit = query.get('limit') ? query.get('limit') : 17;
    page = parseInt(page);
    let newLink = [...allParams];
    newLink = newLink.filter((param) => param.indexOf('page') < 0 && param.indexOf('limit') < 0 && param.indexOf('sort') < 0);
    if(query.get('sort')) {
        newLink.push(`sort=${query.get('sort')}`)
    }
    let totalPages = Math.ceil(total / limit);
    let centerPage = page + 5 <= totalPages && page - 5 > 0 ? page : page - 5 <= 0 ? 6 : page + 5 >= totalPages ? totalPages - 5 : Math.ceil(totalPages / 2);

    const handleClick = (page, limit, newLink, storeName) => () => {
        setPaginationParam({page, limit, newLink, storeName});
    }
    const [arrPages, setArrPages] = useState([])
    useEffect(() => {
        setArrPages(makePagesLink())
    }, [page, allParams, total])
    const makePagesLink = () => {
        let result = [];
        let newPage = page - 4;
        if(totalPages > 10) {
            if(centerPage - 6 > 0) {
                result.push(<div key={'extra-1'} className="extra-page">{makeLink(1, limit, newLink, storeName)}<span>...</span></div>)
            }
            for(let i = 1; i <= 11; i++) {
                newPage = centerPage - (6 - i)
                result.push(makeLink(newPage, limit, newLink, storeName))
            }
            if(centerPage + 6 <= totalPages) {
                result.push(<div key={'extra-99'} className="extra-page"><span>...</span>{makeLink(totalPages, limit, newLink, storeName)}</div>)
            }
            return result
        }
        for(let i = 1; i <= totalPages; i++) {
            result.push(makeLink(i, limit, newLink, storeName))
        }
        return result
    }
    return (
        <>
            <div className={'pagination'}>
                <span>Страница:</span>
                {arrPages}
            </div>
        </>
    )
}

const getStateByName = (state, stateLocation, storeName) => {
    return !!state[stateLocation][storeName] ? state[stateLocation][storeName] : {};
}
const stateByName = createSelector(
    [getStateByName],
    (items) => {
        return {...items};
    }
)

const mapStateToProps = (state, props) => {
    let { initParams: {loading, pageInit, pageReload, popPageInit, noData}, activeToken, setActiveToken, title } = props;
    
    const uiState = state.uiState;
    const settings = props.settings;
    const rightMenu = state.rightMenu;
    let extra = {};
    let cardConfig = {};
    let filterBarConfig = null;
    let allParams = [];
    let viewType = {};
    let displayConfig = {};
    let filterDateStart = {
        days: null,
        dateSearchParam: null
    };
    let filterDateStartSecond = null
    let defaultSortParam = null
    let allParamsResponse = {};
    let listingPageResponse = {};
    let goods = {};
    let total = 0;
    let pagination = {};
    let cardCheckbox = false;
    let cardCheckboxItems = {}
    let checkBoxCount = 0;
    if(!!state['searchParams'][settings.storeName] === true) {
        allParamsResponse = stateByName(state, 'searchParams', settings.storeName);
        allParams = allParamsResponse.allParams;
        filterDateStart = allParamsResponse.filterDateStart;
        filterDateStartSecond = allParamsResponse.filterDateStartSecond;
        defaultSortParam = allParamsResponse.defaultSortParam;
        pagination = allParamsResponse.pagination;
    }
    if(!!state['listingPage'][settings.storeName] === true) {
        listingPageResponse = stateByName(state, 'listingPage', settings.storeName);
        filterBarConfig = listingPageResponse.filterBarConfig === undefined ? null : listingPageResponse.filterBarConfig;
        goods = listingPageResponse.goods === undefined ? {} : listingPageResponse.goods;
        cardConfig = listingPageResponse.cardConfig;
        extra = listingPageResponse.extra;
        cardCheckbox = listingPageResponse.cardCheckbox;
        cardCheckboxItems = stateByName(state, 'cardCheckbox', settings.storeName);
        if (!!cardCheckbox) {
            let count = 0;
            Object.keys(cardCheckboxItems).map((key) => {
                count = cardCheckboxItems[key].active === true ? count + 1 : count;
            })
            checkBoxCount = !!cardCheckbox ? count : 0;
        }
        total = listingPageResponse.total;
        displayConfig = listingPageResponse.displayConfig;
        viewType = !!displayConfig ? listingPageResponse.displayConfig.viewType : '';
        loading = listingPageResponse.loading === undefined ? loading : listingPageResponse.loading;
        pageInit = listingPageResponse.pageInit === undefined ? pageInit : listingPageResponse.pageInit;
        pageReload = listingPageResponse.pageReload === undefined ? pageReload : listingPageResponse.pageReload;
        noData = listingPageResponse.noData === undefined ? noData : listingPageResponse.noData;
    }
    return {
        cardCheckbox, 
        cardCheckboxItems, 
        rightMenu, 
        goods, 
        activeToken, 
        loading, 
        noData, 
        extra, 
        cardConfig, 
        filterDateStart, 
        filterDateStartSecond, 
        defaultSortParam, 
        displayConfig, 
        pageInit, 
        pageReload, 
        allParams, 
        viewType, 
        total, 
        pagination, 
        settings, 
        popPageInit, 
        filterBarConfig, 
        setActiveToken, 
        title, 
        uiState, 
        checkBoxCount
    };
};

const actionCreators = {
    fetchData: actions.fetchData,
    initSearchStateParams: actions.initSearchStateParams,
    setPaginationParam: actions.setPaginationParam,
    setRightMenuActive: actions.setRightMenuActive,
    delInitStateParams: actions.delInitStateParams,
    delInputStateParams: actions.delInputStateParams,
    reloadListingPage: actions.reloadListingPage,
    forceClose: actions.forceClose,
    initConfig: actions.initConfig,
    setDemoData: actions.setDemoData,
};

const initPage = (props) => {
    const {initSearchStateParams, settings, initConfig} = props;
    initConfig({storeName: settings.storeName})
    initSearchStateParams(window.location.search, settings.storeName, {
        pageInit: false,
        pageReload: true
    });
}

class ListingPage extends React.Component {

    componentDidMount() {
        // console.log('componentDidMount')
        const {initSearchStateParams, settings, setActiveToken} = this.props;
        initPage(this.props);

        window.onpopstate = function() {
            console.log('ListingPage window.onpopstate');
            initSearchStateParams(window.location.search, settings.storeName);
            const query = new URLSearchParams(window.location.search);
            const urlActiveToken = query.get('api_key_id');
            setActiveToken({id: urlActiveToken});
        };
    }

    componentWillUnmount() {
        const {delInitStateParams} = this.props;
        this.props.forceClose();
        delInitStateParams(this.props.settings.storeName);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {fetchData, initSearchStateParams, delInputStateParams, setActiveToken, setDemoData, goods, pageReload} = this.props;
        const {apiUrl, storeName} = nextProps.settings;
        const query = new URLSearchParams(window.location.search);
        if(nextProps.jwt !== this.props.jwt) {
            console.log(nextProps.jwt);
        }


        // if (!localStorage.getItem('showSupForecastOnboarding')) {
        //     if (pageReload) {
        //         setDemoData(demoData);
        //     }
        //     return true;
        // }


        if(!this.props.activeToken) {
            // initPage(this.props);
            const urlActiveToken = query.get('api_key_id');
            if(!!urlActiveToken) {
                setActiveToken({id: urlActiveToken});
            }
        }
        if(!!this.props.activeToken) {
            const startDateFilterParam = query.get(`${nextProps.filterDateStart.dateSearchParam}date_from`);
            if (!startDateFilterParam && nextProps.filterDateStart.dateSearchParam != null) {
                const firstDateTo = moment().subtract(1, 'days').format('YYYY-MM-DD');
                const firstDateFrom = moment().subtract(nextProps.filterDateStart.days, 'days').format('YYYY-MM-DD');
                const firstIntervalQuery = `${nextProps.filterDateStart.dateSearchParam}date_to=${firstDateTo}&${nextProps.filterDateStart.dateSearchParam}date_from=${firstDateFrom}`
                let secondIntervalQuery = '';
                if(!!this.props.filterDateStartSecond) {
                    const secondDateTo = moment().subtract(nextProps.filterDateStart.days + 1, 'days').format('YYYY-MM-DD');
                    const secondDateFrom = moment().subtract(nextProps.filterDateStart.days + nextProps.filterDateStartSecond.days, 'days').format('YYYY-MM-DD');
                    secondIntervalQuery = `&${nextProps.filterDateStartSecond.dateSearchParam}date_to=${secondDateTo}&${nextProps.filterDateStartSecond.dateSearchParam}date_from=${secondDateFrom}`
                }
                // window.history.replaceState({}, null, `${window.location.pathname}${window.location.search ? window.location.search + '&': '?'}${this.props.filterDateStart.dateSearchParam}date_to=${reFormatDate(new Date())}&${this.props.filterDateStart.dateSearchParam}date_from=${reFormatDate(calcNextDate(this.props.filterDateStart.days, new Date()))}`);
                window.history.replaceState({}, null, `${window.location.pathname}${window.location.search ? window.location.search + '&': '?'}${firstIntervalQuery}${secondIntervalQuery}${!!nextProps.defaultSortParam ? `&sort=${nextProps.defaultSortParam}` : ''}`);
                initSearchStateParams(window.location.search, this.props.settings.storeName);
                return false
            }

            if(parseInt(nextProps.activeToken.id) !== parseInt(this.props.activeToken.id)) {
                console.log('!!!!!!!!!!TOKEN_CHANGE!!!!!!!!!!');
                window.history.replaceState({}, null, `${window.location.pathname}?api_key_id=${nextProps.activeToken.id}`)
                initSearchStateParams(window.location.search, this.props.settings.storeName);
                delInputStateParams(this.props.settings.storeName);
                return false
            }
            if(nextProps.pageReload && !!nextProps.activeToken.id) {
                query.delete('api_key_id');
                let newParams = nextProps.allParams.filter((param) => param.indexOf('sort') < 0)
                if(query.get('sort')) {
                    newParams.push(`sort=${query.get('sort')}`)
                }
                if(!query.get('page')) {
                    newParams = newParams.filter((param) => {
                        return param.indexOf('page') < 0
                    })
                }
                fetchData(`${apiUrl}?${encodeURI(`${newParams.join('&')}`)}`, storeName, 'GET', null, {
                    'Access-Control-Allow-Origin': '*', Authorization: `${nextProps.jwt}`, 'Content-Type': 'application/json'
                });
                return false
            }
        }
        return true
    }

    reloadListing = () => {
        const {reloadListingPage, settings: {storeName}} = this.props;
        reloadListingPage({storeName});
    }

    render() {
        console.log('render');
        const {allParams,
            viewType,
            pageReload,
            goods,
            loading,
            pageInit,
            setPaginationParam,
            cardConfig,
            displayConfig,
            total,
            pagination,
            settings,
            extra,
            filterBarConfig,
            cardCheckbox,
            activeToken,
            rightMenu,
            jwt,
            title,
            noData,
            uiState,
            checkBoxCount,
            cardCheckboxItems,
        } = this.props;
        if (noData && localStorage.getItem('showSupForecastOnboarding') !== 'true') {
            localStorage.setItem('showSupForecastOnboarding', 'noData');
        } else if(localStorage.getItem('showSupForecastOnboarding') !== 'true') {
            localStorage.removeItem('showSupForecastOnboarding');
        }

        if (!activeToken && !!localStorage.getItem('showSupForecastOnboarding')) {
            return <div className="loader">
                <div>Нет активного токена</div>
            </div>
        }
        if(pageInit && !noData) {
            console.log('pageInit');
            return <Loader />
        }

        if(!pageInit && (Object.keys(goods).length === 0 || pageReload)) { // исправить загрузку. скорее всего запрос по урлу каждый раз
            // console.log(!!filterBarConfig)
            // console.log(!!cardConfig)
            return (
                <div className="content-section">
                    <div className="header-bar">
                        {/*<h1>{title}</h1>*/}
                    </div>
                    <div className="sticky bar-block">
                        {
                            !!filterBarConfig && 
                            <FilterBar 
                                key={`filter-bar-${settings.storeName}`} 
                                cardConfig={cardConfig} 
                                settings={settings} 
                                filterBarConfig={filterBarConfig} 
                            />}
                        {
                            !!cardConfig && 
                            <SortingBar 
                                cardConfig={cardConfig} 
                                settings={settings}
                            />
                        }
                    </div>
                    {
                        !noData &&
                        <div className="goods-container">
                            <Loader />
                        </div>
                    }
                    {
                        noData &&
                        <div className="goods-container">
                            <div className="no-data">По вашему запросу нет данных. </div>
                        </div>
                    }
                </div>
            )
        }
        const getSettingActivity = (settingKey) => {
            return displayConfig.currentPropsInCard[settingKey]
        };
        const handleOpenMenu = (type) => () => {
            const {setRightMenuActive} = this.props;
            setRightMenuActive({isActive: true, type});
        }
        return (
            <div className="content-section">
                <div className="header-bar">
                    {/*<h1>{title}</h1>*/}
                    <div className='btns' data-tut="download_report">
                        {Object.keys(rightMenu.tabs[settings.storeName].upload).length > 0 &&
                            <div className="btn btn-hard-gray" onClick={handleOpenMenu('stocks-upload')}>Загрузить свои данные</div>
                        }
                        {Object.keys(rightMenu.tabs[settings.storeName].download).length > 0 &&
                            <div className="btn btn-blue btn-check-box-count" onClick={handleOpenMenu('report')}>
                                Выгрузить отчет
                                {checkBoxCount != 0 &&
                                <span className='check-box-count'>{checkBoxCount}</span>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="sticky bar-block">
                {
                    !pageInit && 
                    <FilterBar 
                        key={`filter-bar-${settings.storeName}`} 
                        cardConfig={cardConfig} 
                        settings={settings} 
                        filterBarConfig={filterBarConfig} 
                    />
                }
                {
                    !pageInit && 
                    <SortingBar 
                        cardConfig={cardConfig} 
                        settings={settings}
                    />
                }
                </div>
                <div className={`goods-container${viewType === 'shortView' ? ' short-view' : ''}${viewType === 'simple' ? ' simple-view' : ''}`}>
                    {loading && <Loader />}
                    {noData && <div className="no-data">По вашему запросу нет данных. </div>}
                    {!loading && !noData && goods.map((item) => (
                        <GroupGoodsCard
                            key={item['nomenclature']}
                            itemKey={item['nomenclature']}
                            cardConfig={cardConfig}
                            extra={extra}
                            getSettingActivity={getSettingActivity}
                            item={item}
                            viewType={viewType}
                            settings={settings}
                            cardCheckbox={cardCheckbox}
                            cardCheckboxItems={cardCheckboxItems}
                            reloadListing={this.reloadListing}
                            uiState={uiState}
                        />
                    ))}
                </div>
                <RightSideMenu
                    settings={settings} 
                    jwt={jwt}
                    activeToken={activeToken}
                />
                {
                    !noData && 
                    !loading &&
                    <Pagination 
                        total={total} 
                        pagination={pagination} 
                        allParams={allParams} 
                        storeName={settings.storeName} 
                        setPaginationParam={setPaginationParam}
                    />
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, actionCreators)(ListingPage)

