import React from "react";
import moment from 'moment';

export const TariffItem = ({tariff, payHandler = null}) => {
    const expDays = tariff['expire_at'] ? moment(tariff['expire_at']).diff(moment(), 'days') : null;
    let tariffPeriod = null;
    let initPrice = null;
    let saleTitle = null;

    switch(tariff.saving) {
        case null:
            tariffPeriod = 'МЕСЯЦ';
            initPrice = tariff.price / 0.5 + ' ₽';
            saleTitle = '  50% скидка';
            break;
        default: 
            const months = tariff.duration / 30;
            let monthTitle = null;
            if (
                months.toString().endsWith('1') || 
                months.toString().endsWith('21') ||
                months.toString().endsWith('31') ||
                months.toString().endsWith('41')
            ) {
                monthTitle = 'МЕСЯЦ';
            } else if (
                (
                    months.toString().endsWith('2') ||
                    months.toString().endsWith('3') ||
                    months.toString().endsWith('4')
                ) &&
                (
                    !months.toString().endsWith('11') &&
                    !months.toString().endsWith('12') &&
                    !months.toString().endsWith('13') &&
                    !months.toString().endsWith('14')
                )
            ) {
                monthTitle = 'МЕСЯЦА';
            } else {
                monthTitle = 'МЕСЯЦЕВ';
            }


            tariffPeriod = `${months} ${monthTitle}`;
            initPrice = 23760 + ' ₽';
            saleTitle = `  60% скидка`;
            break;
    }

    return (
        <div className="an-col-inline">
            <div className="round-card bg-color-white inlineCard">
                <div className="personal-tariff">
                    {!!expDays &&
                    <div className="tariff-label">
                        Осталость {expDays / 30} д.
                    </div>
                    }
                    <div style={{ marginBottom: '50px' }}>
                        <div className="tariff-h-s">
                            <span>{tariff.price} ₽ / {tariffPeriod}</span>
                        </div>
                        <div className="tariff-h-c">
                            {!!initPrice && 
                                <span className="through-line-red">{initPrice}</span>
                            }
                            {saleTitle}
                        </div>
                    </div>
                    {!!payHandler &&
                    <div className="btn btn-stroke-blue-big" onClick={() => payHandler(tariff.duration_id)}>Купить</div>
                    }
                </div>
            </div>
        </div>
    )
}
