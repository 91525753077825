import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/index'
import {createSelector} from "reselect";
import {UploadFileTabs} from "./rightSideMenu/UploadFileTabs";
import {ViewMenu} from "./rightSideMenu/ViewMenu";
import {ReportMenu} from "./rightSideMenu/ReportMenu";


const getStateByName = (state, stateLocation, storeName) => {
    return state[stateLocation][storeName];
}
const stateByName = createSelector(
    [getStateByName],
    (items) => {
        return {...items.displayConfig};
    }
)
const mapStateToProps = (state, props) => {
    const storeName = props.settings.storeName;
    const { rightMenu: {isActive, type, tabs}, cardCheckbox, quantityInputs } = state;
    const displayConfig = stateByName(state, 'listingPage', storeName);
    return {isActive, displayConfig, storeName, type, tabs, cardCheckbox, quantityInputs};
};
const actionCreators = {
    setRightMenuActive: actions.setRightMenuActive,
    setDisplayOption: actions.setDisplayOption,
    setFileUploadTab: actions.setFileUploadTab,
    reloadListingPage: actions.reloadListingPage,
};

class RightSideMenu extends React.Component {
    handleCloseMenu = () => {
        const {setRightMenuActive} = this.props;
        setRightMenuActive({isActive: false, type: ''});
    }
    handleOptionChange = (propId, storeName) => () => {
        const {setDisplayOption} = this.props;
        setDisplayOption({propId, storeName});
    }
    handleTabClick = (tabKey, storeName) => {
        const {setFileUploadTab} = this.props;
        setFileUploadTab({tabKey, storeName});
    }
    reloadListing = () => {
        const {reloadListingPage, storeName, setRightMenuActive} = this.props;
        console.log(storeName);
        reloadListingPage({storeName});
        setRightMenuActive({isActive: false, type: ''});
    }
    render() {
        const { isActive, displayConfig, storeName, type, tabs, activeToken, cardCheckbox, quantityInputs } = this.props;
        if (!isActive) {
            return null;
        }
        if(Object.keys(displayConfig).length === 0) {
            return null;
        }
        const displaySettings = displayConfig.displaySettings;
        const currentPropsInCard = displayConfig.currentPropsInCard;

        return (
            <div className="right-side-menu">
                <div className={"menu-bg"} onClick={this.handleCloseMenu}>{}</div>
                <div className="menu-container">
                    {type === 'report' && Object.keys(tabs[storeName].download).length >0 &&
                        <ReportMenu handleCloseMenu={this.handleCloseMenu}
                                    tabsObj={tabs[storeName].download}
                                    reloadListing={this.reloadListing}
                                    activeToken={activeToken}
                                    cardCheckbox={cardCheckbox}
                                    quantityInputs={quantityInputs}
                                    storeName={storeName}
                        />
                    }
                    {type === 'stocks-upload' && Object.keys(tabs[storeName].upload).length >0 &&
                        <UploadFileTabs tabsObj={tabs[storeName].upload}
                                        reloadListing={this.reloadListing}
                                        activeToken={activeToken}
                                        handleCloseMenu={this.handleCloseMenu}
                        />
                    }
                    {type === 'view-config' &&
                        <ViewMenu handleCloseMenu={this.handleCloseMenu}
                                  displaySettings={displaySettings}
                                  currentPropsInCard={currentPropsInCard}
                                  storeName={storeName}
                                  handleOptionChange={this.handleOptionChange}
                        />
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, actionCreators)(RightSideMenu);
