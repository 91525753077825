import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/index'
import { createSelector } from "reselect";
//
const getItems = (state, itemKey, propVal, storeName) => {
    return state.quantityInputs[storeName][itemKey] !== undefined ? state.quantityInputs[storeName][itemKey][propVal] : '';
};

const inputValSelector = createSelector(
    [getItems],
    (item) => {
        return item;
    }
)

const mapStateToProps = (state, props) => {
    let inputVal = '';
    const storeName = props.storeName;
    if(state.quantityInputs[storeName] !== undefined) {
        inputVal = inputValSelector(state, props.itemKey, props.propVal, storeName);
    }
    return {inputVal, storeName};
};

const actionCreators = {
    setQuantityInput: actions.setQuantityInput,
    setCheckboxVal: actions.setCheckboxVal
};

class QuantityInput extends React.Component {

    handleInputChange = (itemKey, propVal, storeName) => (e) => {
        const {setQuantityInput, setCheckboxVal} = this.props;
        setQuantityInput({"value": e.target.value, itemKey, propVal, storeName});
        setCheckboxVal({itemKey, currentVal: false, storeName});
    }

    render() {
        let { inputVal, initVal, itemKey, propVal, storeName, endStr } = this.props;
        inputVal = inputVal !== '' ? inputVal : initVal;
        return (
            <>
                <div>
                    <input type="text" name="goods-item-val" className="goods-input" value={inputVal === null ? '' : inputVal} onChange={this.handleInputChange(itemKey, propVal, storeName)}/>
                    {!!endStr && <span> {endStr}</span>}
                    <div className=""><small>Рекомендуем: {initVal !== null ? initVal : 'Незнаю'}</small></div>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, actionCreators)(QuantityInput);
