import React from "react";

export const Orders = ({ priceOne, countOne, priceTwo, countTwo }) => {

  return (
    <div className="dash-card-main-wrap">
      <div className="flex-col round-dash-card flex-center">
        <h4>
          Заказы
        </h4>
        <span>
          {priceOne} руб. / {countOne} шт.
        </span>
        <span>
          {priceTwo} руб. / {countTwo} шт.
        </span>
      </div>
    </div>
  )
}