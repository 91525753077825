import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";

export const SearchInput = ({ storeName }) => {
    const [value, setValue] = useState('');
    const allParamsResponse = useSelector((state) => state['searchParams'][storeName]);
    const dispatch = useDispatch();
    const allParams = allParamsResponse.allParams;
    useEffect(() => {
        const params = new URL(window.location.href).searchParams;
        const search = params.get('search');
        setValue(!search ? '' : search);
    }, [window.location.href])
    let newAllParams = [...allParams];
    newAllParams = newAllParams.filter((param) => param.indexOf('page') < 0 && param.indexOf('limit') < 0);
    newAllParams = newAllParams.filter((param) => param.indexOf('search') < 0);
    if (value !== '') {
        newAllParams.push(`search=${value}`)
    }
    const onClick = () => {
        dispatch(actions.setSearchString({ currentVal: value, storeName }))
    }
    useEffect(() => {
        if (!value) {
            window.history.replaceState({}, null, `${window.location.pathname}?${newAllParams.join('&')}`);
            onClick();
        }
    }, [value])
    return (
        <div className="search-input-block">
            <Link to={`?${newAllParams.join('&')}`} onClick={onClick}>
                <i className="an-ico an-ico-search" />
            </Link>
            <input
                className="btn-filter-input"
                placeholder='WB id/артикул/ссылка'
                onChange={(e) => setValue(e.target.value)}
                value={value}
                onKeyPress={(key) => {
                    if (key.key === "Enter") {
                        window.history.replaceState({}, null, `${window.location.pathname}?${newAllParams.join('&')}`)
                        onClick();
                    }
                }}
            />
            {value !== '' &&
                <i className="clean-search an-ico an-ico-close" onClick={() => setValue('')} />
            }

        </div>
    )
}
