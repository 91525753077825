import React, {useState, useCallback, useContext, useEffect} from "react";
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";


export const Supplies = () => {
    const {loading, request} = useHttp();
    const {jwt} = useContext(AuthContext);

    const [suppliesData, setSuppliesData] = useState({
      necessary_reserve: null,
      shipment_preparing: null,
    });
    const [error, setError] = useState({
        necessary_reserveNotValid: false,
        shipment_preparingNotValid: false,
    })

  const getSupplySettings = useCallback(async() => {
    try {
        const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/get-supply-settings/`, 'GET', null, {
            'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
        });

        setSuppliesData(data)
    } catch (e) {}
  },[jwt, request]);

  useEffect(() => {
      getSupplySettings()
  }, [getSupplySettings]);
  
  useEffect(() => {
    if(error.necessary_reserveNotValid && suppliesData.necessary_reserve > 0) {
        setError({...error, necessary_reserveNotValid: false})
    }
    if(error.shipment_preparingNotValid && suppliesData.shipment_preparing > 0) {
        setError({...error, shipment_preparingNotValid: false})
    }
  }, [suppliesData]);

    const handleChangeForm = (event) => {
      setSuppliesData({
            ...suppliesData,
            [event.target.name]: Number(event.target.value)
        })
    };

    const changeSupplySettingsFetch = async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/supply-settings/`, 'POST',
                {
                  necessary_reserve: suppliesData.necessary_reserve, 
                  shipment_preparing: suppliesData.shipment_preparing
                },
                {
                    'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
                }
                )
            // console.log('data ====>', data);
            // setPasswordForm(false);
        } catch (e) {}
    }
    const changeSupplySettings = () => {
        if(suppliesData.necessary_reserve <= 0) {
            setError({...error, necessary_reserveNotValid: true})
        } else if(suppliesData.shipment_preparing <= 0) {
            setError({...error, shipment_preparingNotValid: true})
        } else {
            changeSupplySettingsFetch();
        }
    }
    return (
        <>
            {!loading &&
                <div className="an-col-16">
                    <form className="round-card bg-color-white">
                        <div className="onboarding-form">
                            <div className="input-group">
                                <div className="input-group-item">
                                    <label htmlFor="necessary-reserve">Необходимый товарный запас</label>
                                    <input
                                        className="form-input"
                                        type="number"
                                        id="necessary-reserve"
                                        min="0"
                                        name="necessary_reserve"
                                        value={!!suppliesData.necessary_reserve ? Number(suppliesData.necessary_reserve).toString() : suppliesData.necessary_reserve}
                                        onChange={handleChangeForm}
                                    />
                                </div>
                            </div>
                            {
                              error.necessary_reserveNotValid &&
                              <span className='color-red'>Число должно быть больше 0</span>
                            }
                            <div className="input-group">
                                <div className="input-group-item">
                                    <label htmlFor="shipment-preparing">Кол-во дней на подготовку к отгрузке</label>
                                    <input
                                        className="form-input"
                                        type="number"
                                        id="shipment-preparing"
                                        min="0"
                                        name="shipment_preparing"
                                        value={!!suppliesData.shipment_preparing ? Number(suppliesData.shipment_preparing).toString() : suppliesData.shipment_preparing}
                                        onChange={handleChangeForm}
                                    />
                                </div>
                            </div>
                            {
                              error.shipment_preparingNotValid &&
                              <span className='color-red'>Число должно быть больше 0</span>
                            }
                            <div className="btn-block">
                                <div 
                                  className="btn btn-blue" 
                                  onClick={changeSupplySettings}
                                >
                                  Изменить настройки
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}
