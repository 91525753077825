import React, { useCallback, useEffect, useContext } from 'react'
import { Switch, Route, Redirect, useLocation, withRouter  } from 'react-router-dom'
import ListingPage from "./pages/ListingPage";
import { AuthPage } from "./pages/AuthPage";
import { Personal } from "./pages/Personal";
import { DashBoard } from "./pages/DashBoard";
import { ActivatePage } from "./pages/ActivatePage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { CheckTariff } from "./pages/CheckTariff";
import {TariffContext} from "./context/TariffContext";

const useRoutes = ({isAuthenticated, activeToken, jwt, setActiveToken, tokens, hasActiveTariff}) => { 
    const {getActiveTariff} = useContext(TariffContext);   

    const page = useLocation().pathname;

    useEffect(async () => {
            if (page !== '/' &&  !page.includes('/user/activate')) {
            await getActiveTariff(jwt);
        } 
    }, [page, getActiveTariff]);
    

    if (isAuthenticated) {
        let popPageInit = false;
        return (
            <Switch>
                <Route path="/" exact render={(props) => <CheckTariff {...props} hasActiveTariff={hasActiveTariff} />} />
                <Route path="/dashboard" exact component={DashBoard} />
                <Route path="/delivery-forecast"
                    exact
                    render={(props) =>
                        <ListingPage {...props}
                            settings={
                                {
                                    storeName: 'DeliveryForecast',
                                    apiUrl: `https://${process.env.REACT_APP_DOMAIN}/api/supply-report/`
                                }
                            }
                            title={'Прогноз поставок'}
                            key={'DeliveryForecast'}
                            jwt={jwt}
                            activeToken={activeToken}
                            setActiveToken={setActiveToken}
                            initParams={
                                {
                                    loading: true,
                                    pageReload: true,
                                    pageInit: true,
                                    popPageInit: popPageInit,
                                    noData: false,
                                }
                            }
                        />}
                />
                <Route path="/delivery-analytic"
                    exact
                    render={(props) =>
                        <ListingPage {...props}
                            settings={
                                {
                                    storeName: 'DeliveryAnalytic',
                                    apiUrl: `https://${process.env.REACT_APP_DOMAIN}/api/logistics-report/`,
                                }
                            }
                            title={'Анализ логистики'}
                            key={'DeliveryAnalytic'}
                            jwt={jwt}
                            activeToken={activeToken}
                            setActiveToken={setActiveToken}
                            initParams={
                                {
                                    loading: true,
                                    pageReload: true,
                                    pageInit: true,
                                    popPageInit: popPageInit,
                                    noData: false,
                                }
                            }
                        />}
                />
                <Route path="/price-report"
                    exact
                    render={(props) =>
                        <ListingPage {...props}
                            settings={
                                {
                                    storeName: 'ReportPrice',
                                    apiUrl: `https://${process.env.REACT_APP_DOMAIN}/api/price-report/`,
                                }
                            }
                            title={'Анализ показателей'}
                            key={'ReportPrice'}
                            jwt={jwt}
                            activeToken={activeToken}
                            setActiveToken={setActiveToken}
                            initParams={
                                {
                                    loading: true,
                                    pageReload: true,
                                    pageInit: true,
                                    popPageInit: popPageInit,
                                    noData: false,
                                }
                            }
                        />}
                />
                <Route path="/logistic-analytic"
                    exact
                    render={(props) =>
                        <ListingPage {...props}
                            settings={
                                {
                                    storeName: 'LogisticAnalytic',
                                    apiUrl: `https://${process.env.REACT_APP_DOMAIN}/api/price-report/`,
                                }
                            }
                            title={'Поиск цены'}
                            key={'LogisticAnalytic'}
                            jwt={jwt}
                            activeToken={activeToken}
                            setActiveToken={setActiveToken}
                            initParams={
                                {
                                    loading: true,
                                    pageReload: true,
                                    pageInit: true,
                                    popPageInit: popPageInit,
                                    noData: false,
                                }
                            }
                        />}
                />
                <Route exact path="/personal/">
                    <Personal />
                </Route>
                <Route path="/personal/:id">
                    <Personal />
                </Route>
                <Redirect to={"/delivery-forecast/"} />
            </Switch>
        )
    }
    // else if(!hasActiveTariff && isAuthenticated) {
    //     return (
    //         <Switch>
    //             <Route exact path="/personal/">
    //                 <Personal />
    //             </Route>
    //             <Route path="/personal/:id">
    //                 <Personal />
    //             </Route>
    //             <Redirect to={"/personal/tariffs"} />
    //         </Switch>
    //     )
    // }
    else {
        return (
            <Switch>
                <Route path="/" exact component={AuthPage} />
                <Route path="/user/activate/:id" exact component={ActivatePage} />
                <Route path="/user/reset-password/:id" exact component={ResetPasswordPage} />
                <Redirect to={"/"} />
            </Switch>
        )
    }

}

export default withRouter(useRoutes);