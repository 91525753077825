import React, {useState, useEffect, useContext, useCallback} from 'react'
import {NavLink, useHistory, useParams} from 'react-router-dom'
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import {TariffContext} from "../context/TariffContext";
import {Tokens} from "./Tokens";
import {Profile} from "./Profile";
import {TokensContext} from "../context/TokensContext";
import {Tariffs} from "./Tariffs";
import {Stats} from "./Stats";
import {Supplies} from './Supplies';

export const Personal = () => {
    const {loading, request} = useHttp();
    const {activeTariff, setActiveTariff} = useContext(TariffContext);
    const auth = useContext(AuthContext);
    const {delAllTokens} = useContext(TokensContext);
    const history = useHistory();
    const page = useParams().id;

    if(!useParams().id) {
        history.push('/personal/profile')
    }   

    const [allTariffs, setTariffs] = useState([]);
    const [currentTariff, setCurrentTariff] = useState([]);

    const getTariffs = useCallback(async() => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/tariffs/`, 'GET', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${auth.jwt}`
            });
            setTariffs(data['tariffs'])
        } catch (e) {}
    },[auth.jwt, request]);

    useEffect(() => {
        getTariffs();
    }, [getTariffs]);
    
    useEffect(() => {
        setCurrentTariff(allTariffs.filter((tariff) => !!tariff['expire_at']));
    }, [allTariffs])

    const logoutHandler = event => {
        event.preventDefault()
        delAllTokens()
        auth.logout()
        history.push('/')
    }

    if(loading) {
        return <div>LOADING</div>
    }
    if(!auth.userInfo) {
        return <div>LOADING</div>
    }
    const isAdmin = auth.userInfo['is_admin']

    return (
        <div className="content-section">
            <div className="header-bar">
                <h1>{`${auth.userInfo.first_name} ${auth.userInfo.last_name}`}</h1>
                <div className="btn btn-hard-gray" onClick={logoutHandler}>Выйти</div>
            </div>
            <div className="tab-bar-container">
                <div className="tab-bar-nav">
                    <NavLink to="/personal/profile" className="tab-bar-nav-item">Аккаунт</NavLink>
                    {/*<div className="tab-bar-nav-item">Пользователи</div>*/}
                    <NavLink to="/personal/token" className="tab-bar-nav-item">API-ключи</NavLink>
                    {/*<div className="tab-bar-nav-item">Оплата</div>*/}
                    <NavLink to="/personal/tariffs" className="tab-bar-nav-item">Тарифы</NavLink>
                    {auth.isDevShow && <NavLink to="/personal/supplies" className="tab-bar-nav-item">Управление поставками</NavLink>}
                    {isAdmin && <NavLink to="/personal/stats" className="tab-bar-nav-item">Статистика</NavLink>}

                </div>
                <div className="tab-content">
                    <div className="an-p-row an-row">
                        {/*<div className="an-col-16">*/}
                        {/*    <div className="round-card">*/}
                        {/*        <h3>Персональные данные</h3>*/}
                        {/*        <form className="onboarding-form">*/}
                        {/*            <label htmlFor="login-fio">Имя и фамилия</label>*/}
                        {/*            <input className="form-input" type="text" id="login-fio" placeholder="Maxim Skuratov" />*/}
                        {/*            <label htmlFor="login-email">Электронная почта</label>*/}
                        {/*            <input className="form-input" type="email" id="login-email" placeholder="Введите эл. почту" />*/}
                        {/*            <label htmlFor="login-text">Номер телефона</label>*/}
                        {/*            <input className="form-input" type="text" id="login-text" placeholder="Введите номер телефона" />*/}
                        {/*        </form>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="an-col-8">*/}
                        {/*    <div className="round-card">*/}

                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="an-col-16">*/}
                        {/*    <div className="round-card">*/}
                        {/*        <h3>Изменить пароль</h3>*/}
                        {/*        <form className="onboarding-form">*/}
                        {/*            <label htmlFor="login-password-1">Введите текущий пароль</label>*/}
                        {/*            <input className="form-input" type="password" id="login-password-1" placeholder="Введите пароль" />*/}
                        {/*            <label htmlFor="login-password">Введите новый пароль</label>*/}
                        {/*            <input className="form-input" type="password" id="login-password" placeholder="Введите пароль" />*/}
                        {/*            <div className="btn-block">*/}
                        {/*                <div className="btn btn-blue">Изменить пароль</div>*/}
                        {/*            </div>*/}
                        {/*        </form>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="an-col-8">*/}
                        {/*    <div className="round-card"></div>*/}
                        {/*</div>*/}
                        {page === 'profile' &&
                            <Profile />
                        }
                        {page === 'token' &&
                            <Tokens />
                        }
                        {page === 'tariffs' &&
                            <Tariffs allTariffs={allTariffs}/>
                        }
                        {page === 'stats' &&
                            <Stats />
                        }
                        {page === 'supplies' &&
                            <Supplies />
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
