import React, { useState } from 'react'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE, useDateInput } from 'react-nice-dates'
import {Link} from "react-router-dom";
import moment from "moment";

export default function DatePickerCalendarWolbe({filterType, uiState, minDate, dateFrom, dateTo, allSearchParams, setDateParams, setOpen, storeName, type, searchParamName, firstDateFrom = null, firstDateTo = null}) {
    // let intervalDiff = null
    //
    // if (filterType === 'date-simple-second' && !dateFrom && !dateTo) {
    //     intervalDiff = moment(firstDateTo) - moment(firstDateFrom);
    //     intervalDiff = moment(intervalDiff).format('DD');
    // }

    const isSecondInterval = filterType === 'date-simple-second';

    const calcNextDate = (period, date) => {
        let dateOffset = (24*60*60*1000) * period;
        let startDate = new Date();
        date.setHours(0,0,0,0);
        startDate.setHours(0,0,0,0);
        startDate.setTime(startDate.getTime() + dateOffset);
        return startDate;
    }

    const maximumDate = type === 'sort' ? calcNextDate( 365,  new Date()) :  moment().subtract(0, 'days').toDate(); //moment().subtract(1, 'days').valueOf()
    const minimumDate = minDate === false ? new Date() : new Date(minDate);

    function reFormatDate(date) {
        return format(date, 'yyyy-MM-dd', {
            locale: ru
        });
    }

    const initStartDate = !dateFrom ? new Date() : new Date(dateFrom);
    const initEndDate = !dateTo ? new Date() : new Date(dateTo);

    const [startDate, setStartDate] = useState(initStartDate);
    const [endDate, setEndDate] = useState(initEndDate);

    const setStartDateHandler = (date) => {
        setStartDate(date)
        setEndDate(null)
    }
    const setEndDateHandler = (date) => {
        // if(moment(date).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')) {
        //     setStartDate(moment(startDate).subtract(1, 'days').toDate())
        // }
        setEndDate(date)
    }

    const [focus, setFocus] = useState(START_DATE);
    const [calOpen, setCalOpen] = useState(true);
    const inputStartProps = useDateInput({
        date: startDate,
        format: 'dd-MM-yyyy',
        locale: ru,
        minimumDate: minimumDate,
        onDateChange: setStartDate
    })
    const inputEndProps = useDateInput({
        date: endDate,
        format: 'dd-MM-yyyy',
        locale: ru,
        maximumDate: maximumDate,
        onDateChange: setEndDate
    })
    const handleFocusChange = newFocus => {
        setFocus(newFocus || START_DATE)
    }
    const handleClickRadio = (period, date) => () => {
        date = new Date(date);
        let startDate = moment().subtract(period, 'days').toDate()
        startDate = startDate >= date ? startDate : date;
        setStartDate( startDate );
        setEndDate( moment().subtract(1, 'days').toDate() );
        // setCalOpen(false);
    }
    let newAllSearchParams = [...allSearchParams];
    newAllSearchParams = newAllSearchParams.filter((param) =>  param.indexOf(`${searchParamName}date_from`) < 0  && param.indexOf(`${searchParamName}date_to`)< 0 && param.indexOf('page') < 0 && param.indexOf('limit') < 0);

    const isActive = uiState.active.filter((key) => key === filterType).length > 0;
    return (
        <div data-tut="forecast_period" className="filter-item filter-date">
            {type === 'filter' &&
                <div className={`btn-filter active ${isActive ? 'filter-open' : ''}`}
                     onClick={() => setOpen(filterType, false)}
                >
                    {dateFrom === '' && dateTo === '' ? searchParamName === 'second_interval_' ?
                        <i className="an-ico an-ico-plus">{}</i> : 'Выбор даты' :
                        <div className="date-val">{moment(dateFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')} - {moment(dateTo, 'YYYY-MM-DD').format('DD.MM.YYYY')}</div>
                    }
                    {/*{dateFrom !== '' && dateTo !== '' && searchParamName !== 'second_interval_' || searchParamName === 'first_interval_' &&*/}
                    {/*    <i className="an-ico an-ico-drop-arrow">{}</i>*/}
                    {/*}*/}
                </div>
            }
            {type === "sort" &&
                <span className={`action-val cal-right under-dashed ${isActive ? 'filter-open' : ''}`} onClick={() => setOpen(filterType, false)}>Выбор даты</span>
            }
            <div className="drop-filter">
                <div className="drop-filter-cal">
                    <div className="cal-radio-group btns">
                        <div className="radio-block">
                            {/*<input type="radio" name={`${searchParamName}${type}-cal-radio`} className="custom-radio" id={`${searchParamName}${type}r-1`} onChange={handleClickRadio(type === 'sort' ? 14 : -14, minDate)}/>*/}
                            {/*<label htmlFor={`${searchParamName}${type}r-1`}>*/}
                            {/*    <div className="label-group">*/}
                            {/*        <div className="label-h">14 дней</div>*/}
                            {/*        /!*<div className="label-txt">Для сезонных товаров</div>*!/*/}
                            {/*    </div>*/}
                            {/*</label>*/}
                            <div className="btn btn-blue" onClick={handleClickRadio(type === 'sort' ? 14 : 14, minDate)}>14 дней</div>
                        </div>
                        <div className="radio-block">
                            {/*<input type="radio" name={`${searchParamName}${type}-cal-radio`} className="custom-radio" id={`${searchParamName}${type}r-2`} onChange={handleClickRadio(type === 'sort' ? 30 : -30, minDate)}/>*/}
                            {/*<label htmlFor={`${searchParamName}${type}r-2`}>*/}
                            {/*    <div className="label-group">*/}
                            {/*        <div className="label-h">30 дней</div>*/}
                            {/*        /!*<div className="label-txt">Для сезонных товаров</div>*!/*/}
                            {/*    </div>*/}
                            {/*</label>*/}
                            <div className="btn btn-blue" onClick={handleClickRadio(type === 'sort' ? 30 : 30, minDate)}>30 дней</div>
                        </div>
                        <div className="radio-block">
                            {/*<input type="radio" name={`${searchParamName}${type}-cal-radio`} className="custom-radio" id={`${searchParamName}${type}r-3`} onChange={handleClickRadio(type === 'sort' ? 90 : -90, minDate)}/>*/}
                            {/*<label htmlFor={`${searchParamName}${type}r-3`}>*/}
                            {/*    <div className="label-group">*/}
                            {/*        <div className="label-h">90 дней</div>*/}
                            {/*        /!*<div className="label-txt">Для сезонных товаров</div>*!/*/}
                            {/*    </div>*/}
                            {/*</label>*/}
                            <div className="btn btn-blue" onClick={handleClickRadio(type === 'sort' ? 90 : 90, minDate)}>90 дней</div>
                        </div>
                        {/*<div className="radio-block">*/}
                        {/*    <input type="radio" name={`${searchParamName}${type}-cal-radio`} className="custom-radio" id={`${searchParamName}${type}r-4`} onChange={(e) => setCalOpen(e.target.value === 'on')}/>*/}
                        {/*    <label htmlFor={`${searchParamName}${type}r-4`}>*/}
                        {/*        <div className="label-group">*/}
                        {/*            <div className="label-h">Свой период</div>*/}
                        {/*            /!*<div className="label-txt">Выбрать свое количество дней</div>*!/*/}
                        {/*        </div>*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                    </div>
                    {isActive && calOpen && <>
                        {/*<p>Активна: {focus}.</p>*/}
                        <DateRangePickerCalendar
                            startDate={startDate}
                            endDate={endDate}
                            focus={focus}
                            onStartDateChange={(date) => setStartDateHandler(date)}
                            onEndDateChange={(date) => setEndDateHandler(date)}
                            maximumDate={maximumDate}
                            minimumDate={minimumDate}
                            onFocusChange={handleFocusChange}
                            locale={ru}
                        />
                        <div className="date-input-block">
                            <input className={`input date-input`} {...inputStartProps} onFocus={() => setFocus('startDate')}/>
                            <div className="date-line">{}</div>
                            <input className={`input date-input`} {...inputEndProps} onFocus={() => setFocus('endDate')}/>
                        </div>
                    </>}
                    <div className="cal-btn-block">
                        { startDate && endDate &&
                        <Link to={`?${newAllSearchParams.join('&')}&${searchParamName}date_from=${reFormatDate(startDate)}&${searchParamName}date_to=${reFormatDate(endDate)}`}
                              className="date-submit btn btn-blue"
                              onClick={() => {
                                  setDateParams({
                                      date_from: reFormatDate(startDate),
                                      date_to: reFormatDate(endDate),
                                      storeName: storeName,
                                      searchParamName: searchParamName
                                  });
                                  setOpen(filterType, true)}
                              }
                        >Применить</Link>
                        }
                        { isSecondInterval &&
                        <Link to={`?${newAllSearchParams.join('&')}`}
                              className="date-submit btn btn-gray"
                              style={{marginLeft: 10}}
                              onClick={() => {
                                  setDateParams({
                                      date_from: null,
                                      date_to: null,
                                      storeName: storeName,
                                      searchParamName: searchParamName
                                  });
                                  setOpen(filterType, true)}
                              }
                        >Отменить</Link>
                        }
                    </div>
                </div>
                <div className="drop-filter-bg" onClick={() => setOpen(filterType, false)}>{}</div>
            </div>
        </div>
    )

}
