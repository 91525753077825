import {createContext} from 'react';

function noop() {}

export const TokensContext = createContext({
    tokens: null,
    activeToken: null,
    initTokens: noop,
    delAllTokens: noop,
    setNewActiveToken: noop,
    setActiveToken: noop,
})