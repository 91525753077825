import React from "react";
import { connect } from 'react-redux';
import * as actions from '../actions/index'
import { createSelector } from "reselect";

const getItems = (state, itemKey, propVal, storeName) => {
    return state.cardCheckbox[storeName][itemKey] !== undefined ? state.cardCheckbox[storeName][itemKey].active : false;
};

const inputValSelector = createSelector(
    [getItems],
    (item) => {
        return item;
    }
)


const mapStateToProps = (state, props) => {
    let inputVal = '';
    const storeName = props.storeName;
    if(state.cardCheckbox[storeName] !== undefined) {
        inputVal = inputValSelector(state, props.itemKey, 'active', storeName);
    }
    // console.log(inputVal);
    return {inputVal};
};

const actionCreators = {
    setCheckboxVal: actions.setCheckboxVal
};

class CardCheckbox extends React.Component {
    handleOptionChange = (itemKey, currentVal, storeName, groupHeader, groupUniqKey, uniqKeysInGroup) => () => {
        const {setCheckboxVal} = this.props;
        if(groupHeader) {
            console.log('currentVal => ', currentVal)
            uniqKeysInGroup.forEach((itemKey) => {
                setCheckboxVal({itemKey, currentVal, storeName});
            })
            // setCheckboxVal({itemKey, currentVal, storeName});
        } else {
            setCheckboxVal({itemKey, currentVal, storeName});
            if(groupUniqKey) {
                // setCheckboxVal({itemKey: groupUniqKey, currentVal, storeName});
            }
        }
    }

    render() {
        let { inputVal, itemKey, storeName, groupHeader, uniqKeysInGroup, groupUniqKey, isGroupFull } = this.props;
        // const isGroupCheckboxActive = groupHeader ? uniqKeysInGroup.filter()
        return (
            <div className="goods-item-check">
                <div className="check option">
                    <input type="checkbox" className="checkbox" id={`card-${itemKey}`} checked={groupHeader ? isGroupFull : inputVal}
                           onChange={this.handleOptionChange(itemKey, groupHeader ? isGroupFull : inputVal, storeName, groupHeader, groupUniqKey, uniqKeysInGroup)}/>
                    <label htmlFor={`card-${itemKey}`} className="checkbox-a">{}</label>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, actionCreators)(CardCheckbox);
