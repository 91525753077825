import React, { useContext, useState } from "react";
import { useHttp } from "../hooks/http.hook";
import { AuthContext } from "../context/AuthContext";
import { Notification } from "./Notification";

const QuizTextArea = ({ field, onChange, value }) => {
    return (
        <textarea
            className="quiz-textarea form-input"
            id={`textarea-${field.name}`}
            placeholder="другие"
            value={value}
            onChange={(e) => onChange(`textarea-${field.name}`, e.target.value)}
        />
    )
}

export const Quiz = ({ visible, setVisible }) => {
    const { jwt, user, userInfo } = useContext(AuthContext);

    const quizFields = [
        {
            name: 'search',
            label: 'поиск товара для продажи',
            type: 'checkbox',
            textarea: false,
        },
        {
            name: 'control',
            label: 'контроль остатков и поставок',
            type: 'checkbox',
            textarea: false,
        },
        {
            name: 'analyzeSales',
            label: 'анализ продаж',
            type: 'checkbox',
            textarea: false,
        },
        {
            name: 'priceCalculation',
            label: 'расчет цен товаров',
            type: 'checkbox',
            textarea: false,
        },
        {
            name: 'analyze',
            label: 'анализ конкурентов',
            type: 'checkbox',
            textarea: false,
        },
        {
            name: 'other',
            label: 'другие',
            type: 'checkbox',
            textarea: true,
        }
    ]
    const initState = {};
    quizFields.map((field) => {
        initState[field.name] = false;
        if (!!field.textarea) {
            initState[`textarea-${field.name}`] = '';
        }
    })
    const [state, setState] = useState(initState);
    const { loading, error, request } = useHttp();
    console.log('state => ', state);
    const changeHandler = (fieldName, value) => {
        setState((prev) => {
            return {
                ...prev,
                [fieldName]: value
            }
        });

    }
    const buildAnswer = () => {
        let answer = '';
        if (!!state.search) answer += quizFields.find(x => x.name === 'search').label + ';';
        if (!!state.priceCalculation) answer += quizFields.find(x => x.name === 'priceCalculation').label + ';';
        if (!!state.analyzeSales) answer += quizFields.find(x => x.name === 'analyzeSales').label + ';';
        if (!!state.control) answer += quizFields.find(x => x.name === 'control').label + ';';
        if (!!state.analyze) answer += quizFields.find(x => x.name === 'analyze').label + ';';
        if (!!state.other) answer += quizFields.find(x => x.name === 'other').label + ';';
        if (!!state[`textarea-other`]) answer += state[`textarea-other`] + ';';
        return answer;
    }
    const isFormFilledValid = () => {
        if (
            !!state.search ||
            !!state.priceCalculation ||
            !!state.analyzeSales ||
            !!state.control ||
            !!state.analyze ||
            (
                !!state.other &&
                !!state[`textarea-other`]
            )
        ) {
            return true;
        }
        return false;
    }
    const fetchQuiz = async (e) => {
        e.preventDefault();
        if (!isFormFilledValid()) {
            Notification({ msg: 'Надо выбрать хотя бы одно поле или заполнить поле "другие"' })
            return;
        }
        try {
            const data = await request(
                `https://${process.env.REACT_APP_DOMAIN}/api/user/questionary/`,
                'POST',
                {
                    answer: buildAnswer()
                },
                {
                    'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
                }

            );
            console.log('data ======> ', data)
            if (!!data) {
                if (localStorage.getItem('isNewAccount') !== null) {
                    setVisible(false);
                    document.location.href = '/personal/token';
                    localStorage.removeItem('isNewAccount');
                } else {
                    setVisible(false);
                }
            }
        } catch (e) {
            console.log('error => ', e);
        }
    }
    return (
        <div
            className={"onboarding-wrapper"}
        >
            <div className="quiz-content">
                <div className="onboarding-h">
                    Какие задачи вы хотите сейчас решить с помощью сервиса?
                </div>
                <form
                    className="onboarding-form"
                    onSubmit={fetchQuiz}
                >
                    {quizFields.map((field) => {

                        return (
                            <React.Fragment key={field.name}>
                                <div className="check option">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={field.name}
                                        required=""
                                        checked={state[field.name]}
                                        onChange={() => changeHandler(field.name, !state[field.name])}
                                    />
                                    <label
                                        htmlFor={field.name}
                                        className="checkbox-a"
                                    >
                                        {field.label}
                                    </label>
                                </div>
                                {
                                    !!field.textarea &&
                                    !!state[field.name] &&
                                    <QuizTextArea
                                        field={field}
                                        value={state[`textarea-${field.name}`]}
                                        onChange={changeHandler}
                                    />
                                }
                            </React.Fragment>
                        )
                    })}
                    <div className="btn-block">
                        <button
                            type={"submit"}
                            className="btn btn-blue"
                            onClick={fetchQuiz}
                            disabled={loading}
                        >
                            Отправить
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
