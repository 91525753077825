import React, {useContext, useState} from "react";
import {CustomDropZone} from "./CustomDropZone";
import {AuthContext} from "../../context/AuthContext";
import {useHttp} from "../../hooks/http.hook";

export const UploadFileTabs = ({tabsObj, reloadListing, activeToken, handleCloseMenu}) => {
    const {request} = useHttp();
    const [activeTab, setActiveTab] = useState(Object.keys(tabsObj)[0]);
    const [loading, setLoading] = useState(false);

    const {jwt} = useContext(AuthContext);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }
    const getFile = async (fileType) => {
        setLoading(true);

        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/templates/download/${fileType}/`, 'POST', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            }, true);
            if(data) {
                setLoading(false);
                // handleCloseMenu();
            }
        } catch (e) {}
    }
    return (
        <>
            <div className="menu-header">
                <i className="menu-close an-ico an-ico-close" onClick={() => handleCloseMenu()}>{}</i>
                <div>Загрузить свои данные</div>
            </div>
            <div className="menu-content">
                <div className="menu-tabs">
                    {Object.keys(tabsObj).map((tab, index) => {
                        return (
                            <div className={`tab-item ${tab === activeTab ? 'active' : ''}`}
                                 onClick={() => handleTabClick(tab)}
                                 key={`file-upload-tab-${index}`}
                            >{tabsObj[tab].name}</div>
                        )
                    })}
                </div>
                <div className="option-group-title">{tabsObj[activeTab].name}</div>
                <p className={"label-txt"}>
                    С данными по своим остаткам удобнее корректировать количествово отгружаемых товаров
                </p>
                <CustomDropZone jwt={jwt}
                             currentUploadTabMethod={activeTab}
                             reloadListing={reloadListing}
                             activeToken={activeToken}
                />
                <p className={""}>Загрузите данные из «1С» или «Мой склад» в формате Excel. Или воспользуйтесь шаблоном и заполните его вручную</p>
                <div className="menu-link">
                    <div className="get-file" onClick={() => getFile(tabsObj[activeTab].filePath)}><i className="an-ico an-ico-download">{}</i>{`Скачать шаблон отчета «${tabsObj[activeTab].name}»`}</div>
                </div>
            </div>
        </>
    )
}
