import {useState, useCallback} from 'react'
import {Notification} from "../components/Notification";

export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const request = useCallback(async (url, method = 'GET', body = null, headers = {}, fileDownload = false, userActivate = false) => {
        setLoading(true);
        try {
            if (body) {
                body = JSON.stringify(body);
                headers['Content-Type'] = 'application/json';
            }
            const resp = await fetch(url, {method, body, headers})
            if(fileDownload) {
                if (!resp.ok) {
                    throw new Error('что то не так')
                }
                resp.blob()
                    .then((blob) => {
                        window.open(URL.createObjectURL(blob), '_self')
                    })
                return true;
            } else {
                const data = await resp.json();
                if(resp.status === 410) { //410
                    return 'reSend'
                } else if(resp.status === 404) {
                    return false
                } else if(resp.status === 401) {
                    localStorage.removeItem('userData');
                    //document.location.href = '/';
                    throw new Error(data.response['errorMessage'] || 'ошибка авторизации')
                } else if (!resp.ok) {
                    throw new Error(data.response['errorMessage'] || 'что то не так')
                }
                setLoading(false)
                return data.response
            }

        } catch (e) {
            // console.log(e);
            setLoading(false)
            setError(e.message)
            Notification({msg: e.message})
            throw e
        }
    }, [])
    const clearError = () => {setError(null)}

    return {loading, request, error, clearError}
}
