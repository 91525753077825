import React from "react";

export const ViewMenu = ({handleCloseMenu, displaySettings, currentPropsInCard, storeName, handleOptionChange}) => {
    return (
        <>
            <div className="menu-header">
                <i className="menu-close an-ico an-ico-close" onClick={handleCloseMenu}>{}</i>
                <div>Настройка отображения данных</div>
            </div>
            <div className="menu-content">
                {Object.keys(displaySettings).map((groupTitle) => {
                    return (
                        <div className="menu-option-group" key={`view-prop-${groupTitle}`}>
                            <div className="option-group-title">{groupTitle}</div>
                            <div className="option-group-list">
                                {Object.keys(displaySettings[groupTitle]).map((propId) => {
                                    return (
                                        <div className="check option" key={`view-prop-${groupTitle}-${propId}`}>
                                            <input type="checkbox" className="checkbox" id={`confirm-${propId}`} checked={currentPropsInCard[propId]} onChange={handleOptionChange(propId, storeName)}/>
                                            <label htmlFor={`confirm-${propId}`} className="checkbox-a">{displaySettings[groupTitle][propId] !== '' ? displaySettings[groupTitle][propId] : propId}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}