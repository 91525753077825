import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import moment from "moment";


export const Stats = () => {
    const {jwt} = useContext(AuthContext);
    const {request, loading} = useHttp();
    const [users, setUsers] = useState([]);
    const [registrations, setRegistrations] = useState(0)

    const getUsers = useCallback(async() => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/user/stats/?limit=10`, 'GET', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            });

            setUsers(data['users'])
            setRegistrations(data['registrations'])
        } catch (e) {}
    },[jwt, request]);

    useEffect(() => {
        getUsers()
    }, [getUsers])
    if(users.length === 0) {
        return null
    }
    const formatter = (date) => {
        if(!date) {
            return date
        }
        let newDate = moment(date)
        if(!newDate) {
            return false
        }
        return [newDate.format('DD-MM-YYYY'), newDate.format('HH:mm:ss')]
    }
    const header = Object.keys(users[0])

    return (
        <div className="an-col-24">
            <h4 style={{marginBottom: 20}}>{`Кол-во регистраций ${registrations}`}</h4>
            <table className='stat-table'>
                <thead>
                <tr>
                    {header.map((item) => <th key={item}>{item}</th>)}
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => {
                    return (
                        <tr key={`tr-${index}`}>
                            {Object.keys(user).map((field, userIndex, value) => {
                                if(field === 'last_login_at' || field === 'activated_at' || field === 'created_at') {
                                    if (!formatter(user[field])) return <td key={`tr-${index}-${userIndex}`} className="field-value">---</td>
                                    return (
                                        <td key={`tr-${index}-${userIndex}`} className="field-value">
                                            <div className='td-date'>{formatter(user[field])[0]}</div>
                                            <div className='td-date'>{formatter(user[field])[1]}</div>
                                        </td>
                                    )
                                }
                                return (
                                    <td key={`tr-${index}-${userIndex}`} className="field-value">{user[field]}</td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {/*{users.map((user, index) => {*/}
            {/*    return (*/}
            {/*        */}
            {/*        // <div className="an-col-12" key={`user-${index}`}>*/}
            {/*        //     <div className="user-item">*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>e-mail:</div>*/}
            {/*        //             <div className="field-value">{user['email']}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>last_name:</div>*/}
            {/*        //             <div className="field-value">{user['last_name']}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>first_name:</div>*/}
            {/*        //             <div className="field-value">{user['first_name']}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>second_name:</div>*/}
            {/*        //             <div className="field-value">{user['second_name']}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>phone:</div>*/}
            {/*        //             <div className="field-value">{user['phone']}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>login_counter:</div>*/}
            {/*        //             <div className="field-value">{user['login_counter']}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>last_login_at:</div>*/}
            {/*        //             <div className="field-value">{formatter(user['last_login_at'])}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>activated_at:</div>*/}
            {/*        //             <div className="field-value">{formatter(user['activated_at'])}</div>*/}
            {/*        //         </div>*/}
            {/*        //         <div className='field-item'>*/}
            {/*        //             <div className='field-name'>created_at:</div>*/}
            {/*        //             <div className="field-value">{formatter(user['created_at'])}</div>*/}
            {/*        //         </div>*/}
            {/*        //     </div>*/}
            {/*        // </div>*/}
            {/*    )*/}
            {/*})}*/}
        </div>
    )
}
