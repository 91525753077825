import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

export const CheckTariff = ({ hasActiveTariff = true }) => {
    const history = useHistory();
    console.log('hasActiveTariff => ', hasActiveTariff);
    useEffect(() => {
        if (!hasActiveTariff) {
            history.push('/personal/profile');
        } else {
            history.push('/delivery-forecast');
        }
    }, [hasActiveTariff])

    return null
}
