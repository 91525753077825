import React, { useState, PureComponent, useContext } from 'react';
import QuantityInput from "./QuantityInput";
import CardCheckbox from "./CardCheckbox"
import GoodsImg from './GoodsImg';
import { Pie, PieChart, Cell } from "recharts";
import { AuthContext } from "../context/AuthContext";
import { TokensContext } from "../context/TokensContext";
import { useHttp } from "../hooks/http.hook";

const isNumeric = (str) => {
    let newStr = parseInt(str)
    if (!newStr) return false
    if (newStr.length !== str.length) {
        return false
    }
    if (parseInt(str)) {
        return true
    }
    if (parseFloat(str)) {
        return true
    }
    return false
    // if (typeof str != "string") return false // we only process strings!
    // return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    //     !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

let formatter = new Intl.NumberFormat("ru", {
    // maximumSignificantDigits: 3,
    maximumFractionDigits: 1
});

const makeFormat = ((value, propName) => {
    if (isNumeric(value)) {
        return formatter.format(value)
    }
    return value
})


class CardItem extends PureComponent {
    render() {
        const { reloadListing, viewType, itemKey, getSettingActivity, storeName } = this.props
        let { cardConfig, keyConfig, goodItem, disableActivity, setFetchParams, groupHeader, handleGroupActive, uiState } = this.props;
        const mathRound = (val) => {
            return +parseFloat(val).toFixed(2)
        }
        const SimpleProp = ({ propName, propVal, question, endStr, withColor }) => {
            if (propName === 'id WB') {
                return (
                    <>
                        <span className="prop-name color-dark-blue">{!propName ? '' : `${propName}\u00a0`}</span>
                        <a className={'under-dashed'} href={`https://www.wildberries.ru/catalog/${goodItem[propVal]}/detail.aspx`} target="_blank" rel="noreferrer">{goodItem[propVal]}</a>
                    </>
                )
            }
            if (propName === 'штрихкод') {
                return (
                    <>
                        <span className="prop-name color-dark-blue">{!propName ? '' : `${propName}\u00a0`}</span>
                        <span>{goodItem[propVal]}</span>
                    </>
                )
            }
            if (propName === 'оборачиваемость') {
                return (
                    <AuthContext.Consumer>
                        {prop => {
                                if (prop.isDevShow) {
                                    return (
                                        <>
                                            <span className="prop-name color-dark-blue">{!propName ? '' : `${propName}\u00a0`}</span>
                                            <span>{goodItem[propVal]}</span>
                                        </>
                                    )
                                } else {
                                    return;
                                }
                            }
                        }
                    </AuthContext.Consumer>
                )
            }
            return (
                <>
                    <span className={`prop-name color-dark-blue ${propVal === 'price_client' ? 'bold' : ''}`}>
                        {!propName || propVal === 'item_name' ? '' : `${propName}\u00a0`}
                    </span>
                    <span className={`prop-val ${withColor ? goodItem[propVal] > 0 ? 'color-red' : 'color-green' : ''} ${propVal === 'price_client' ? 'bold' : ''} ${propVal === 'lost_profit' && goodItem[propVal] < 0 ? 'color-red' : ''}`}>
                        {/*{!propVal ? 'NaN' : !!parseInt(goodItem[propVal]) ? `${mathRound(goodItem[propVal])} ${!!endStr ? endStr : ''}` : goodItem[propVal]}*/}
                        {`${!propVal ? 'NaN' : makeFormat(goodItem[propVal])} ${!!endStr && endStr !== 'rub' ? endStr : ''}`}
                        {endStr === 'rub' && <span className={'rub-font-a'}></span>}
                        {
                            !!question && 
                            <>
                                <span 
                                    className="question" 
                                    data-txt={question}
                                >
                                    <i className="an-ico an-ico-question">{ }</i>
                                </span>
                            </>
                        }
                    </span>
                </>
            )
        }
        const SimpleEditProp = ({ propName, propVal, question }) => {
            const initVal = !propVal ? 'NaN' : goodItem[propVal];
            const [active, setActive] = useState(false);
            const [value, setValue] = useState(initVal);
            const { request } = useHttp();
            const { jwt } = useContext(AuthContext);
            const { activeToken } = useContext(TokensContext);

            const handleInputChange = (newVal) => {
                newVal = parseInt(newVal) ? parseInt(newVal) : 0;
                setValue(newVal.length > 0 && newVal[0] === 0 ? newVal.slice(1) : newVal);
            }

            const fetchVal = async () => {
                try {
                    const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/update-price/`, 'POST', {
                        api_key_id: activeToken.id,
                        barcode: goodItem["barcode"],
                        new_price: value,
                    }, {
                        'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
                    });
                    console.log(parseInt(data.new_price) === parseInt(value));
                    if (parseInt(data.new_price) === parseInt(value)) {
                        reloadListing();
                    } else (
                        console.log('что то не так с себесом')
                    )
                } catch (e) { }
            }
            return (
                <>
                    <span onClick={() => setActive(true)} className="prop-name under-dashed">{!propName || propVal === 'item_name' ? '' : `${propName}\u00a0`}</span>
                    <span onClick={() => setActive(true)} className={`prop-val under-dashed ${propVal === 'lost_profit' ? 'color-red' : ''}`}>
                        {/*{!propVal ? 'NaN' : !!parseInt(goodItem[propVal]) ? `${mathRound(goodItem[propVal])} ${!!endStr ? endStr : ''}` : goodItem[propVal]}*/}
                        {!propVal ? 'NaN' : goodItem[propVal]}
                        {!!question && <>
                            <span className="question" data-txt={question}><i
                                className="an-ico an-ico-question">{ }</i></span>
                        </>}
                    </span>
                    {active &&
                        <div className="popup-value">
                            <div className="popup-wrapper">
                                <div className="prop-name">Себестоимость</div>
                                <input type="text" placeholder="" value={value} onChange={(e) => handleInputChange(e.target.value)} />
                                <div className="popup-border">{ }</div>
                                <div className="btn btn-blue" onClick={() => fetchVal()}>Добавить</div>
                            </div>
                            <div className="popup-bg" onClick={() => setActive(false)}>{ }</div>
                        </div>
                    }
                </>
            )
        }
        const CatCard = ({ propName, propVal }) => {
            return (
                <>
                    <span className="prop-name color-dark-blue">{!propName ? '' : `${propName}\u00a0`}</span>
                    <span className="prop-val">
                        {!propVal ? 'NaN' : goodItem[propVal]}
                    </span>
                </>
            )
        }
        const WithInterest = ({ propName, propVal, endStr, subVal, subName, subValType, question, uiStateValues, uiStateName, uiState }) => {
            let propPostfix = !!uiStateValues && !!uiStateName ? uiStateValues[uiState.selectors[uiStateName]].postfix : '';

            return (
                <>
                    <span className="prop-name color-dark-blue">
                        {!propName ? '' : `${propName}\u00a0`} {!subName ? '' : ` (${goodItem[subName]}%)`}
                        {!!question &&
                            <>
                                <span className="question" data-txt={question}>
                                    <i className="an-ico an-ico-question" />
                                </span>
                            </>
                        }
                    </span>
                    <div className="prop-val">
                        <span>
                            {!propVal ? 'NaN' : !!parseFloat(goodItem[`${propVal}${propPostfix}`]) ? `${mathRound(goodItem[`${propVal}${propPostfix}`])} ${!!endStr && endStr !== 'rub' ? endStr : ''}` : `${!!goodItem[`${propVal}${propPostfix}`] ? goodItem[`${propVal}${propPostfix}`] : 0} ${!!endStr && endStr !== 'rub' ? endStr : ''}`}
                        </span>
                        {endStr === 'rub' && <span className={'rub-font-a'}>{ }</span>}
                        {subValType === 'percent' && !!subVal &&
                            <span className={`${goodItem[`${subVal}${propPostfix}`] !== null ? `prop-percent ${parseInt(goodItem[subVal]) >= 0 ? 'green' : 'red'}` : ''} `}>
                                {goodItem[`${subVal}${propPostfix}`] !== null ? `${parseInt(goodItem[`${subVal}${propPostfix}`]) >= 0 ? "+" : ""}${goodItem[`${subVal}${propPostfix}`]}%` : ''}
                            </span>
                        }
                        {subValType === 'compare' && !!subVal &&
                            <>
                                <span>{!subVal ? 'NaN' : !!parseInt(goodItem[`${subVal}${propPostfix}`]) ? ` → ${mathRound(goodItem[`${subVal}${propPostfix}`])} ${!!endStr && endStr !== 'rub' ? endStr : ''}` : ` → ${!!goodItem[`${subVal}${propPostfix}`] ? goodItem[`${subVal}${propPostfix}`] : 0} ${!!endStr && endStr !== 'rub' ? endStr : ''}`}</span>
                                {endStr === 'rub' && <span className={'rub-font-a'}>{ }</span>}
                            </>
                        }

                    </div>
                </>
            )
        }
        const QuantityInputCalc = ({ initVal, propVal, setFetchParams, endStr }) => {
            let [inputVal, setInputVal] = useState(initVal);
            return (
                <>
                    <div className="calc-input-block">
                        <input type="text" name="goods-item-val" className="goods-input-calc" value={inputVal}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setFetchParams(propVal, inputVal)
                                }
                            }}
                            onChange={(e) => {
                                let value = e.target.value;
                                value = parseInt(value) ? parseInt(value) : 0;
                                setInputVal(value.length > 0 && value[0] === 0 ? value.slice(1) : value)
                            }}
                        />
                        <div className="calc-input-end-str">
                            {endStr[0] === 'rub' &&
                                <span className={'rub-font-b'}>{` ${endStr[1]}`}</span>
                            }
                            {endStr[0] !== 'rub' &&
                                `${endStr[0]} ${endStr[1]}`
                            }
                            <span>{ }</span>
                        </div>
                        {/*{!!endStr && <span> {endStr}</span>}*/}
                    </div>
                </>
            )
        }
        const DiscountProp = ({ propName, propVal, subVal, endStr }) => {
            if (propName === 'промокод') return null;

            let bracketsValue = '';
            switch(propName) {
                case 'СПП':
                    bracketsValue =`(${Math.floor(+(goodItem[subVal]))})`;
                    break;
                case 'скидка':
                    bracketsValue ='';
                    break;
                default: 
                    bracketsValue =`(${makeFormat(mathRound(goodItem[subVal]))})`;
                break;
            }
            return (
                <>
                    <span className="prop-name color-dark-blue">
                        {!propName ? '' : `${propName}\u00a0`}
                    </span>
                    <span className="prop-val">
                        {`${goodItem[propVal]}% ${bracketsValue}`}
                        {`${!!endStr && endStr !== 'rub' ? <span>endStr</span> : ''}`}
                        {endStr === 'rub' && <span className={'rub-font-a'} />}
                    </span>

                </>
            )
        }
        const InStock = ({ propName, propVal, subVal }) => {
            return (
                <>
                    <span className="prop-name color-dark-blue">
                        {!propName ? '' : `${propName}\u00a0`}
                    </span>
                    <br />
                    <span className="prop-val">
                        {`${makeFormat(goodItem[propVal])} из ${goodItem[subVal]} д.`}
                    </span>
                </>
            )
        }
        const StockSpeed = ({ propVal, subVal, separator }) => {
            return (
                <>
                    <span className="prop-name color-dark-blue">{ }</span>
                    <span className="prop-val">
                        {`${mathRound(goodItem[propVal])}${separator}${subVal ? goodItem[subVal] : '1'} д.`}
                    </span>
                </>
            )
        }
        const BalanceProp = ({ subVal }) => {
            let deliveryToUser = goodItem[subVal[2]];
            let deliveryFromUser = goodItem[subVal[3]];
            // let deliveryFromTo = deliveryToUser !== null ? deliveryToUser : 0 + deliveryFromUser !== null ? deliveryFromUser : 0;
            let styleT = {}

            let enoughFor = goodItem[subVal[1]] !== null ? goodItem[subVal[1]] : 0;

            const query = new URLSearchParams(window.location.search);
            let forecastDuration = !!query.get('predict_days') ? query.get('predict_days') : 14;
            let durationDiff = forecastDuration > enoughFor;
            if (durationDiff) {
                let greenLine = goodItem[subVal[0]] === 0 ? 0 : enoughFor * 100 / forecastDuration;
                let redLine = 100 - greenLine;
                styleT = {
                    greenLine: {
                        width: `${greenLine}%`
                    },
                    redLine: {
                        width: `${redLine}%`

                    }
                };
            } else {
                styleT = {
                    greenLine: {
                        width: `100%`
                    },
                    redLine: {
                        width: `0%`

                    }
                };
            }

            // if (deliveryFromTo === 0) {
            //     styleT = {
            //         deliveryToUser: {
            //             width: `0%`
            //         },
            //         deliveryFromUser: {
            //             width: `100%`
            //         }
            //     }
            // } else {
            //     let toUserVal = (deliveryToUser !== null ? deliveryToUser : 0) * 100 / deliveryFromTo;
            //     let fromUserVal = (deliveryFromUser !== null ? deliveryFromUser : 0) * 100 / deliveryFromTo;
            //     styleT = {
            //         deliveryToUser: {
            //             width: `${toUserVal}%`
            //         },
            //         deliveryFromUser: {
            //             width: `${(toUserVal + fromUserVal) === 0 ? 0 : fromUserVal}%`
            //         }
            //     };
            // }
            return (
                <>
                    {/*    <div className="chart-pie">*/}
                    {/*    <Doughnut*/}
                    {/*        width={100}*/}
                    {/*        height={100}*/}
                    {/*        data={{*/}
                    {/*            // labels: ["выкупы:", "отказы:", "возвраты:"],*/}
                    {/*            datasets: [{*/}
                    {/*                // label: "",*/}
                    {/*                data: [300, 50, 100],*/}
                    {/*                backgroundColor: [*/}
                    {/*                    '#1ECC6E',*/}
                    {/*                    '#F97C6B',*/}
                    {/*                    '#94D2FF'*/}
                    {/*                ],*/}
                    {/*                hoverBackgroundColor: [*/}
                    {/*                    '#1ECC6E',*/}
                    {/*                    '#F97C6B',*/}
                    {/*                    '#94D2FF'*/}
                    {/*                ]*/}
                    {/*            }],*/}
                    {/*        }}*/}
                    {/*        options={{*/}
                    {/*            cutoutPercentage: 85,*/}
                    {/*            animation: {*/}
                    {/*                duration: 0*/}
                    {/*            },*/}
                    {/*            hover: {*/}
                    {/*                animationDuration: 0*/}
                    {/*            },*/}
                    {/*            responsiveAnimationDuration: 0,*/}
                    {/*            maintainAspectRatio: false,*/}
                    {/*        }}/>*/}
                    {/*    /!*<svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">*!/*/}
                    {/*    /!*    <circle className="sector" r="25" cx="50" cy="50" style={{strokeDasharray: "156.067, 156.067", strokeDashoffset: "156.067", stroke: "rebeccapurple"}}/>*!/*/}
                    {/*    /!*    <circle className="sector" r="25" cx="50" cy="50" style={{strokeDasharray: "90.36404, 156.067", stroke: "red"}}/>*!/*/}
                    {/*    /!*    <circle className="sector" r="25" cx="50" cy="50" style={{strokeDasharray: "79.36404, 156.067"}}/>*!/*/}
                    {/*    /!*    <circle className="border" r="49.5" cx="50" cy="50" />*!/*/}
                    {/*    /!*    <circle className="inner" r="40" cx="50" cy="50" />*!/*/}
                    {/*    /!*</svg>*!/*/}
                    {/*</div>*/}
                    <p>{goodItem[subVal[0]]} шт. <span className="border-space-left color-dark-blue">
                        <i className={`dot-status ${durationDiff ? 'bg-color-yellow' : goodItem[subVal[0]] === 0 ? 'bg-color-red' : 'bg-color-green'}`}>{ }</i>
                        {goodItem[subVal[0]] === 0 ? 'закончились' : `хватит на ${enoughFor !== 0 ? enoughFor : '?'} д.`}
                    </span>
                    </p>
                    {viewType === 'full' &&
                        <>
                            <div className="goods-status-line">
                                <div className="green-line" style={styleT.greenLine}>{ }</div>
                                <div className="red-line" style={styleT.redLine}>{ }</div>
                            </div>
                            <div className="prop-item">
                                <p>
                                    <span className="prop-name color-dark-blue ">{`к клиенту \u00a0`}</span>
                                    <span className="prop-val">{deliveryToUser} шт.</span>
                                </p>
                            </div>
                            <div className="prop-item">
                                <p>
                                    <span className="prop-name color-dark-blue ">{`от клиента \u00a0`}</span>
                                    <span className="prop-val">{deliveryFromUser} шт.</span>
                                </p>
                            </div>
                        </>
                    }
                </>
            )
        }
        const LightsProp = ({ propVal, subVal }) => {
            return (
                <>
                    <div className="lights-block">
                        <div className="main-value border-space-right">{`${!!goodItem[propVal] ? makeFormat(goodItem[propVal]) : '?'} шт.`}</div>
                        <div className="lights-values">
                            <i className="dot-status bg-color-green" />{`${goodItem[subVal[0]]} в наличии`} <br />
                            <i className="dot-status bg-color-yellow" />{`${goodItem[subVal[1]]} заканчиваются`} <br />
                            <i className="dot-status bg-color-red" />{`${goodItem[subVal[2]]} закончились`}
                        </div>
                    </div>
                </>
            )
        }
        const SalesProp = React.memo(({ propVal, subVal, uiStateValues, uiStateName, uiState }) => {
            if (Object.keys(uiState.selectors).length === 0) {
                return null
            }
            let propPostfix = uiStateValues[uiState.selectors[uiStateName]].postfix;
            let salesCount = goodItem[`${subVal[0]}${propPostfix}`];
            let salesPercent = goodItem[`${subVal[1]}${propPostfix}`];
            let canceledCount = goodItem[`${subVal[2]}${propPostfix}`];
            let canceledPercent = goodItem[`${subVal[3]}${propPostfix}`];
            let refundsCount = goodItem[`${subVal[4]}${propPostfix}`];
            let refundsPercent = goodItem[`${subVal[5]}${propPostfix}`];
            let inWayCount = goodItem[`${subVal[6]}${propPostfix}`];
            let inWayPercent = goodItem[`${subVal[7]}${propPostfix}`];
            const data = [];
            if (salesPercent !== 0) {
                data.push({ name: 'выкупы', value: salesPercent, color: '#1ECC6E' })
            }
            if (canceledPercent !== 0) {
                data.push({ name: 'отказы', value: canceledPercent, color: '#FFD809' })
            }
            if (refundsPercent !== 0) {
                data.push({ name: 'возвраты', value: refundsPercent, color: '#F97C6B' })
            }
            if (inWayPercent !== 0) {
                data.push({ name: 'в пути', value: inWayPercent, color: '#94D2FF' })
            }
            return (
                <>
                    <div className="sales-data">
                        <div className="an-row">
                            <div className="an-col-7">
                                <div className="chart-block">
                                    <div className="chart-block-txt">
                                        <div className="sales-val">{parseFloat(salesPercent).toFixed(0)} %</div>
                                        <div className="sales-type">выкупы</div>
                                    </div>
                                    <PieChart width={80} height={80}>
                                        <Pie
                                            data={data}
                                            innerRadius={37}
                                            outerRadius={40}
                                            fill="#8884d8"
                                            paddingAngle={2}
                                            dataKey="value"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </div>
                            </div>
                            <div className="an-col-17">
                                <div className="sales-data-details">
                                    <div className="an-row">
                                        <div className="an-col-12">Заказы:</div>
                                        <div className="an-col-12">{`${makeFormat(goodItem[propVal])} шт.`}</div>
                                        <>
                                            <div className="an-col-12 color-dark-blue">
                                                <i className="dot-status bg-color-green">{ }</i>
                                                выкупы:
                                            </div>
                                            <div className="an-col-12 color-dark-blue">{salesCount} шт.
                                                ({salesPercent.toFixed(1)} %)
                                            </div>
                                        </>
                                        <>
                                            <div className="an-col-12 color-dark-blue">
                                                <i className="dot-status bg-color-yellow">{ }</i>
                                                отказы:
                                            </div>
                                            <div className="an-col-12 color-dark-blue">{canceledCount} шт.
                                                ({canceledPercent.toFixed(1)} %)
                                            </div>
                                        </>
                                        <>
                                            <div className="an-col-12 color-dark-blue">
                                                <i className="dot-status bg-color-red">{ }</i>
                                                возвраты:
                                            </div>
                                            <div className="an-col-12 color-dark-blue">{refundsCount} шт.
                                                ({refundsPercent.toFixed(1)} %)
                                            </div>
                                        </>
                                        <>
                                            <div className="an-col-12 color-dark-blue">
                                                <i className="dot-status bg-color-light-blue">{ }</i>
                                                в пути:
                                            </div>
                                            <div className="an-col-12 color-dark-blue">{inWayCount} шт.
                                                ({inWayPercent.toFixed(1)} %)
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </>
            )
        })

        return (
            <div className={groupHeader && cardConfig[keyConfig].headerClass ? cardConfig[keyConfig].headerClass : cardConfig[keyConfig].class} key={`col-${itemKey}-${keyConfig}`}>
                {cardConfig[keyConfig].img &&
                    <div className="goods-item-img">
                        {cardConfig[keyConfig].img !== false &&
                            <GoodsImg img={goodItem[cardConfig[keyConfig].img]} />
                        }
                    </div>
                }
                <div className={`goods-description ${cardConfig[keyConfig].goodsDescriptionHeaderClass && groupHeader ? cardConfig[keyConfig].goodsDescriptionHeaderClass : ''}`}>
                    {cardConfig[keyConfig][groupHeader ? 'HeaderItems' : 'CardItems'].map((itemProp) => {
                        const itemActivity = disableActivity ? true : getSettingActivity(itemProp.val);
                        if (!itemActivity) {
                            if (itemProp.type === 'toggleGroupView') {
                                return (
                                    <div className="prop-item">
                                        <div className={`toggle-group-view`} onClick={handleGroupActive}>
                                            <div className="an-ico an-ico-ico-dots" />
                                        </div>
                                    </div>
                                )
                            }
                            return null
                        }
                        return (
                            <div className="prop-item"
                                key={`prop-${itemKey}-${itemProp.val === '' ? Math.random() : itemProp.val}`}>
                                {
                                    itemActivity && itemProp.type === "simple" &&
                                    <SimpleProp 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val} 
                                        endStr={itemProp.endStr}
                                        question={itemProp.question} 
                                        withColor={!!itemProp.withColor} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "simpleEdit" &&
                                    <SimpleEditProp 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val} 
                                        endStr={itemProp.endStr}
                                        question={itemProp.question} 
                                    />
                                }
                                {
                                 itemActivity && itemProp.type === "cat-card" &&
                                    <CatCard 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val} 
                                        subVal={itemProp.subVal}
                                        endStr={itemProp.endStr} 
                                        question={itemProp.question} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "with-interest" &&
                                    <WithInterest 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val}
                                        uiStateValues={!!itemProp.salesTypeSelector ? itemProp.salesTypeSelector.uiStateValues : null}
                                        uiStateName={!!itemProp.salesTypeSelector ? itemProp.salesTypeSelector.uiStateName : null}
                                        endStr={itemProp.endStr} 
                                        subVal={itemProp.subVal}
                                        subValType={itemProp.subValType} 
                                        subName={itemProp.subName}
                                        uiState={uiState}
                                        question={itemProp.question} 
                                    />
                                }
                                {
                                    itemProp.type === "br" &&
                                    <div className={`props-br`}>{ }</div>
                                }
                                {
                                    itemProp.type === "toggleGroupView" &&
                                    <div 
                                        className={`toggle-group-view`} 
                                        onClick={handleGroupActive}
                                    >
                                        <div className="an-ico an-ico-ico-dots" />
                                    </div>
                                }
                                {
                                    itemActivity && itemProp.type === "discount" &&
                                    <DiscountProp 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val}
                                        endStr={itemProp.endStr}
                                        subVal={itemProp.subVal} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "stock-speed" &&
                                    <StockSpeed 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val}
                                        separator={itemProp.separator} 
                                        subVal={itemProp.subVal} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "in-stock" &&
                                    <InStock 
                                        propName={itemProp.name}
                                        propVal={itemProp.val} 
                                        subVal={itemProp.subVal} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "balance" &&
                                    <BalanceProp 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val}
                                        subVal={itemProp.subVal} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "lights" &&
                                    <LightsProp 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val}
                                        subVal={itemProp.subVal} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "sales" &&
                                    <SalesProp 
                                        propName={itemProp.name} 
                                        propVal={itemProp.val}
                                        uiStateValues={itemProp.salesTypeSelector.uiStateValues}
                                        subVal={itemProp.subVal}
                                        uiStateName={itemProp.salesTypeSelector.uiStateName}
                                        uiState={uiState} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "quantity-input" &&
                                    <QuantityInput 
                                        initVal={goodItem[itemProp.val]} 
                                        itemKey={itemKey}
                                        propVal={itemProp.val} 
                                        storeName={storeName}
                                        endStr={itemProp.endStr} 
                                    />
                                }
                                {
                                    itemActivity && itemProp.type === "quantity-calc-input" &&
                                    <QuantityInputCalc 
                                        key={`q-i-${itemProp.val}`} 
                                        setFetchParams={setFetchParams}
                                        initVal={goodItem[itemProp.val]} 
                                        itemKey={itemKey}
                                        propVal={itemProp.val} 
                                        endStr={itemProp.endStr} 
                                    />
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}


export const GoodsCard = React.memo(({ reloadListing, groupHeader = false, isGroupFull, groupUniqKey = null, goodItem, itemKey, cardConfig, cardCheckbox, extra, getSettingActivity, viewType, settings: { storeName }, uiState }) => {
    // const mathRound = (val) => Math.round(parseFloat(val * 100) / 100);

    // const fetchData = async (url, method = 'GET', body = null, headers = {'Access-Control-Allow-Origin': '*'}) => {
    //
    //     try {
    //         const response = await fetch(url, {method, body, headers})
    //         const data = await response.json();
    //         console.log(data)
    //         // const result = data.data;
    //         if (!response.ok) {
    //             throw new Error(data.message || 'что то не так')
    //         }
    //
    //     } catch (e) {
    //         throw e
    //     }
    // };
    // fetchData('https://${process.env.REACT_APP_DOMAIN}/api/new-price/?user_id=1&uniq_key=9735235|2000171373000&retail_price=1489&discount=67&promocode=20');
    const CalcCard = ({ extra, uniqKey }) => {
        const auth = useContext(AuthContext);
        const { activeToken } = useContext(TokensContext);
        let cardConfig = extra.calcCard.cols;

        let [open, setOpen] = useState(false);
        let [loading, setLoading] = useState(true);
        let [reload, setReload] = useState(false);

        let [goodCalcItem, setGoodCalcItem] = useState({});

        const getParams = () => {
            let result = ''
            Object.keys(goodCalcItem).forEach((key) => {
                result += `${key}=${goodCalcItem[key]}&`
            })
            return result
        }

        const setFetchParamsFromChild = (param, value) => {
            setGoodCalcItem({ ...goodCalcItem, [param]: value });
            setReload(true);
        }

        const fetchData = async (url,
            method = 'GET',
            body = null,
            headers = {
                'Access-Control-Allow-Origin': '*',
                Authorization: `${auth.jwt}`
            }
        ) => {
            try {
                const resp = await fetch(url, { method, body, headers })
                let data = await resp.json();
                // const result = data.data;
                if (!resp.ok) {
                    throw new Error(data.message || 'что то не так')
                }
                return data.response
            } catch (e) {
                throw e
            }
        };

        if (reload) {
            setReload(false);
            fetchData(`https://${process.env.REACT_APP_DOMAIN}/api/new-price/?&api_key_id=${activeToken.id}&&uniq_key=${uniqKey}&${getParams()}`)
                .then((data) => {
                    console.log("fetch2");
                    data = {
                        ...goodCalcItem,
                        ...data,
                        "uniq_key": uniqKey
                    }
                    setGoodCalcItem(data);
                })
        }

        return (
            <div className={`goods-calc-item-wrapper ${open ? 'active' : ''}`} key={`item-calc-${itemKey}`}>
                <div className="an-row">
                    <div className={"an-col-24 t-a-center calc-btn"}><div className={"btn btn-gray btn-small"} onClick={() => {
                        if (open) {
                            setOpen(false)
                        } else {
                            fetchData(`https://${process.env.REACT_APP_DOMAIN}/api/new-price/?&api_key_id=${activeToken.id}&&uniq_key=${uniqKey}`)
                                .then((data) => {
                                    console.log("fetch1");
                                    data = {
                                        ...data,
                                        "uniq_key": uniqKey
                                    }
                                    setGoodCalcItem(data);
                                })
                                .then(() => {
                                    setLoading(false);
                                    setOpen(true);
                                })
                        }
                    }}>{extra.calcCard.actionBtn}</div></div>
                    {open && !loading &&
                        <>
                            {Object.keys(cardConfig).map((keyConfig) => {
                                return (
                                    <CardItem cardConfig={cardConfig}
                                        keyConfig={keyConfig}
                                        goodItem={goodCalcItem}
                                        disableActivity={true}
                                        setFetchParams={setFetchParamsFromChild}
                                        reloadListing={reloadListing}
                                        viewType={viewType}
                                        itemKey={itemKey}
                                        getSettingActivity={getSettingActivity}
                                        storeName={storeName}
                                        key={`calc-${keyConfig}-${goodCalcItem.uniq_key}`}
                                    />
                                )
                            })}
                        </>
                    }
                </div>
            </div>
        )
    }
    const [groupActive, setGroupActive] = useState(false);
    const handleGroupActive = () => {
        setGroupActive((prev) => !prev);
    }
    const uniqKeysInGroup = []
    if (groupHeader) {
        goodItem['products'].forEach((product) => {
            uniqKeysInGroup.push(product.uniq_key);
        })
    }
    return (
        <div className={`goods-item ${groupHeader ? 'group-header' : ''} ${groupActive ? 'active' : ''}`} key={`r-${itemKey}`}>
            {!!extra && extra.extraType === "calcCard" &&
                <CalcCard
                    extra={extra}
                    key={`calcCard-${goodItem["uniq_key"]}`}
                    uniqKey={goodItem["uniq_key"]}
                    retail_price={goodItem["current_retail_price"]}
                    discount={goodItem["current_discount"]}
                    promocode={goodItem["current_promo_code"]}
                />
            }
            <div className={`goods-item-wrapper`} key={`extra-item-${itemKey}`}>
                {cardCheckbox &&
                    <CardCheckbox
                        key={`check-${itemKey}`}
                        itemKey={itemKey}
                        storeName={storeName}
                        groupHeader={groupHeader}
                        uniqKeysInGroup={uniqKeysInGroup}
                        groupUniqKey={groupUniqKey}
                        isGroupFull={isGroupFull}
                    />
                }
                <div className="an-row">
                    {
                        Object.keys(cardConfig).map((keyConfig) => 
                            <CardItem
                                key={`ee-${keyConfig}-${itemKey}`}
                                cardConfig={cardConfig}
                                reloadListing={reloadListing}
                                viewType={viewType}
                                itemKey={itemKey}
                                getSettingActivity={getSettingActivity}
                                storeName={storeName}
                                groupHeader={groupHeader}
                                keyConfig={keyConfig}
                                goodItem={goodItem}
                                disableActivity={false}
                                handleGroupActive={handleGroupActive}
                                uiState={uiState}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
})
