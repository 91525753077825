import React, { useCallback, useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { TokensContext } from "../context/TokensContext";
import {TariffContext} from "../context/TariffContext";
import {OnboardingContext} from '../context/OnboardingContext';
import { useHttp } from "../hooks/http.hook";
import * as actions from "../actions";
import { useInterval } from '../hooks/setUnterval.hook';
import { connect } from "react-redux";
import moment from 'moment';
import { Quiz } from './Quiz';

const formatter = (date) => {
    if (!date) {
        return date
    }
    let newDate = moment.utc(date).local();
    if (!newDate) {
        return false
    }
    return newDate.format('DD.MM.YY HH:mm');
}

const mapStateToProps = (state, props) => {
    const uiState = state.uiState;
    return { uiState }
}

const actionCreators = {
    setUiState: actions.setUiState,
}

class Topbar extends React.Component {
    handleUiChange = (filterName) => {
        const { setUiState } = this.props;
        setUiState({ selector: filterName });
    }
    render() {
        const { uiState, setUiState } = this.props;
        return (
            <TopbarFC uiState={uiState} setUiState={this.handleUiChange} />
        )
    }
}

export default connect(mapStateToProps, actionCreators)(Topbar)

const UpdateKeyData = ({ field, dateTime }) => {
    return (
        <div className='update-item'>
            <span className='update-item-title'>
                {field}
            </span>
            <span>
                {formatter(dateTime)}
            </span>
        </div>
    )
}

export const TopbarFC = ({ uiState, setUiState }) => {
    const { jwt, user, userInfo } = useContext(AuthContext);
    const { tokens, activeToken, initTokens, setNewActiveToken } = useContext(TokensContext);
    const { getActiveTariff } = useContext(TariffContext);
    const { account } = useContext(OnboardingContext);
    const history = useHistory();

    useEffect(async () => {
        await getActiveTariff(jwt);
    }, [activeToken]);

    const isActive = uiState.active.filter((key) => key === 'tokenSelector').length > 0;

    const { request, loading } = useHttp();
    const [tokenMenu, setTokenMenu] = useState(false);
    const [quiz, setQuiz] = useState(false);

    useInterval(() => {
        getTokens()
    }, 30 * 60 * 1000)

    const getTokens = useCallback(async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/token/`, 'GET', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            });
            initTokens(data.keys);
        } catch (e) { }
    }, [jwt, request, initTokens]);

    const getUserData = useCallback(async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/profile/`, 'GET', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            });
            user(data);
            if (data && data.show_questionary === true) {
                history.push('/personal/token');
                setQuiz(true);
            }

        } catch (e) { }
    }, [jwt, request, user]);

    useEffect(() => {
        getUserData()
    }, [getUserData])

    useEffect(() => {
        getTokens()
    }, [getTokens]);

    const handleCloseQuiz = () => {
        setQuiz(false);
        localStorage.setItem('showAccOnboarding', 'true');
        history.push('/personal/token/');
    }
    const hasTokens = tokens.length > 0;
    if (loading) {
        return null
    }
    const isWb_stocks = activeToken &&
        activeToken.wb_api_last_updates &&
        activeToken.wb_api_last_updates.wb_stocks;
    const isWb_sales = activeToken &&
        activeToken.wb_api_last_updates &&
        activeToken.wb_api_last_updates.wb_sales;
    const isWb_orders = activeToken &&
        activeToken.wb_api_last_updates &&
        activeToken.wb_api_last_updates.wb_orders
    const isWb_incomes = activeToken &&
        activeToken.wb_api_last_updates &&
        activeToken.wb_api_last_updates.wb_incomes;


    return (
        <div className="nav-header an-container-fluid" style={!hasTokens ? { backgroundColor: 'var(--color-red)' } : null} >
            <div className="lk-company">
                <div className="company-drop-menu">
                    {!hasTokens &&
                        <b data-tut="choose_token"><NavLink to="/personal/token" className="color-white" onClick={() => setUiState('tokenSelector')}>+ Добавить API-ключ</NavLink></b>
                    }
                    {hasTokens &&
                        <>
                            <div 
                                data-tut="choose_token"
                                className="drop-title" 
                                onClick={() => setUiState('tokenSelector')}
                            >
                                {!activeToken &&
                                    <>Токен не выбран</>
                                }
                                {activeToken &&
                                    <>
                                        <img src="/assets/images/wb-logo.svg" alt="" />
                                        <>Токен # {activeToken.id}</>
                                    </>
                                }
                                <i className="an-ico an-ico-drop-arrow">{ }</i>
                            </div>
                            {isActive &&
                                <>
                                    <div className="drop-token-items">
                                        {tokens.map((token) => {
                                            if (!!activeToken && token.id === activeToken.id) {
                                                return null
                                            } else {
                                                return (
                                                    <div key={`token-menu-${token.id}`} className="drop-token-item" onClick={() => {
                                                        setNewActiveToken(token);
                                                        setUiState('tokenSelector');
                                                    }}><img src="/assets/images/wb-logo.svg" alt="" /> Токен #{token.id}</div>
                                                )
                                            }
                                        })}
                                        <NavLink to="/personal/token" className="color-blue" onClick={() => setUiState('tokenSelector')}>+Добавить API-ключ</NavLink>
                                    </div>
                                    <div className="drop-token-items-bg" onClick={() => setUiState('tokenSelector')}>{ }</div>
                                </>
                            }
                        </>
                    }
                </div>
                <div className='update-token-info'>
                    {
                        (
                            isWb_stocks ||
                            isWb_sales ||
                            isWb_orders ||
                            isWb_incomes
                        )
                        && <div className='update-title'>Обновление данных:</div>
                    }

                    {
                        isWb_stocks &&
                        <UpdateKeyData
                            field={'Остатки:'}
                            dateTime={activeToken.wb_api_last_updates.wb_stocks}
                        />
                    }
                    {
                        isWb_sales &&
                        <UpdateKeyData
                            field={'Продажи:'}
                            dateTime={activeToken.wb_api_last_updates.wb_sales}
                        />
                    }
                    {
                        isWb_orders &&
                        <UpdateKeyData
                            field={'Заказы:'}
                            dateTime={activeToken.wb_api_last_updates.wb_orders}
                        />
                    }
                    {
                        isWb_incomes &&
                        <UpdateKeyData
                            field={'Поставки:'}
                            dateTime={activeToken.wb_api_last_updates.wb_incomes}
                        />
                    }
                </div>
            </div>
            <div data-tut="account" className="lk-user">
                {!!userInfo &&
                    <NavLink to="/personal/profile" className="drop-title" style={!hasTokens ? { color: 'white' } : {}}>
                        {userInfo.first_name} {userInfo.last_name}
                        {/*<i className="an-ico an-ico-drop-arrow" />*/}
                    </NavLink>
                }
            </div>
            {quiz &&
                <div className="popup img-popup">
                    <div className="popup-content">
                        <Quiz
                            visible={quiz}
                            setVisible={setQuiz}
                        />
                    </div>
                    <div className="popup-bg" onClick={handleCloseQuiz} />
                </div>
            }
        </div>
    )
}
