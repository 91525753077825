import {useState, useCallback, useEffect} from "react";

export const useTokens = () => {
    const [tokens, setTokens] = useState([]);
    const [activeToken, setActiveToken] = useState(null);
    const [tokensReady, setTokensReady] = useState(false);

    const initTokens = useCallback((newTokens) => {
        const query = new URLSearchParams(window.location.search);
        let apiKeyId = query.get('api_key_id');

        // console.log('initTokens, apiKeyId_fromURL: ' + apiKeyId);
        let result = [];

        newTokens.forEach((item) => {
            if(newTokens.length === 1) {
                setNewActiveToken(item);
            }
            if (item.id == apiKeyId) {
                setActiveToken(item);
            }
            result.push(item);
        })

        setTokens(result);
    }, [])

    const delAllTokens = useCallback(() => {
        setTokens([]);
        setActiveToken(null);
    },[])

    const setNewActiveToken = useCallback((newActiveToken) => {
        const query = new URLSearchParams(window.location.search);
        query.delete('api_key_id');
        window.history.replaceState({}, null, `${window.location.pathname}?api_key_id=${newActiveToken.id}${!!query.toString() ? '&' + query.toString() : ''}`)
        setActiveToken(newActiveToken);
    },[])

    useEffect(() => {
        setTokensReady(true)
    }, [initTokens])

    return {tokens, initTokens, delAllTokens, activeToken, setNewActiveToken, tokensReady, setActiveToken};
}
