import React, {useState, useContext, useEffect} from "react";
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";


export const Profile = () => {
    const {userInfo} = useContext(AuthContext);
    const {loading, request} = useHttp();
    const {jwt} = useContext(AuthContext);

    const [userData, setUserData] = useState({
        ...userInfo
    });

    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
    });

    const [passwordForm, setPasswordForm] = useState(false);
    const [error, setError] = useState({
        passwordNotValid: false,
        newPasswordNotValid: false,
        newPasswordLength: false,
    })

    const handleChangeForm = (event) => {
        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        })
    };
    useEffect(() => {
        if(passwordData.newPassword == passwordData.newPasswordRepeat) {
            setError({...error, newPasswordNotValid: false})
        }
    }, [passwordData]);

    useEffect(() => {
        if(error.passwordNotValid && passwordData.currentPassword.length > 0) {
            setError({...error, passwordNotValid: false})
        }
    }, [passwordData]);

    useEffect(() => {
        if(error.newPasswordLength && passwordData.newPassword.length > 0) {
            setError({...error, newPasswordLength: false})
        }
    }, [passwordData]);

    const handleChangePasswordForm = (event) => {

        setPasswordData({
            ...passwordData,
            [event.target.name]: event.target.value
        })
    };
    const changePasswordFetch = async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/profile/`, 'PUT',
                {curr_password: passwordData.currentPassword, password: passwordData.newPassword},
                {
                    'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
                }
                )
            // console.log('data ====>', data);
            setPasswordForm(false);
        } catch (e) {}
    }
    const changePassword = () => {
        if(passwordData.currentPassword.length === 0) {
            setError({...error, passwordNotValid: true})
        } else if(passwordData.newPassword != passwordData.newPasswordRepeat) {
            setError({...error, newPasswordNotValid: true})
        } else if(passwordData.newPassword.length === 0) {
            setError({...error, newPasswordLength: true})
        } else {
            changePasswordFetch();
        }
    }
    console.log('PROFILE => ', error);
    return (
        <>
            {!loading && userInfo &&
                <>
                    <div className="an-col-16">
                        <form className="round-card bg-color-white">
                            <h3>Персональные данные</h3>
                            <div className="onboarding-form">
                                <div className="input-group">
                                    <div className="input-group-item">
                                        <label htmlFor="login-name">Имя</label>
                                        <input
                                            autoComplete="on"
                                            className="form-input"
                                            type="text"
                                            id="login-name"
                                            placeholder="Имя"
                                            name="first_name"
                                            value={userData.first_name}
                                            onChange={handleChangeForm}
                                        />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-item">
                                        <label htmlFor="login-last_name">Фамилия</label>
                                        <input
                                            autoComplete="on"
                                            className="form-input"
                                            type="text"
                                            id="login-last_name"
                                            placeholder="Фамилия"
                                            name="last_name"
                                            value={userData.last_name}
                                            onChange={handleChangeForm}
                                        />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-item">
                                        <label htmlFor="login-email">Электронная почта</label>
                                        <input
                                            autoComplete="on"
                                            className="form-input"
                                            type="email"
                                            id="login-email"
                                            placeholder="Введите эл. почту"
                                            name="email"
                                            value={userData.email}
                                            onChange={handleChangeForm}
                                        />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-item">
                                        <label htmlFor="login-phone">Номер телефона</label>
                                        <input
                                            autoComplete="on"
                                            className="form-input"
                                            type="text"
                                            id="login-phone"
                                            placeholder="Введите номер телефона"
                                            name="phone"
                                            value={userData.phone}
                                            onChange={handleChangeForm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="an-col-24">
                        <div className="round-card bg-color-white">
                            <h3>Пароль</h3>
                            {/*<div>Безопасность текущего пароля: <span className="color-red">пора сменить пароль</span></div>*/}
                            <div className="btn btn-line-gray m-t-25" onClick={() => setPasswordForm(true)}>Изменить пароль</div>
                            {passwordForm &&
                            <div className="popup">
                                <div className="popup-bg" onClick={() => setPasswordForm(false)}>{}</div>
                                <div className="popup-content">
                                    <h3>Изменить пароль</h3>
                                    <form className="onboarding-form" onSubmit={(e) => e.preventDefault()}>
                                        <label htmlFor="login-password-1">
                                            {!error.passwordNotValid &&
                                            <span>Введите текущий пароль</span>
                                            }
                                            {error.passwordNotValid &&
                                            <span className='color-red'>Пароль не может быть пустым</span>
                                            }
                                        </label>
                                        <input onChange={handleChangePasswordForm}
                                               autoComplete="on"
                                               className={`form-input input-full-width ${error.passwordNotValid ? 'error' : ''}`}
                                               type="password" id="login-password-1" placeholder="Введите пароль"
                                               value={passwordData.currentPassword} name={'currentPassword'}/>
                                        <label htmlFor="login-password">
                                            {!error.newPasswordNotValid && !error.newPasswordLength &&
                                            <span>Введите новый пароль</span>
                                            }
                                            {error.newPasswordNotValid && !error.newPasswordLength &&
                                            <span className='color-red'>Пароли не совпадают</span>
                                            }
                                            {error.newPasswordLength &&
                                            <span className='color-red'>Новый пароль не может быть пустым</span>
                                            }
                                        </label>
                                        <input onChange={handleChangePasswordForm}
                                               autoComplete="on"
                                               className={`form-input input-full-width ${error.newPasswordNotValid || error.newPasswordLength ? 'error' : ''}`}
                                               type="password" id="login-password" placeholder="Введите пароль"
                                               value={passwordData.newPassword} name={'newPassword'}/>
                                        <label htmlFor="login-password-1">
                                            {!error.newPasswordNotValid &&
                                            <span>Введите новый пароль (повторно)</span>
                                            }
                                            {error.newPasswordNotValid &&
                                            <span className='color-red'>Пароли не совпадают</span>
                                            }
                                        </label>
                                        <input onChange={handleChangePasswordForm}
                                               autoComplete="on"
                                               className={`form-input input-full-width ${error.newPasswordNotValid ? 'error' : ''}`}
                                               type="password" id="login-password" placeholder="Введите пароль"
                                               value={passwordData.newPasswordRepeat} name={'newPasswordRepeat'}/>
                                        <div className="btn-block">
                                            <div className="btn btn-blue btn-full-width" onClick={changePassword}>Изменить пароль</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {/*<div className="an-col-8">*/}
                    {/*    <div className="round-card bg-color-blue color-white flex-col h-100">*/}
                    {/*        <h3>Реферальный код</h3>*/}
                    {/*        <div className="ref-code">W4B67EEL</div>*/}
                    {/*        <small className="color-white m-t-auto">Скидка 500 ₽ на первую покупку тарифа другу и 500 баллов на ваш бонусный счет</small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>
            }

        </>
    )
}
