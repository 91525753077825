import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from '../actions/index.js';

const cardConfig = {
    DeliveryForecast: {
        "checkbox": true,
        "cols": {
            "col-1": {
                "name": "Товар",
                "class": "an-col-6 goods-img-block",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "headerClass": "an-col-10 goods-img-block",
                "img": "cover", // false
                "CardItems": [
                    {
                        "name": "Название",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "item_name",
                        "type": "simple",
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "артикул",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "article",
                        "type": "simple"
                    },
                    {
                        "name": "штрихкод",
                        "displayMenu": true,
                        "shortView": true,
                        "val": "barcode",
                        "type": "simple"
                    },
                    {
                        "name": "id WB",
                        "displayMenu": true,
                        "shortView": true,
                        "val": "nomenclature",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "размер",
                        "displayMenu": true,
                        "val": "size", // ????
                        "type": "simple"
                    },
                    {
                        "name": "бренд",
                        "displayMenu": true,
                        "val": "brand",
                        "type": "simple"
                    },
                    {
                        "name": "категория", // Тип товара
                        "displayMenu": true,
                        "val": "subject", //???
                        "subVal": "categories",
                        "type": "cat-card" //?????
                    },
                ],
                "HeaderItems": [
                    {
                        "name": "Название группы",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "article",
                        "type": "simple",
                    },
                    {
                        "name": "id WB",
                        "shortView": true,
                        "val": "nomenclature",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "",
                        "type": "br"
                    },
                    // {
                    //     "name": "артикул",
                    //     "displayMenu": true,
                    //     "simpleView": true,
                    //     "val": "article",
                    //     "type": "simple"
                    // },
                    // {
                    //     "name": "штрихкод",
                    //     "displayMenu": true,
                    //     "val": "barcode",
                    //     "type": "simple"
                    // },
                    // {
                    //     "name": "id WB",
                    //     "displayMenu": true,
                    //     "val": "nomenclature",
                    //     "type": "simple"
                    // },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    // {
                    //     "name": "размер",
                    //     "displayMenu": true,
                    //     "val": "size", // ????
                    //     "type": "simple"
                    // },
                    {
                        "name": "бренд",
                        "displayMenu": true,
                        "val": "brand",
                        "type": "simple"
                    },
                    {
                        "name": "категория", // Тип товара
                        "displayMenu": true,
                        "val": "subject", //???
                        "subVal": "categories",
                        "type": "cat-card" //?????
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Товар',
                    "param": "sort",
                    "val": "subject",
                    "sortingAction": {
                        "name": "Выделить все",
                        "subName": "Снять выделение",
                        "actionType": "checkAll"
                    }
                }
            },
            "col-2": {
                "name": "Стоимость",
                "class": "an-col-5",
                "headerClass": "d-none",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "CardItems": [
                    {
                        "name": "Цена продавца",
                        "displayMenu": false,
                        "simpleView": true,
                        "shortView": true,
                        "val": "price_client",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "цена до скидок",
                        "displayMenu": true,
                        "val": "retail_price",
                        "endStr": '',
                        "type": "simple"
                    },
                    {
                        "name": "скидка",
                        "displayMenu": true,
                        "val": "discount",
                        "subVal": "discount_value",
                        "endStr": "",
                        "type": "discount", // новый тип
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "СПП",
                        "displayMenu": true,
                        "val": "spp",
                        "subVal": "client_price_minus_spp",
                        // "endStr": '%',
                        "endStr": "",
                        "type": "discount"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "оборачиваемость",
                        "displayMenu": true,
                        "val": "article_turnover",
                        "endStr": 'д.',
                        "type": "simple"
                    }
                ],
                "HeaderItems": [
                    // {
                    //     "name": "",
                    //     "displayMenu": false,
                    //     "simpleView": true,
                    //     "val": "price_client",
                    //     "endStr": 'rub',
                    //     "type": "simple"
                    // },
                    // {
                    //     "name": "",
                    //     "displayMenu": false,
                    //     "val": "",
                    //     "type": "br"
                    // },
                    // {
                    //     "name": "розничная",
                    //     "displayMenu": true,
                    //     "val": "retail_price",
                    //     "endStr": 'rub',
                    //     "type": "simple"
                    // },
                    // {
                    //     "name": "скидка",
                    //     "displayMenu": true,
                    //     "val": "discount",
                    //     "subVal": "discount_value",
                    //     "type": "discount", // новый тип
                    // },
                    // {
                    //     "name": "промокод",
                    //     "displayMenu": true,
                    //     "val": "promocode",
                    //     "subVal": "discount_promocode",
                    //     "type": "discount" // новый тип
                    // },
                    // {
                    //     "name": "",
                    //     "displayMenu": false,
                    //     "val": "",
                    //     "type": "br"
                    // },
                    // {
                    //     "name": "оборачиваемость",
                    //     "displayMenu": true,
                    //     "val": "article_turnover",
                    //     "endStr": 'д.',
                    //     "type": "simple"
                    // }
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Стоимость',
                    "param": "sort",
                    "val": "price",
                    "question": "Последняя цена продажи",
                    "sortingAction": {
                        // "name": "показать все",
                        // "actionType": "rangeCost",
                    }
                }
            },
            "col-3": {
                "name": "Остатки",
                "class": "an-col-5",
                "headerClass": "an-col-6",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "shortView": true,
                        "val": "balance", //?? rest_items - 92шт  delivery_to_user - к клиенту 2 шт.  delivery_from_user - от клиента 150 шт.
                        "type": "balance",
                        "subVal": [
                            "rest_items",
                            "rest_items_days",
                            "delivery_to_user",
                            "delivery_from_user",
                        ]
                    },
                    {
                        "name": "свои остатки",
                        "displayMenu": false,
                        "val": "stock_storage",
                        "type": "simple"
                    },
                    {
                        "name": "заказ в пути",
                        "displayMenu": false,
                        "val": "way_wb",
                        "type": "simple"
                    },
                ],
                "HeaderItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        'shortView': true,
                        "val": "rest_items", //?? rest_items - 92шт  delivery_to_user - к клиенту 2 шт.  delivery_from_user - от клиента 150 шт.
                        "type": "lights",
                        "subVal": [
                            "products_in_stock",
                            "products_running_out_of_stock",
                            "products_out_of_stock",
                        ]
                    },
                    {
                        "name": "свои остатки",
                        "displayMenu": false,
                        "val": "stock_storage",
                        "type": "simple"
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Остаток',
                    "param": "sort",
                    "val": "byStore",
                    "sortingAction": {
                        "name": "На всех складах",
                        "actionType": "storeSelector"

                    }
                }
            },
            "col-4": {
                "name": "Скорость заказов",
                "class": "an-col-4",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "orders_per_day",
                        "subVal": false,
                        "simpleView": true,
                        "separator": ' шт. в ',
                        "type": "stock-speed"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "shortView": true,
                        "val": "orders_quantity",
                        "subVal": "in_stock_days",
                        "simpleView": true,
                        "separator": ' шт. за ',
                        "type": "stock-speed"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "был в наличии",
                        "displayMenu": true,
                        "val": "in_stock_days", //??
                        "type": "in-stock",
                        "subVal": "selected_interval_days",
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "упущенная прибыль",
                        "displayMenu": true,
                        "val": "lost_profit", //??
                        "endStr": 'rub',
                        "type": "simple",
                        "withColor": true
                    }
                ],
                "HeaderItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "orders_per_day",
                        "subVal": false,
                        "simpleView": true,
                        "separator": ' шт. за ',
                        "type": "stock-speed"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "orders_quantity",
                        "subVal": "in_stock_days",
                        "simpleView": true,
                        "separator": ' шт. за ',
                        "type": "stock-speed"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    // {
                    //     "name": "был в наличии",
                    //     "displayMenu": true,
                    //     "val": "in_stock_days", //??
                    //     "type": "in-stock",
                    //     "subVal": "selected_interval_days",
                    // },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "упущенная прибыль",
                        "displayMenu": true,
                        "val": "lost_profit", //??
                        "endStr": 'rub',
                        "type": "simple",
                        "withColor": true
                    }
                ],
                "sortingItem": {
                    "type": "multi",
                    "name": 'Скорость заказов',
                    "param": "sort",
                    "val": {
                        'bySpeed': 'Скорость заказов',
                        'byLostProfit': 'По упущенной прибыли',
                    },
                    "sortingAction": {
                        // "name": "На всех складах",
                        // "actionType": "storeSelector"
                    }
                },
                // "sortingItem": {
                //     "type": "simple",
                //     "name": 'Скорость заказов',
                //     "param": "sort",
                //     "val": "bySpeed",
                //     "sortingAction": {
                //         "name": "за 30 дней",
                //         "actionType": "text"
                //
                //     }
                // }
            },
            "col-5": {
                "name": "Прогноз поставки",
                "class": "an-col-4",
                "goodsDescriptionHeaderClass": "block-all-center",
                "CardItems": [
                    {
                        "name": "",
                        "val": "ship_storage",
                        "displayMenu": false,
                        "simpleView": true,
                        "shortView": true,
                        "endStr": 'шт.',
                        "type": "quantity-input"
                    },
                    // {
                    //     "name": "",
                    //     "displayMenu": false,
                    //     "val": "box_qty",
                    //     "type": "simple"
                    // },
                    // {
                    //     "name": "",
                    //     "displayMenu": false,
                    //     "val": "pallet_qty",
                    //     "type": "simple"
                    // }
                ],
                "HeaderItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "shortView": true,
                        "val": "toggleGroupView",
                        "type": "toggleGroupView"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "Всего",
                        "displayMenu": true,
                        "val": "ship_storage",
                        "endStr": 'шт.',
                        "type": "simple",
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Прогноз поставки',
                    "param": "sort",
                    "val": "byForecast",
                    "sortingAction": {
                        "name": "на 30 дней",
                        "actionType": "predict-days",
                        "defaultVal": 14,
                        "actionVal": [
                            14,
                            30,
                            'customRange'
                        ]
                    }
                }
            }
        },
        "filterDateStart": {
            days: 14,
            dateSearchParam: ''
        },
        "filter-bar-config": {
            "filter": [
                "date-simple",
                "filter",
            ],
            "view-items": [
                "active",
                "count",
                "view-toggle",
                "view-settings"
            ]
        },
        "defaultSortParam": "-byForecast",
        "transfer-files-config": {
            "download": {
                'general': {
                    name: 'Товары',
                    description: 'Данные в формате Excel'
                },
                'price_management': {
                    name: 'Цены',
                    description: 'Данные в формате Excel'
                },
                'wb_deliveries': {
                    name: 'Заказ Wildberries',
                    description: 'Данные в формате Excel'
                }
            },
            "upload": {
                'on_wb_way': {
                    name: 'Заказ на пути в WB',
                    filePath: 'on_wb_way',
                },
                'seller_stocks': {
                    name: 'Остатки на своем складе',
                    filePath: 'seller_stocks',
                },
                'cost_price': {
                    name: 'Себестоимость',
                    filePath: 'cost_price',
                }
            }
        }
    },
    DeliveryAnalytic: {
        "cols": {
            "col-1": {
                "name": "Товар",
                "class": "an-col-6 goods-img-block",
                "img": "cover", // false
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "item_name",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "артикул",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "article",
                        "type": "simple"
                    },
                    {
                        "name": "штрихкод",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "barcode",
                        "type": "simple"
                    },
                    {
                        "name": "id WB",
                        "displayMenu": true,
                        "val": "nomenclature",
                        "type": "simple"
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Товар',
                    "param": "sort",
                    "val": "subject",
                    "sortingAction": {
                        // "name": "Выделить все",
                        // "subName": "Снять выделение",
                        // "actionType": "checkAll"
                    }
                }
            },
            "col-2": {
                "name": "Стоимость",
                "class": "an-col-4",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "total_spending",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "Логистика",
                        "displayMenu": true,
                        "val": "logistics_price", // ?
                        "endStr": 'rub',
                        "type": "simple",
                    },
                    // {
                    //     "name": "Свои расходы",
                    //     "displayMenu": true,
                    //     "val": "discount",
                    //     "type": "simple", // новый тип
                    // },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Стоимость',
                    "param": "sort",
                    "val": "price",
                    "sortingAction": {
                        // "name": "показать все",
                        // "actionType": "rangeCost",
                    }
                }
            },
            "col-3": {
                "name": "Данные по продажам",
                "class": "an-col-8",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "orders_count",
                        "type": "sales",
                        "salesTypeSelector": {
                            "uiStateName": "orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_by_sales"
                                },
                            }
                        },
                        "subVal": [
                            "sales_count",
                            "sales_percent",
                            "canceled_count",
                            "canceled_percent",
                            "refunds_count",
                            "refunds_percent",
                            "in_way_count",
                            "in_way_percent"
                        ]
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Данные по продажам',
                    "param": "sort",
                    "val": "byStore",
                    "sortingAction": {
                    "name": "По заказам",
                    "actionType": "salesTypeSelector"
                    }
                }
            },
            "col-4": {
                "name": "Затраты",
                "class": "an-col-2 t-a-right",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "total_spending",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "sales_spending",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "canceled_spending",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "refunds_spending",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "in_way_spending",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Затраты',
                    "param": "sort",
                    "val": "bySpeed",
                    "sortingAction": {
                        "name": "за 30 дней",
                        "actionType": "text"

                    }
                }
            },
            "col-5": {
                "name": "Прогноз",
                "class": "an-col-4 t-a-right",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "predict_logistics_price",
                        "endStr": 'rub',
                        "type": "simple"
                    }
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Прогноз',
                    "param": "sort",
                    "val": "byForecast",
                    "sortingAction": {
                        "name": "на 30 дней",
                        "actionType": "predict-days",
                        "defaultVal": 30,
                        "actionVal": [
                            30,
                            60,
                            'customRange'
                        ]
                    }
                }
            }
        },
        "filterDateStart": {
            days: 14,
            dateSearchParam: ''
        },
        "filter-bar-config": {
            "filter": [
                "date-simple",
                "filter",
            ],
            "view-items": [
                "active",
                "count",
                "view-toggle",
                "view-settings"
            ]
        },
        "transfer-files-config": {
            "download": {},
            "upload": {}
        }
    },
    ReportPrice: {
        "checkbox": true,
        "cols": {
            "col-1": {
                "name": "Товар",
                "class": "an-col-6 goods-img-block",
                "img": "cover", // false
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "item_name",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "артикул",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "article",
                        "type": "simple"
                    },
                    {
                        "name": "штрихкод",
                        "displayMenu": true,
                        "val": "barcode",
                        "type": "simple"
                    },
                    {
                        "name": "id WB",
                        "displayMenu": true,
                        "val": "nomenclature",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "размер",
                        "displayMenu": true,
                        "val": "size", // ????
                        "type": "simple"
                    },
                    {
                        "name": "бренд",
                        "displayMenu": true,
                        "val": "brand",
                        "type": "simple"
                    },
                    {
                        "name": "категория", // Тип товара
                        "displayMenu": true,
                        "val": "subject", //???
                        "subVal": "categories",
                        "type": "cat-card" //?????
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Товар',
                    "param": "sort",
                    "val": "subject",
                    "sortingAction": {
                        "name": "Выделить все",
                        "subName": "Снять выделение",
                        "actionType": "checkAll"
                    }
                }
            },
            "col-2": {
                "name": "Стоимость",
                "class": "an-col-5",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "current_price",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "розничная",
                        "displayMenu": true,
                        "val": "current_retail_price",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                    {
                        "name": "себестоимость",
                        "displayMenu": true,
                        "val": "cost_price",
                        "endStr": 'rub',
                        "type": "simpleEdit"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "cкидка",
                        "displayMenu": true,
                        "val": "current_discount",
                        "endStr": '%',
                        "type": "simple"
                    },
                    {
                        "name": "промокод",
                        "displayMenu": true,
                        "val": "current_promo_code",
                        "endStr": '%',
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "СПП",
                        "displayMenu": true,
                        "val": "current_spp",
                        "subVal": "last_spp",
                        "subValType": "percent",
                        "endStr": '%',
                        "type": "with-interest"
                    },
                    {
                        "name": "Цена покупателя",
                        "displayMenu": true,
                        "val": "current_price_minus_spp",
                        "subVal": "last_price_minus_spp",
                        "subValType": "percent",
                        "endStr": 'rub',
                        "type": "with-interest"
                    },
                ],
                "sortingItem": {
                    "type": "multi",
                    "name": 'Средняя стоимость',
                    "param": "sort",
                    "val": {
                        'byPrice': 'По цене',
                        'byRetailPrice': 'По розничной цене',
                        'byDiscount': 'По скидке',
                        'byPromo': 'По промокоду',
                    },
                    "sortingAction": {
                        "name": "На всех складах",
                        "actionType": "storeSelector"
                    }
                }
            },
            "col-3": {
                "name": "Фин. показатели",
                "class": "an-col-5",
                "CardItems": [
                    {
                        "name": "чистая прибыль",
                        "displayMenu": false,
                        "val": "current_gross_profit",
                        "subVal": "gross_profit_diff",
                        "subValType": "percent",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "question": "Чистая прибыль = продажи в день - комиссия ВБ в день - стоимость логистики в день - стоимость хранения в день - себестоимость проданного товара в день.",
                        "salesTypeSelector": {
                            "uiStateName": "price_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "маржинальность",
                        "displayMenu": false,
                        "val": "current_marginality",
                        "subVal": "last_curr_marginality_diff",
                        "subValType": "percent",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "question": "Маржинальность = (цена для покупателя - комиссия ВБ (подгруженные данные по группе товаров)-налоги (AM в %) - себестоимость (M).",
                        "salesTypeSelector": {
                            "uiStateName": "price_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "рентабельность",
                        "displayMenu": false,
                        "val": "current_profitability",
                        "subVal": "last_curr_profitability_diff",
                        "subValType": "percent",
                        "endStr": '%',
                        "type": "with-interest",
                        "question": "Рентабельность = Маржинальность деленная на себестоимость и умноженная на 100%. Формула: r=(n/m)*100%",
                        "salesTypeSelector": {
                            "uiStateName": "price_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "оборот",
                        "displayMenu": false,
                        "val": "current_turnover",
                        "subVal": "turnover_diff",
                        "subValType": "percent",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "question": "Оборот в день = продажи в день - комиссия ВБ в день - стоимость логистики в день - стоимость хранения в день. Если не проставлена себестоимость, то считаем ее по умолчанию 0 руб",
                        "salesTypeSelector": {
                            "uiStateName": "price_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    }
                ],
                "sortingItem": {
                    "type": "multi",
                    "name": 'Фин. показатели',
                    "param": "sort",
                    "val": {
                        'byProfit': 'По прибыли',
                        'byMarginality': 'По маржинальности',
                        'byTurnover': 'По обороту',
                        'byProfitability': 'По рентабельности',
                    },
                    "sortingAction": {
                        "name": "По заказам",
                        "actionType": "salesTypeSelector"
                    }
                }
            },
            "col-4": {
                "name": "Заказы и продажи",
                "class": "an-col-4",
                "CardItems": [
                    {
                        "name": "заказы",
                        "displayMenu": false,
                        "val": "curr_orders_quantity",
                        "subVal": "orders_quantity_diff",
                        "subValType": "percent",
                        "endStr": 'шт.',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "продажи",
                        "displayMenu": false,
                        "val": "curr_sales_quantity",
                        "subVal": "sales_quantity_diff",
                        "subValType": "percent",
                        "endStr": 'шт.',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "скорость заказов",
                        "displayMenu": false,
                        "val": "current_orders_quantity_per_day",
                        "subVal": "",
                        "subValType": "percent",
                        "endStr": 'шт./д.',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "скорость продаж",
                        "displayMenu": false,
                        "val": "current_sales_quantity_per_day",
                        "subVal": "",
                        "subValType": "percent",
                        "endStr": 'шт./д.',
                        "type": "with-interest"
                    }
                ],
                "sortingItem": {
                    "type": "multi",
                    "name": 'Заказы и продажи',
                    "param": "sort",
                    "val": {
                        'byOrdersPerDay': 'По заказам в день',
                        'byOrders': 'По заказам',
                        'bySalesPerDay': 'По продажам за день',
                        'bySales': 'По продажам',
                    },
                    "sortingAction": {
                        "name": "На всех складах",
                        "actionType": "storeSelector"
                    }
                }
            },
            "col-5": {
                "name": "Расходы",
                "class": "an-col-4",
                "CardItems": [
                    {
                        "name": "комиссия",
                        "displayMenu": false,
                        "val": "curr_commission_wd_rub",
                        "subVal": "",
                        "subValType": "percent",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "salesTypeSelector": {
                            "uiStateName": "price_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "логистика",
                        "displayMenu": false,
                        "val": "curr_logistics_cost",
                        "subVal": "",
                        "subValType": "percent",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "salesTypeSelector": {
                            "uiStateName": "price_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "налог",
                        "subName": "taxes",
                        "displayMenu": false,
                        "val": "curr_taxes_rub",
                        "subVal": "",
                        "subValType": "percent",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "salesTypeSelector": {
                            "uiStateName": "price_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                ],
                "sortingItem": {
                    "type": "multi",
                    "name": 'Расходы',
                    "param": "sort",
                    "val": {
                        'byCommission': 'По комиссии',
                        'byLogisticsCost': 'По логистике',
                        'byTax': 'По налогу',
                    },
                    "sortingAction": {
                        "name": "На всех складах",
                        "actionType": "storeSelector"
                    }
                }
            }
        },
        "filterDateStart": {
            days: 14,
            dateSearchParam: 'first_interval_'
        },
        "filterDateStartSecond": {
            days: 14,
            dateSearchParam: 'second_interval_'
        },
        "filter-bar-config": {
            "filter": [
                "date-simple-first",
                "date-simple-second",
                "date-space",
                "filter",
            ],
            "view-items": [
                "active",
                "count",
                "view-toggle",
                "view-settings"
            ]
        },
        "defaultSortParam": "-byProfit",
        "transfer-files-config": {
            "download": {
                'general': {
                    name: 'Товары',
                    description: 'Данные в формате Excel'
                },
                'price_management': {
                    name: 'Цены',
                    description: 'Данные в формате Excel'
                },
            },
            "upload": {
                'cost_price': {
                    name: 'Себестоимость',
                    filePath: 'cost_price',
                }
            }
        }

    },
    LogisticAnalytic: {
        "checkbox": true,
        "cols": {
            "col-1": {
                "name": "Товар",
                "class": "an-col-6 goods-img-block",
                "img": "cover", // false
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "item_name",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "артикул",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "article",
                        "type": "simple"
                    },
                    {
                        "name": "штрихкод",
                        "displayMenu": true,
                        "val": "barcode",
                        "type": "simple"
                    },
                    {
                        "name": "id WB",
                        "displayMenu": true,
                        "val": "nomenclature",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "размер",
                        "displayMenu": true,
                        "val": "size", // ????
                        "type": "simple"
                    },
                    {
                        "name": "бренд",
                        "displayMenu": true,
                        "val": "brand",
                        "type": "simple"
                    },
                    {
                        "name": "категория", // Тип товара
                        "displayMenu": true,
                        "val": "subject", //???
                        "subVal": "categories",
                        "type": "cat-card" //?????
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Товар',
                    "param": "sort",
                    "val": "subject",
                    "sortingAction": {
                        "name": "Выделить все",
                        "subName": "Снять выделение",
                        "actionType": "checkAll"
                    }
                }
            },
            "col-2": {
                "name": "Стоимость",
                "class": "an-col-5",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "subVal": "current_price",
                        "val": "last_price",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "розничная",
                        "displayMenu": true,
                        "subVal": "current_retail_price",
                        "val": "last_retail_price",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest"
                    },
                    {
                        "name": "cкидка",
                        "displayMenu": true,
                        "subVal": "current_discount",
                        "val": "last_discount",
                        "subValType": "compare",
                        "endStr": '%',
                        "type": "with-interest"
                    },
                    {
                        "name": "промокод",
                        "displayMenu": true,
                        "subVal": "current_promo_code",
                        "val": "last_promo_code",
                        "subValType": "compare",
                        "endStr": '%',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "СПП",
                        "displayMenu": true,
                        "val": "current_spp",
                        "subVal": "last_spp",
                        "subValType": "compare",
                        "endStr": '%',
                        "type": "with-interest"
                    },
                    {
                        "name": "Цена покупателя",
                        "displayMenu": true,
                        "val": "current_price_minus_spp",
                        "subVal": "last_price_minus_spp",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "себестоимость",
                        "displayMenu": true,
                        "val": "cost_price",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Стоимость',
                    "param": "sort",
                    "val": "price",
                    "sortingAction": {
                        "name": "показать все",
                        "actionType": "rangeCost",
                    }
                }
            },
            "col-3": {
                "name": "Фин. показатели",
                "class": "an-col-5",
                "CardItems": [
                    {
                        "name": "чистая прибыль",
                        "displayMenu": false,
                        "subVal": "current_gross_profit",
                        "val": "last_gross_profit",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "question": "Чистая прибыль = продажи в день - комиссия ВБ в день - стоимость логистики в день - стоимость хранения в день - себестоимость проданного товара в день.",
                        "salesTypeSelector": {
                            "uiStateName": "logistic_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "маржинальность",
                        "displayMenu": false,
                        "subVal": "current_marginality",
                        "val": "last_marginality",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "question": "Маржинальность = (цена для покупателя - комиссия ВБ (подгруженные данные по группе товаров)-налоги (AM в %) - себестоимость (M).",
                        "salesTypeSelector": {
                            "uiStateName": "logistic_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "рентабельность",
                        "displayMenu": false,
                        "subVal": "current_profitability",
                        "val": "last_profitability",
                        "subValType": "compare",
                        "endStr": '%',
                        "type": "with-interest",
                        "question": "Рентабельность = Маржинальность деленная на себестоимость и умноженная на 100%. Формула: r=(n/m)*100%",
                        "salesTypeSelector": {
                            "uiStateName": "logistic_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "оборот",
                        "displayMenu": false,
                        "subVal": "current_turnover",
                        "val": "last_turnover",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "question": "Оборот в день = продажи в день - комиссия ВБ в день - стоимость логистики в день - стоимость хранения в день. Если не проставлена себестоимость, то считаем ее по умолчанию 0 руб",
                        "salesTypeSelector": {
                            "uiStateName": "logistic_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    }
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Фин. показатели',
                    "param": "sort",
                    "val": "byStore",
                    "sortingAction": {
                        "name": "По заказам",
                        "actionType": "salesTypeSelector"
                    }
                }
            },
            "col-4": {
                "name": "Заказы и продажи",
                "class": "an-col-4",
                "CardItems": [
                    {
                        "name": "заказы",
                        "displayMenu": false,
                        "subVal": "curr_orders_quantity",
                        "val": "last_orders_quantity",
                        "subValType": "compare",
                        "endStr": 'шт.',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "продажи",
                        "displayMenu": false,
                        "subVal": "curr_sales_quantity",
                        "val": "last_sales_quantity",
                        "subValType": "compare",
                        "endStr": 'шт.',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "скорость заказов",
                        "displayMenu": false,
                        "subVal": "current_orders_quantity_per_day",
                        "val": "last_orders_quantity_per_day",
                        "subValType": "compare",
                        "endStr": 'шт./д.',
                        "type": "with-interest"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "скорость продаж",
                        "displayMenu": false,
                        "subVal": "current_sales_quantity_per_day",
                        "val": "last_sales_quantity_per_day",
                        "subValType": "compare",
                        "endStr": 'шт./д.',
                        "type": "with-interest"
                    }
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Заказы и продажи',
                    "param": "sort",
                    "val": "byStore",
                    "sortingAction": {
                        "name": "На всех складах",
                        "actionType": "storeSelector"

                    }
                }
            },
            "col-5": {
                "name": "Расходы",
                "class": "an-col-4",
                "CardItems": [
                    {
                        "name": "комиссия",
                        "displayMenu": false,
                        "subVal": "curr_commission_wb_rub",
                        "val": "last_commission_wb_rub",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "salesTypeSelector": {
                            "uiStateName": "logistic_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "логистика",
                        "displayMenu": false,
                        "subVal": "curr_logistics_cost",
                        "val": "last_logistics_cost",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "salesTypeSelector": {
                            "uiStateName": "logistic_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "налог",
                        "subName": "taxes",
                        "displayMenu": false,
                        "subVal": "curr_taxes_rub",
                        "val": "last_taxes_rub",
                        "subValType": "compare",
                        "endStr": 'rub',
                        "type": "with-interest",
                        "salesTypeSelector": {
                            "uiStateName": "logistic_orders_count",
                            "uiStateValues": {
                                "byOrders": {
                                    "name": "По заказам",
                                    "postfix": ""
                                },
                                "all": {
                                    "name": "Всего",
                                    "postfix": "_per_period"
                                },
                            }
                        },
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Расходы',
                    "param": "sort",
                    "val": "byForecast",
                    "sortingAction": {
                        "name": "показать все",
                        "actionType": ""

                    }
                }
            }
        },
        "filterDateStart": {
            days: 14,
            dateSearchParam: 'first_interval_'
        },
        "filterDateStartSecond": {
            days: 14,
            dateSearchParam: 'second_interval_'
        },
        "filter-bar-config": {
            "filter": [
                "date-simple-first",
                "date-simple-second",
                "date-space",
                "filter",
            ],
            "view-items": [
                "active",
                "count",
                "view-settings"
            ]
        },
        "extra": {
            "extraType": "calcCard",
            "calcCard": {
                "actionBtn": "Прогноз цены",
                "cols": {
                    "col-1": {
                        "class": "an-col-6",
                        "CardItems": [
                            {
                                "name": "cost",
                                "val": "new_retail_price",
                                "endStr": ['rub', 'цена'],
                                "type": "quantity-calc-input"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "percent",
                                "val": "new_discount",
                                "endStr": ['%', 'скидка'],
                                "type": "quantity-calc-input"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "promo",
                                "val": "new_promo_code",
                                "endStr": ['%', 'промкод'],
                                "type": "quantity-calc-input"
                            },
                        ],
                    },
                    "col-2": {
                        "class": "an-col-5",
                        "CardItems": [
                            {
                                "name": "",
                                "val": "new_price",
                                "endStr": 'rub',
                                "type": "simple"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "розничная",
                                "val": "new_retail_price",
                                "endStr": 'rub',
                                "type": "simple"
                            },
                            {
                                "name": "cкидка",
                                "val": "new_discount",
                                "endStr": '%',
                                "type": "simple"
                            },
                            {
                                "name": "промокод",
                                "val": "new_promo_code",
                                "endStr": '%',
                                "type": "simple"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "себестоимость",
                                "val": "new_cost_price",
                                "endStr": 'rub',
                                "type": "simple"
                            },
                        ],
                    },
                    "col-3": {
                        "class": "an-col-5",
                        "CardItems": [
                            {
                                "name": "чистая прибыль",
                                "val": "new_gross_profit",
                                "subVal": "new_curr_gross_profit_diff",
                                "subValType": "percent",
                                "endStr": 'rub',
                                "type": "with-interest",
                                "question": "Чистая прибыль = продажи в день - комиссия ВБ в день - стоимость логистики в день - стоимость хранения в день - себестоимость проданного товара в день."
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "маржинальность",
                                "val": "new_marginality",
                                "subVal": "new_curr_marginality_diff",
                                "subValType": "percent",
                                "endStr": 'rub',
                                "type": "with-interest",
                                "question": "Маржинальность = (цена для покупателя - комиссия ВБ (подгруженные данные по группе товаров)-налоги (AM в %) - себестоимость (M).",
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "рентабельность",
                                "val": "new_profitability",
                                "subVal": "new_curr_profitability_diff",
                                "subValType": "percent",
                                "endStr": '%',
                                "type": "with-interest",
                                "question": "Рентабельность = Маржинальность деленная на себестоимость и умноженная на 100%. Формула: r=(n/m)*100%"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "оборот",
                                "val": "new_turnover",
                                "subVal": "new_curr_turnover_diff",
                                "subValType": "percent",
                                "endStr": 'rub',
                                "type": "with-interest",
                                "question": "Оборот в день = продажи в день - комиссия ВБ в день - стоимость логистики в день - стоимость хранения в день. Если не проставлена себестоимость, то считаем ее по умолчанию 0 руб"
                            }
                        ],
                    },
                    "col-4": {
                        "class": "an-col-4",
                        "CardItems": [
                            {
                                "name": "заказы",
                                "val": "new_orders_quantity",
                                "subVal": "new_curr_orders_diff",
                                "subValType": "percent",
                                "endStr": 'шт.',
                                "type": "with-interest"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "продажи",
                                "val": "new_sales_quantity",
                                "subVal": "new_curr_sales_diff",
                                "subValType": "percent",
                                "endStr": 'шт.',
                                "type": "with-interest"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "скорость заказов",
                                "val": "new_orders_per_day",
                                "subVal": "new_curr_orders_per_day_diff",
                                "subValType": "percent",
                                "endStr": 'шт./д.',
                                "type": "with-interest"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "скорость продаж",
                                "val": "new_sales_per_day",
                                "subVal": "new_curr_sales_per_day_diff",
                                "subValType": "percent",
                                "endStr": 'шт./д.',
                                "type": "with-interest"
                            }
                        ],
                    },
                    "col-5": {
                        "class": "an-col-4",
                        "CardItems": [
                            {
                                "name": "комиссия",
                                "val": "new_commission_wb_rub",
                                "subVal": "",
                                "subValType": "percent",
                                "endStr": 'rub',
                                "type": "with-interest"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "логистика",
                                "val": "new_logistics_cost",
                                "subVal": "",
                                "subValType": "percent",
                                "endStr": 'rub',
                                "type": "with-interest"
                            },
                            {
                                "name": "",
                                "val": "",
                                "type": "br"
                            },
                            {
                                "name": "налог",
                                "subName": "taxes",
                                "val": "new_taxes_rub",
                                "subVal": "",
                                "subValType": "percent",
                                "endStr": 'rub',
                                "type": "with-interest"
                            },
                        ],
                    }
                }
            }
        },
        "transfer-files-config": {
            "download": {
                'general': {
                    name: 'Товары',
                    description: 'Данные в формате Excel'
                },
                'price_management': {
                    name: 'Цены',
                    description: 'Данные в формате Excel'
                },
            },
            "upload": {
                'cost_price': {
                    name: 'Себестоимость',
                    filePath: 'cost_price',
                }
            }
        }
    },
    DashBoard: {
        "widgetItems": [
            {
                "type": "SingleLineChart",
                "color": "94D2FF",
                "dataSelector": "average_bill",
                "title": ['Средний чек'],
                "className": "an-col-8"
            },
            {
                "type": "SingleLineChart",
                "color": "1ECC6E",
                "dataSelector": ['marketplace_payments'],
                "title": ['Перечисление от маркетплейса'],
                "className": "an-col-8"
            },
            {
                "type": "StocksChart",
                "color": "",
                "dataSelector": "",
                "title": "",
                "className": "an-col-8"
            },
            {
                "type": "DoubleLineChart",
                "color": ['#ffd809', '#94D2FF'],
                "dataSelector": ['orders', 'sales'],
                "title": ['Заказы', 'Продажи'],
                "className": "an-col-24"
            },
            {
                "type": "DoubleBarChart",
                "color": "",
                "dataSelector": ['profit'],
                "title": ['Прибыль'],
                "className": "an-col-8"
            },
        ],
        "filterDateStart": {
            days: 14,
            dateSearchParam: 'first_interval_'
        },
        "filter-bar-config": {
            "filter": [
                "date-simple-first",
                "date-simple-second",
            ],
        },
    },
    DashBoardOrderFeed: {
        "checkbox": true,
        "cols": {
            "col-1": {
                "name": "Товар",
                "class": "an-col-6 goods-img-block",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "headerClass": "an-col-10 goods-img-block",
                "img": "cover", // false
                "CardItems": [
                    {
                        "name": "Название",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "item_name",
                        "type": "simple",
                    },
                    {
                        "name": "артикул",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "article",
                        "type": "simple"
                    },
                    {
                        "name": "id WB",
                        "displayMenu": true,
                        "shortView": true,
                        "val": "nomenclature",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "бренд",
                        "displayMenu": true,
                        "val": "brand",
                        "type": "simple"
                    },
                ],
                "HeaderItems": [
                    {
                        "name": "Название группы",
                        "displayMenu": true,
                        "simpleView": true,
                        "val": "article",
                        "type": "simple",
                    },
                    {
                        "name": "id WB",
                        "shortView": true,
                        "val": "nomenclature",
                        "type": "simple"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "бренд",
                        "displayMenu": true,
                        "val": "brand",
                        "type": "simple"
                    },
                    {
                        "name": "категория", // Тип товара
                        "displayMenu": true,
                        "val": "subject", //???
                        "subVal": "categories",
                        "type": "cat-card" //?????
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Товар',
                    "param": "sort",
                    "val": "subject",
                    "sortingAction": {
                        "name": "Выделить все",
                        "subName": "Снять выделение",
                        "actionType": "checkAll"
                    }
                }
            },
            "col-2": {
                "name": "Себестоимость",
                "class": "an-col-5",
                "headerClass": "d-none",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "CardItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        "shortView": true,
                        "val": "cost_price",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                ],
                "HeaderItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Стоимость',
                    "param": "sort",
                    "val": "price",
                    "sortingAction": {
                    }
                }
            },
            "col-3": {
                "name": "Цена",
                "class": "an-col-5",
                "headerClass": "an-col-6",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "CardItems": [
                    {
                        "name": "Цена",
                        "displayMenu": false,
                        "simpleView": true,
                        "shortView": true,
                        "val": "price_client",
                        "endStr": 'rub',
                        "type": "simple"
                    },
                ],
                "HeaderItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "simpleView": true,
                        'shortView': true,
                        "val": "rest_items", //?? rest_items - 92шт  delivery_to_user - к клиенту 2 шт.  delivery_from_user - от клиента 150 шт.
                        "type": "lights",
                        "subVal": [
                            "products_in_stock",
                            "products_running_out_of_stock",
                            "products_out_of_stock",
                        ]
                    },
                    {
                        "name": "свои остатки",
                        "displayMenu": false,
                        "val": "stock_storage",
                        "type": "simple"
                    },
                ],
                "sortingItem": {
                    "type": "simple",
                    "name": 'Остаток',
                    "param": "sort",
                    "val": "byStore",
                    "sortingAction": {
                        "name": "На всех складах",
                        "actionType": "storeSelector"

                    }
                }
            },
            "col-4": {
                "name": "Прибыль",
                "class": "an-col-4",
                "goodsDescriptionHeaderClass": "block-vertical-center",
                "CardItems": [
                    {
                        "name": "прибыль",
                        "displayMenu": true,
                        "val": "profit",
                        "endStr": 'rub',
                        "type": "simple",
                        "withColor": true
                    }
                ],
                "HeaderItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "прибыль",
                        "displayMenu": true,
                        "val": "profit",
                        "endStr": 'rub',
                        "type": "simple",
                        "withColor": true
                    }
                ],
                "sortingItem": {
                    // "type": "multi",
                    // "name": 'Скорость заказов',
                    // "param": "sort",
                    // "val": {
                    //     'bySpeed': 'Скорость заказов',
                    //     'byLostProfit': 'По упущенной прибыли',
                    // },
                    // "sortingAction": {
                    //     // "name": "На всех складах",
                    //     // "actionType": "storeSelector"
                    // }
                },
            },
            "col-5": {
                "name": "Кол-во",
                "class": "an-col-4",
                "goodsDescriptionHeaderClass": "block-all-center",
                "CardItems": [
                    {
                        "name": "",
                        "val": "count",
                        "displayMenu": false,
                        "simpleView": true,
                        "shortView": true,
                        "endStr": 'шт.',
                        "type": "quantity-input"
                    },
                ],
                "HeaderItems": [
                    {
                        "name": "",
                        "displayMenu": false,
                        "val": "",
                        "type": "br"
                    },
                    {
                        "name": "кол-во",
                        "displayMenu": true,
                        "val": "count",
                        "endStr": 'шт.',
                        "type": "simple",
                    },
                ],
                "sortingItem": {
                    // "type": "simple",
                    // "name": 'Прогноз поставки',
                    // "param": "sort",
                    // "val": "byForecast",
                    // "sortingAction": {
                    //     "name": "на 30 дней",
                    //     "actionType": "predict-days",
                    //     "defaultVal": 14,
                    //     "actionVal": [
                    //         14,
                    //         30,
                    //         'customRange'
                    //     ]
                    // }
                }
            }
        },
        // "filterDateStart": {
        //     days: 14,
        //     dateSearchParam: ''
        // },
        "filter-bar-config": {
            "filter": [
                "date-simple",
                "filter",
            ],
            "view-items": [
                "active",
                "count",
                "view-toggle",
                "view-settings"
            ]
        },
        "defaultSortParam": "-byForecast",
        "transfer-files-config": {
            "download": {
                'general': {
                    name: 'Товары',
                    description: 'Данные в формате Excel'
                },
                'price_management': {
                    name: 'Цены',
                    description: 'Данные в формате Excel'
                },
                'wb_deliveries': {
                    name: 'Заказ Wildberries',
                    description: 'Данные в формате Excel'
                }
            },
            "upload": {
                'on_wb_way': {
                    name: 'Заказ на пути в WB',
                    filePath: 'on_wb_way',
                },
                'seller_stocks': {
                    name: 'Остатки на своем складе',
                    filePath: 'seller_stocks',
                },
                'cost_price': {
                    name: 'Себестоимость',
                    filePath: 'cost_price',
                }
            }
        }
    },
}
const dashBoardConfig = {
    "widgetItems": [
        {
            "type": "SingleLineChart",
            "color": "94D2FF",
            "dataSelector": "average_bill",
            "title": ['Средний чек'],
            "className": "an-col-8"
        },
        {
            "type": "SingleLineChart",
            "color": "1ECC6E",
            "dataSelector": ['marketplace_payments'],
            "title": ['Перечисление от маркетплейса'],
            "className": "an-col-8"
        },
        {
            "type": "StocksChart",
            "color": "",
            "dataSelector": "",
            "title": "",
            "className": "an-col-8"
        },
        {
            "type": "DoubleLineChart",
            "color": ['#ffd809', '#94D2FF'],
            "dataSelector": ['orders', 'sales'],
            "title": ['Заказы', 'Продажи'],
            "className": "an-col-24"
        },
        {
            "type": "DoubleBarChart",
            "color": "",
            "dataSelector": ['profit'],
            "title": ['Прибыль'],
            "className": "an-col-8"
        },
    ],
    "filterDateStart": {
        days: 14,
        dateSearchParam: 'first_interval_'
    },
    "filter-bar-config": {
        "filter": [
            "date-simple-first",
            "date-simple-second",
            "date-space",
            "filter",
        ],
        "view-items": [
            "active",
            "count",
            "view-settings"
        ]
    },
}

const getRightSideMenuConfig = () => {
    let result = {}
    Object.keys(cardConfig).forEach((key) => {
        result[key] = {
            ...cardConfig[key]['transfer-files-config']
        }
    })
    return result
}
const rightMenu = handleActions({
    [actions.setRightMenuActive](state, { payload: { isActive, type } }) {
        return { ...state, isActive, type }
    },
    [actions.setFileUploadTab](state, { payload: { tabKey, storeName } }) {
        let newState = { ...state };

        Object.keys(newState.tabs[storeName].upload).forEach((key) => {
            newState.tabs[storeName].upload[key].active = key === tabKey
        })
        return {
            ...newState,
        }
    },
}, {
    isActive: false,
    type: '',
    // fileUploadTabs: {
    //     'on_wb_way': {
    //         active: true,
    //         name: 'заказ на пути в WB'
    //     },
    //     'seller_stocks': {
    //         active: false,
    //         name: 'остатки на своем складе'
    //     },
    //     'cost_price': {
    //         active: false,
    //         name: 'себестоимость'
    //     }
    // },
    // reportTabs: {
    //     'general': {
    //         name: '',
    //         active: true
    //     },
    //     'price_management': {
    //         name: '',
    //         active: false
    //     },
    //     'wb_deliveries': {
    //         name: '',
    //         active: false
    //     }
    // },
    tabs: {
        ...getRightSideMenuConfig()
    }
});

const uiState = handleActions({
    [actions.setSelectorsUiState](state, { payload: { selector, value } }) {
        const isActive = state.active.filter((key) => key === selector).length > 0;
        return {
            ...state,
            selectors: {
                ...state.selectors,
                [selector]: value
            }
        }
    },
    [actions.setUiState](state, { payload: { selector } }) {
        const isActive = state.active.filter((key) => key === selector).length > 0;
        return {
            ...state,
            active: isActive ? [] : [selector]
        }
    },
    [actions.forceClose](state, { }) {
        return {
            ...state,
            active: []
        }
    },

    [actions.setRightMenuActive](state, payload) {
        return {
            ...state,
            active: []
        }
    },
}, {
    active: [], selectors: {
        orders_count: 'byOrders',
        logistic_orders_count: 'byOrders',
        price_orders_count: 'byOrders'
    }
})

const cardCheckbox = handleActions({
    [actions.setCheckboxVal](state, { payload: { itemKey, currentVal, storeName } }) {
        let newVal = currentVal !== true;

        if (!newVal && !!state[storeName][itemKey]) {
            const { [itemKey]: { g }, ...newState } = state[storeName];
            return {
                ...state,
                [storeName]: {
                    ...newState
                }
            }
        }

        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                [itemKey]: {
                    active: newVal
                }
            }
        };
    },
    [actions.delInputState](state, { payload: { storeName } }) {
        return {};
    },
}, {})

const quantityInputs = handleActions({
    [actions.setQuantityInput](state, { payload: { value, itemKey, propVal, storeName } }) {
        value = parseInt(value) ? parseInt(value) : 0;
        let result = {
            [itemKey]: {
                [propVal]: value.length > 0 && value[0] === 0 ? value.slice(1) : value
            }
        }
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                ...result
            }
        };
    },
    [actions.delInputState](state, { payload: { storeName } }) {
        return {};
    },
}, {})


const listingPage = handleActions({
    [actions.fetchDataRequest](state, { payload: { storeName } }) {
        console.log('fetchDataRequest');
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                loading: true,
                pageReload: false,
                noData: false,
            }
        }
    },
    [actions.fetchDataSuccess](state, { payload: { result, filters, minDate, total, storeName, totalOutOfStock } }) {
        console.log('fetchDataSuccess');
        console.log(result);
        let newResult = []
        result.forEach((cardItem) => {
            newResult.push(cardItem)
        })
        let displayConfig = {
            displaySettings: {},
            currentPropsInCard: [],
            simpleView: [],
            shortView: [],
            viewType: 'full'
        }
        let emptyValCount = 0;
        const getPropsInCard = (cardKey, selector) => {
            Object.keys(selector).forEach((propKey) => {
                let prop = selector[propKey];
                let propVal = prop.val
                if (propVal === '') {
                    propVal = prop.type + '-' + emptyValCount;
                    emptyValCount++;
                }
                if (prop.displayMenu) {
                    displayConfig.displaySettings = {
                        ...displayConfig.displaySettings,
                        [cardConfig[storeName].cols[cardKey].name]: {
                            ...displayConfig.displaySettings[cardConfig[storeName].cols[cardKey].name],
                            [propVal]: prop.name
                        },
                    }
                }
                if (!!prop.simpleView) {
                    displayConfig = {
                        ...displayConfig,
                        simpleView: { ...displayConfig.simpleView, [propVal]: true }
                    }
                }
                if (!!prop.shortView) {
                    displayConfig = {
                        ...displayConfig,
                        shortView: { ...displayConfig.shortView, [propVal]: true }
                    }
                }
                displayConfig = {
                    ...displayConfig,
                    currentPropsInCard: { ...displayConfig.currentPropsInCard, [propVal]: true },
                }
            })
        }
        Object.keys(cardConfig[storeName].cols).forEach((cardKey) => {
            getPropsInCard(cardKey, cardConfig[storeName].cols[cardKey].CardItems)
            if (Object.keys(cardConfig[storeName].cols[cardKey]).indexOf('HeaderItems') >= 0) {
                getPropsInCard(cardKey, cardConfig[storeName].cols[cardKey]['HeaderItems'])
            }
        })
        let extra = false;
        let cardCheckbox = false;
        if (Object.keys(cardConfig[storeName]).indexOf('extra') >= 0) {
            extra = { ...cardConfig[storeName].extra }
        }
        if (Object.keys(cardConfig[storeName]).indexOf('checkbox') >= 0) {
            cardCheckbox = { ...cardConfig[storeName].checkbox }
        }
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                loading: false,
                pageReload: false,
                noData: false,
                goods: newResult,
                displayConfig: { ...displayConfig },
                filterBarConfig: cardConfig[storeName]["filter-bar-config"],
                filters: { ...filters },
                cardConfig: { ...cardConfig[storeName].cols },
                extra: extra,
                cardCheckbox,
                minDate,
                total,
                totalOutOfStock,
            },

        };
    },
    [actions.fetchDataFailure](state, { payload: { storeName } }) {
        console.log('fetchDataFailure');
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                loading: false,
                pageReload: false,
                noData: true,
            }
        }
    },
    [actions.setSimpleView](state, { payload: { type, storeName } }) {
        let newCurrentPropsInCard = {};
        if (type === 'simple') {
            Object.keys(state[storeName].displayConfig.currentPropsInCard).forEach((key) => {
                newCurrentPropsInCard[key] = false;
            })
        } else if (type === 'shortView') {
            Object.keys(state[storeName].displayConfig.currentPropsInCard).forEach((key) => {
                newCurrentPropsInCard[key] = false;
            })
        } else if (type === 'full') {
            Object.keys(state[storeName].displayConfig.currentPropsInCard).forEach((key) => {
                newCurrentPropsInCard[key] = true;
            })
        }
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                displayConfig: {
                    ...state[storeName].displayConfig,
                    viewType: type,
                    currentPropsInCard: {
                        ...newCurrentPropsInCard,
                        ...state[storeName].displayConfig[`${type === 'shortView' ? 'shortView' : 'simpleView'}`]
                    }
                },
            }

        }
        // return {...state}
    },
    [actions.setDisplayOption](state, { payload: { propId, storeName } }) {
        let currentPropState = state[storeName].displayConfig.currentPropsInCard[propId];
        let newPropVal = { [propId]: currentPropState !== true };
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                displayConfig: {
                    ...state[storeName].displayConfig,
                    currentPropsInCard: {
                        ...state[storeName].displayConfig.currentPropsInCard,
                        ...newPropVal
                    }
                },
            }
        }
    },
    [actions.initSearchState](state, { payload: { storeName, pageState = {} } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageInit: false,
                pageReload: true,
                ...pageState
            }
        } //pageReload: true
    },
    [actions.reloadListingPage](state, { payload: { storeName } }) {
        console.log('reloadListingPage:' + storeName);
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageInit: false,
                pageReload: true
            }
        }
    },
    [actions.delInitState](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageInit: true
            }
        } //pageReload: true
    },
    [actions.setSearchParam](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageInit: false,
                pageReload: true
            }
        } //pageReload: true
    },
    [actions.setSearchString](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageInit: false,
                pageReload: true
            }
        } //pageReload: true
    },
    [actions.setSortParam](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageReload: true,
                loading: true
            }
        }
    },
    [actions.setInStockSelector](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageInit: false,
                pageReload: true,
                loading: true
            }
        }
    },
    [actions.setPaginationParam](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageReload: true,
                loading: true
            }
        }
    },
    [actions.setDateParams](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageReload: true,
                loading: true
            }
        }
    },
    [actions.setPredictDaysParams](state, { payload: { storeName } }) {
        console.log('asdasdasdasdasdad!!!!!!!!!')
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pageReload: true,
                loading: true
            }
        }
    },
}, {
    // loading: true,
    // pageReload: true,
    // pageInit: true
})

const searchParams = handleActions({
    [actions.initConfig](state, { payload: { storeName } }) {
        return {
            ...state,
            [storeName]: {
                filterDateStart: cardConfig[storeName].filterDateStart,
                filterDateStartSecond: !!cardConfig[storeName].filterDateStartSecond ? cardConfig[storeName].filterDateStartSecond : null,
                defaultSortParam: !!cardConfig[storeName].defaultSortParam ? cardConfig[storeName].defaultSortParam : null,
            }

        };
    },
    [actions.initSearchState](state, { payload: { search, storeName } }) {
        const query = new URLSearchParams(search);
        let predictDays = query.get('predict_days') ? query.get('predict_days') : '';
        let sortParam = query.get('sort') ? query.get('sort') : '';
        let sortDirection = '';
        if (sortParam.indexOf('-') === 0) {
            // sortDirection = '-';
            // console.log('sortDirection => ', sortDirection)
            // sortParam = sortParam.substring(1);
        }
        let inStockSelectorState = query.get('selector') ? query.get('selector') : 'in_stock';
        let dateFrom = query.get('date_from') ? query.get('date_from') : '';
        let firstIntervalDateFrom = query.get('first_interval_date_from') ? query.get('first_interval_date_from') : '';
        let secondIntervalDateFrom = query.get('second_interval_date_from') ? query.get('second_interval_date_from') : '';
        let dateTo = query.get('date_to') ? query.get('date_to') : '';
        let firstIntervalDateTo = query.get('first_interval_date_to') ? query.get('first_interval_date_to') : '';
        let secondIntervalDateTo = query.get('second_interval_date_to') ? query.get('second_interval_date_to') : '';
        let page = query.get('page') ? query.get('page') : 1;
        let limit = query.get('limit') ? query.get('limit') : 17;
        let searchParams = query.keys();
        let filtersState = {};
        let allParams = [];

        for (let key of searchParams) {
            let searchParam = query.getAll(key);
            searchParam.forEach((param) => {
                if (allParams.indexOf(`${key}=${param}`) < 0 && key !== 'sort') {
                    allParams.push(`${key}=${param}`);
                }
                if (key !== 'sort' &&
                    key !== 'date_from' &&
                    key !== 'date_to' &&
                    key !== 'first_interval_date_from' &&
                    key !== 'first_interval_date_to' &&
                    key !== 'second_interval_date_from' &&
                    key !== 'second_interval_date_to' &&
                    key !== 'page' &&
                    key !== 'search' &&
                    key !== 'limit' &&
                    key !== 'api_key_id' &&
                    key !== 'selector' &&
                    key !== 'predict_days') {
                    filtersState[key] = { ...filtersState[key], [param]: true }
                }
            })
        }
        if (sortParam.length > 0) {
            sortDirection = sortParam.indexOf('-') < 0 ? '' : '-';
            allParams.push(`sort=${sortParam}`);
        }
        if (sortParam.indexOf('-') === 0) {
            // sortDirection = '-';
            // console.log('sortDirection => ', sortDirection)
            sortParam = sortParam.substring(1);
        }
        return {
            ...state,
            [storeName]: {
                sort: sortParam,
                allParams,
                sortDirection: sortDirection,
                filtersState,
                inStockSelectorState,
                pagination: {
                    page: page,
                    limit: limit,
                },
                predictDays,
                filterDateStart: cardConfig[storeName].filterDateStart,
                filterDateStartSecond: !!cardConfig[storeName].filterDateStartSecond ? cardConfig[storeName].filterDateStartSecond : null,
                defaultSortParam: !!cardConfig[storeName].defaultSortParam ? cardConfig[storeName].defaultSortParam : null,
                filterDate: {
                    dateFrom,
                    dateTo,
                    firstIntervalDateFrom,
                    secondIntervalDateFrom,
                    firstIntervalDateTo,
                    secondIntervalDateTo,
                }
            }

        }; //${allParamsLength > 0 ? '?' : ''}${allParams.join('&')}`} неверный подход если вставлять параметры в ссылку. не надо передавать сортировку
    },
    [actions.setSortParam](state, { payload: { search, isActive, newAllSearchParams, storeName } }) {
        let nextDirection = state[storeName].sortDirection === '-' ? '' : '-';
        let allParams = [];
        allParams.push(`sort=${nextDirection}${search}`);
        if (newAllSearchParams.length > 0) {
            newAllSearchParams.forEach(e => e.indexOf('sort') < 0 && allParams.push(e))
        }

        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                sort: search,
                sortDirection: isActive ? nextDirection : '-',
                allParams
            }
        }
    },
    [actions.setInStockSelector](state, { payload: { storeName, selector } }) {
        let newAllSearchParams = state[storeName].allParams;
        let allParams = [];
        allParams.push(`selector=${selector}`);
        if (newAllSearchParams.length > 0) {
            newAllSearchParams.forEach(e => e.indexOf('selector') < 0 && allParams.push(e))
        }
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                inStockSelectorState: selector,
                allParams
            }
        }
    },
    [actions.setPaginationParam](state, { payload: { page, limit, newLink, storeName } }) {

        let allParams = [...newLink];
        allParams.push(`page=${page}`);
        allParams.push(`limit=${limit}`);
        // let nextDirection = state[storeName].sortDirection === '-' ? '' : '-';

        // allParams.push(`sort=${nextDirection}${search}`);
        // if (newAllSearchParams.length > 0) {
        //     newAllSearchParams.forEach(e => e.indexOf('sort') < 0 && allParams.push(e))
        // }

        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                pagination: {
                    page: page,
                    limit: limit
                },
                allParams
            }
        }
    },
    [actions.setSearchParam](state, { payload: { itemKey, currentVal, filterName, storeName } }) {
        let newVal = currentVal !== true;
        let newAllParams = [...state[storeName].allParams];
        newAllParams = newAllParams.filter((param) => param.indexOf('page') < 0 && param.indexOf('limit') < 0);
        if (newVal) {
            newAllParams.push(`${filterName}=${itemKey}`);
        } else {
            newAllParams = newAllParams.filter((e) => e.indexOf(`${filterName}=${itemKey}`) < 0);
        }
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                filtersState: {
                    ...state[storeName].filtersState,
                    [filterName]: {
                        ...state[storeName].filtersState[filterName],
                        [itemKey]: newVal
                    }
                },
                allParams: newAllParams
            }
        };
    },
    [actions.setSearchString](state, { payload: { currentVal, storeName } }) {
        let newAllParams = [...state[storeName].allParams];
        newAllParams = newAllParams.filter((param) => param.indexOf('page') < 0 && param.indexOf('limit') < 0 && param.indexOf(`search`) < 0);
        if (currentVal !== '') {
            newAllParams.push(`search=${currentVal}`);
        }
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                allParams: newAllParams
            }
        };
    },
    [actions.setDateParams](state, { payload: { date_from, date_to, searchParamName, storeName } }) {
        let newAllParams = [...state[storeName].allParams];
        let paramKey = `${searchParamName === 'first_interval_' ? 'firstInterval' : searchParamName === 'second_interval_' ? 'secondInterval' : ''}`
        console.log('searchParamName: ' + searchParamName);
        let fromKey = `${paramKey}${!!paramKey ? 'DateFrom' : 'dateFrom'}`;
        let toKey = `${paramKey}${!!paramKey ? 'DateTo' : 'dateTo'}`;

        newAllParams = newAllParams.filter((param) =>
            param.indexOf(`${searchParamName}date_from`) < 0 &&
            param.indexOf(`${searchParamName}date_to`) < 0 &&
            param.indexOf('page') < 0 &&
            param.indexOf('limit') < 0);

        if (!!date_from && !!date_to) {
            newAllParams.push(`${searchParamName}date_from=${date_from}`)
            newAllParams.push(`${searchParamName}date_to=${date_to}`)
        }

        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                allParams: newAllParams,
                filterDate: {
                    ...state[storeName].filterDate,
                    [fromKey]: !date_from ? '' : date_from,
                    [toKey]: !date_to ? '' : date_to,
                }
            }
        };
    },
    [actions.setPredictDaysParams](state, { payload: { predictDays, storeName } }) {
        let newAllParams = [...state[storeName].allParams];
        newAllParams = newAllParams.filter((param) => param.indexOf('predict_days') < 0 && param.indexOf('page') < 0 && param.indexOf('limit') < 0);
        newAllParams.push(`predict_days=${predictDays}`)
        return {
            ...state,
            [storeName]: {
                ...state[storeName],
                allParams: newAllParams,
                predictDays
            }
        };
    }
}, {
    // sort: '',
    // sortDirection: '-',
    // allParams: '',
    // filtersState: {}
})

export default combineReducers({
    searchParams,
    rightMenu,
    listingPage,
    quantityInputs,
    cardCheckbox,
    uiState
});
