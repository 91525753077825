import React, {useState, useContext, useEffect} from 'react'
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import {Notification} from "../components/Notification";
import {BsEyeSlash, BsEye} from 'react-icons/bs';

export const AuthPage = () => {
    const lang = {
        email: 'Электронная почта',
        password: 'Пароль',
        rePasswd: 'Повторный пароль',
        last_name: 'Фамилия',
        first_name: 'Имя',
        phone: 'Номер телефона',
        second_name: 'Отчетство',
        login: 'Электронная почта'
    }
    const auth = useContext(AuthContext);
    const {loading, error, request} = useHttp();

    const [form, setForm] = useState({
        open: 'auth',
        auth: {
            data: {
                login: '',
                password: ''
            }
        },
        reg: {
            data: {
                email: '',
                password: '',
                // rePasswd: '',
                last_name: '',
                first_name: '',
                phone: '',
                // second_name: ''
            }
        }
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleChangeForm = (type) => (event) => {
        setForm({...form,
            [type]: {
                data: {
                    ...form[type].data,
                    [event.target.name]: event.target.value
                }
            }
        })
    };
    const handleOpen = (type) => () => {
        setForm({...form,
            open: type
        })
        setShowPassword(false);
    }
    const authHandler = async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/login/`, 'POST', {...form.auth.data})
            const dataTariffs = await request(`https://${process.env.REACT_APP_DOMAIN}/api/tariffs/`, 'GET', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${data.jwt}`
            });
            const hasActiveTariff = dataTariffs['tariffs'].filter((tariff) => !!tariff['expire_at']).length > 0;
            auth.login(data.jwt, data.userId, hasActiveTariff) // другие параметры
        } catch (e) {}
    }
    const resetHandler = async () => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/reset-password-request/`, 'POST', {email: form.auth.data.login})
            console.log('data ====>', data);
            setForm({...form, open: 'reset-confirm'})
        } catch (e) {}
    }
    const regHandler = async () => {
        let isValid = true;
        let regData = {...form.reg.data};
        let errMsg = '';
        if(regData.password.length < 8) {
            isValid = false;
        }
        errMsg = (<>
            {Object.keys(regData).map((key) => {
                if(regData[key].length === 0) {
                    isValid = false;
                   return <div>{`${lang[key]} - пусто`}</div>
                } else {
                    return null
                }
            })}
            {regData.password.length < 8 &&
                <div>{`Пароль должен быть не менее 8 знаков`}</div>
            }
        </>);
        if(!isValid) {
            Notification({msg: errMsg})
        } else {
            try {
                const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/register/`, 'POST', {...form.reg.data})
                console.log(data);
                if(data) {
                    let auth = {
                        auth: {
                            data: {
                                login: form.reg.data.email,
                                password: form.reg.data.password
                            }
                        }
                    }
                    setForm(
                        {
                            ...form,
                            ...auth,
                            open: 'validate',
                        }
                    )
                    console.log(form.auth.data);
                }
            } catch (e) {}
        }
    }
    const togglePasswordShow = () => {
        setShowPassword(x => !x);
    }
    return (
        <div className="onboarding-wrapper">
            <div className="onboarding">
                {form.open === 'auth' &&
                <>
                    <div className="onboarding-h">
                        Авторизация
                    </div>
                    <form className="onboarding-form" onSubmit={(e) => e.preventDefault()}>
                        <label htmlFor="login-email">{lang.login}</label>
                        <input
                            autoComplete="on"
                            className="form-input"
                            type="email"
                            name="login"
                            value={form.auth.data.login}
                            id="login-email"
                            placeholder="Введите эл. почту"
                            autoComplete="username"
                            onChange={handleChangeForm('auth')}
                        />  
                        <label htmlFor="login-password">{lang.password}</label>
                        <div>
                            <input
                                autoComplete="on"
                                className="form-input full-width padding-r-35"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={form.auth.data.password}
                                id="login-password"
                                placeholder="Введите пароль"
                                autoComplete="current-password"
                                onChange={handleChangeForm('auth')}
                            />
                            {
                                showPassword ?
                                <BsEye 
                                    className='eye'
                                    onClick={togglePasswordShow}
                                />
                                : <BsEyeSlash 
                                    className='eye'
                                    onClick={togglePasswordShow}
                                />
                            }
                        </div>
                        <div className="btn-block">
                            <button className="btn btn-blue" onClick={authHandler} disabled={loading}>Войти</button>
                            <button className="btn btn-stroke-blue" onClick={handleOpen('reg')} disabled={loading}>Зарегистрироваться</button>
                        </div>
                        <p className="color-dark-blue">Авторизуясь в сервисе Wolbe, вы соглашаетесь с политикой обработки персональных данных</p>
                        <br />
                        <div className="under-dashed" onClick={handleOpen('reset-password')}>Забыли пароль?</div>
                    </form>
                </>
                }
                {form.open === 'reset-password' &&
                <>
                    <div className="onboarding-h">
                        Сброс пароля
                    </div>
                    <form className="onboarding-form" onSubmit={(e) => e.preventDefault()}>
                        <label htmlFor="login-email">{lang.login}</label>
                        <input
                            autoComplete="on"
                            className="form-input"
                            type="email"
                            name="login"
                            value={form.auth.data.login}
                            id="login-email"
                            placeholder="Введите эл. почту"
                            autoComplete="username"
                            onChange={handleChangeForm('auth')}
                        />
                        <div className="btn-block">
                            <button className="btn btn-blue" onClick={resetHandler} disabled={loading}>Отправить</button>
                            <button className="btn btn-stroke-blue" onClick={handleOpen('auth')} disabled={loading}>Назад</button>
                        </div>
                    </form>
                </>
                }
                {form.open === 'reg' &&
                <>
                    <div className="onboarding-h">
                        Регистрация
                    </div>
                    <form className="onboarding-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="input-group">
                            <div className="input-group-item">
                                <label htmlFor="login-name">{lang.first_name}</label>
                                <input
                                    autoComplete="on"
                                    className="form-input"
                                    type="text"
                                    id="login-name"
                                    placeholder="Имя"
                                    name="first_name"
                                    value={form.reg.data.first_name}
                                    onChange={handleChangeForm('reg')}
                                />
                            </div>
                            <div className="input-group-item">
                                <label htmlFor="login-last_name">{lang.last_name}</label>
                                <input
                                    autoComplete="on"
                                    className="form-input"
                                    type="text"
                                    id="login-last_name"
                                    placeholder="Фамилия"
                                    name="last_name"
                                    value={form.reg.data.last_name}
                                    onChange={handleChangeForm('reg')}
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-item">
                                <label htmlFor="login-email">{lang.email}</label>
                                <input
                                    autoComplete="on"
                                    className="form-input"
                                    type="email"
                                    id="login-email"
                                    placeholder="Введите эл. почту"
                                    name="email"
                                    value={form.reg.data.email}
                                    onChange={handleChangeForm('reg')}
                                />
                            </div>
                            <div className="input-group-item">
                                <label htmlFor="login-phone">{lang.phone}</label>
                                <input
                                    autoComplete="on"
                                    className="form-input"
                                    type="text"
                                    id="login-phone"
                                    placeholder="Введите номер телефона"
                                    name="phone"
                                    value={form.reg.data.phone}
                                    onChange={handleChangeForm('reg')}
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-item">
                                <label htmlFor="login-password">{lang.password}</label>
                                <div>
                                    <input
                                        autoComplete="on"
                                        className="form-input padding-r-35"
                                        type={showPassword ? "text" : "password"}
                                        id="login-password"
                                        placeholder="Введите пароль"
                                        name="password"
                                        value={form.reg.data.password}
                                        onChange={handleChangeForm('reg')}
                                    />
                                    {
                                        showPassword ?
                                        <BsEye 
                                            className='eye'
                                            onClick={togglePasswordShow}
                                        />
                                        : <BsEyeSlash 
                                            className='eye'
                                            onClick={togglePasswordShow}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="input-group-item">
                            </div>
                        </div>
                        <div className="btn-block">
                            <button className="btn btn-blue"
                                    onClick={regHandler}
                                    disabled={loading}>
                                Зарегистрироваться
                            </button>
                            <button className="btn btn-stroke-blue"
                                    onClick={handleOpen('auth')}
                                    disabled={loading}>
                                Войти
                            </button>
                        </div>
                        <p className="color-dark-blue">Регистрируясь в сервисе Wolbe, вы соглашаетесь с политикой обработки персональных данных</p>
                    </form>
                </>
                }
                {form.open === 'validate' &&
                    <>
                        <div className="onboarding-h">
                            Вы зарегистрированы
                        </div>
                        <div className="onboarding-form">
                            <p className="color-dark-blue">Для активации учетной записи пройдите по ссылке в письме.</p>
                        </div>
                    </>
                }
                {form.open === 'reset-confirm' &&
                <>
                    <div className="onboarding-h">
                        Обновление пароля
                    </div>
                    <div className="onboarding-form">
                        <p className="color-dark-blue">Пожалуйста пройдите по ссылке в письме, для изменения пароля</p>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

