import {useState} from "react";

export const useOnboarding = () => {  
  const [isAccOpenTour, setAccOpenTour] = useState(
    !localStorage.getItem('showAccOnboarding') 
    && window.location.pathname.includes('/personal/token'));
  const closeAccTour = () => {
    localStorage.removeItem('accStep');
    localStorage.setItem('showAccOnboarding', 'true')
    setAccOpenTour(false);
  }
  const openAccTour = () => {
    setAccOpenTour(true);
  }
  
  const [isSupForecastOpenTour, setSupForecastOpenTour] = useState(
    !localStorage.getItem('showSupForecastOnboarding') 
    && window.location.pathname.includes('/delivery-forecast')
  );
  const closeSupForecastTour = () => {
    localStorage.removeItem('supForecastStep');
    localStorage.setItem('showSupForecastOnboarding', 'true')
    setSupForecastOpenTour(false);
  }
  const openSupForecastTour = () => {
    setSupForecastOpenTour(true);
  }

  return { 
    account: { 
      isOpenTour: isAccOpenTour,
      closeTour: closeAccTour,
      openTour: openAccTour,
    },
    supplyForecast: {
      isOpenTour: isSupForecastOpenTour,
      closeTour: closeSupForecastTour,
      openTour: openSupForecastTour,
    },
  };
}