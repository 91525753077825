import {createContext} from 'react';

function noop() {};

export const OnboardingContext = createContext({
  account: {
    isOpenTour: false,
    closeTour: noop,
    openTour: noop,
  },
  supplyForecast: {
    isOpenTour: false,
    closeTour: noop,
    openTour: noop,
  }
});