import React, {useContext} from "react";
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import {TariffItem} from "../components/TariffItem";

export const Tariffs = ({allTariffs}) => {
    const {jwt} = useContext(AuthContext);
    const {request, loading} = useHttp();
    const payHandler = async (tariffId) => {
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/yookassa/create-pay/`, 'POST', {
                    "tariff_duration_id": tariffId,
                    "confirmation":
                        {"return_url": "http://localhost:3000/personal/tariffs/{uuid_id}/"}
                }, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            });
            document.location.href = data.confirmation.confirmation_url;
        } catch (e) {}
    }
    return (
        <>
            {allTariffs.length > 0 &&
            <>
                {allTariffs.map((tariff, index) => <TariffItem tariff={tariff} payHandler={payHandler} key={`tariff-${index}`}/>)}
            </>
            }
        </>
    )
}
