import React from "react";

export const Loader = ({label = true, small = false, middle = false}) => {
    if (small) {
        return <div className="simple-loader" />
    }

    if (middle) {
        return <div className="middle-loader" />
    }
    
    return (
        <div className="loader">
            <div className="lds-spinner">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
            {
                label &&
                <div className="loader-txt">Загрузка может занять 1-2 минуты</div>
            }
        </div>
    )
}
