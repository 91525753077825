import React from 'react';

export const TableHeader = () => {

  return (
    <div className="an-row">
      <div className="an-col-6 goods-img-block">
        <div className="goods-description">
          Товар
        </div>
      </div>

      <div className="an-col-5">
        <div className="goods-description">
          <div className="prop-item">
            Себестоимость
          </div>
        </div>
      </div>
      
      <div className="an-col-5">
        <div className="goods-description">
          <div className="prop-item">
            Цена
          </div>
        </div>
      </div>
      
      <div className="an-col-4">
        <div className="goods-description">
          <div className="prop-item">
            Прибыль
          </div>
        </div>
      </div>
      
      <div className="an-col-4">
        <div className="goods-description">
          <div className="prop-item">
            Кол-во
          </div>
        </div>
      </div>
    </div>
  )
}