import React, { useContext, useEffect, useState } from 'react'
import { LineChart, BarChart, Bar, Line, CartesianGrid, YAxis, XAxis, ResponsiveContainer } from 'recharts';
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook";
import { TokensContext } from "../context/TokensContext";
import FilterBar from "../components/FilterBar";
import { FilterBarDashboard } from "../components/FilterBarDashboard";
import moment from "moment";
import { Loader } from "../components/Loader";
import { Orders } from '../components/Dashboard/Orders';
import { Sales } from '../components/Dashboard/Sales';
import { Refunds } from '../components/Dashboard/Refunds';
import { ProfitForecast } from '../components/Dashboard/ProfitForecast';
import { Profit } from '../components/Dashboard/Profit';
import { Remains } from '../components/Dashboard/Remains';
import { GoodItem } from '../components/Dashboard/GoodItem';
import { TableHeader } from '../components/Dashboard/TableHeader';
import { TableFilters } from '../components/Dashboard/TableFilters';

let formatter = new Intl.NumberFormat("ru", {
    // maximumSignificantDigits: 3,
    maximumFractionDigits: 1
});

const prefixSelector = [
    'first_interval',
    'second_interval'
];

const colors = [
    '#ffd809',
    '#1ECC6E',
    '#ffd809',
    '#1ECC6E',
];

const getChartMinMax = ({ dataSelector, data }) => {
    let dataMin = 0;
    let dataMax = 0;

    prefixSelector.forEach((prefix) => {
        dataSelector.forEach((selector, index) => {
            data.forEach((item, itemIndex) => {
                const itemSelector = `${prefix}_${selector}`;
                dataMin = parseInt(item[itemSelector]) < dataMin || !dataMin ? parseInt(item[itemSelector]) : dataMin;
                dataMax = parseInt(item[itemSelector]) > dataMax || !dataMax ? parseInt(item[itemSelector]) : dataMax;
            })
        })
    })
    return ({ dataMin, dataMax });
}

const getFullLineSelector = ({ dataSelector }) => {
    let fullDataSelector = []
    prefixSelector.forEach((prefix) => {
        dataSelector.forEach((selector) => {
            fullDataSelector.push([prefix, selector]);
        })
    })
    return fullDataSelector;
}

const StocksChart = ({ chartData }) => {
    let lineCOnf = {
        deliveryFromUser: 0,
        deliveryToUser: 0
    }
    if (!!chartData) {
        // console.log(chartData);
        let deliveryTotal = chartData['first_interval']['delivery_from_user'] + chartData['first_interval']['delivery_to_user']
        lineCOnf = {
            ...lineCOnf,
            deliveryFromUser: chartData['first_interval']['delivery_from_user'] * 100 / deliveryTotal,
            deliveryToUser: chartData['first_interval']['delivery_to_user'] * 100 / deliveryTotal,
        }
        // console.log(lineCOnf);
    }
    return (
        <div className="stocks-chart dashboard-block">
            <div className="chart-label">
                Остаток на складе
            </div>
            <div className="chart-val">{`${formatter.format(chartData['first_interval']['rest_items'])} шт.`}</div>
            <div className="status-line-legend-val">{`Розничная: ${formatter.format(chartData['first_interval']['rest_items_sale_price'])} p.`}</div>
            <div className="chart-label-small">{`Себестоимость: ${formatter.format(chartData['first_interval']['rest_items_cost_price'])} p.`}</div>
            <div className="goods-status-line">
                <div className="red-line" style={{ width: `${lineCOnf.deliveryFromUser}%` }}>{ }</div>
                <div className="green-line" style={{ width: `${lineCOnf.deliveryToUser}%` }}>{ }</div>
            </div>
            <div className="status-line-legend">
                <div className="status-line-legend-item">
                    <div className="status-line-legend-val">{`${formatter.format(chartData['first_interval']['delivery_from_user'])} шт.`}</div>
                    <div className="chart-label-small">едет от клиента</div>
                </div>
                <div className="status-line-legend-item">
                    <div className="status-line-legend-val">{`${formatter.format(chartData['first_interval']['delivery_to_user'])} шт.`}</div>
                    <div className="chart-label-small">едет к клиенту</div>
                </div>
            </div>
        </div>
    )
}
const SingleLineChart = ({ title, color, val, diff, data, dataSelector, endStr = 'шт.' }) => {

    const { dataMin, dataMax } = getChartMinMax({ data, dataSelector });
    const fullLineSelectors = getFullLineSelector({ dataSelector });

    const CustomizedAxisTick = (props) => {
        // console.log(props)
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#767678" >
                    {payload.value}
                </text>
            </g>
        );
    }
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${payload[0].payload.date} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };


    return (
        <div className={`single-line-chart dashboard-block`}>
            <div className="single-line-chart-label">
                <div className="label-val">
                    <div className="dot-status" style={{ backgroundColor: color }}>{ }</div>
                    <div className="chart-val">{formatter.format(val)} {endStr === 'rub' ? <span className={'rub-font-a'} /> : endStr}</div>
                    <div className={`label-diff ${diff < 0 ? 'color-red' : 'color-green'}`}>{`${diff > 0 ? '+' : ''}${Math.round(diff)}`}</div>
                </div>
                <div className="chart-label-small">{title}</div>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart width={340} height={140} data={data}>
                    <CartesianGrid strokeDasharray="7 7" vertical={false} />
                    <YAxis hide={true} domain={[dataMin, dataMax]} />
                    {fullLineSelectors.map((selector, index) => (
                        <Line
                            key={selector.join('_')}
                            // connectNulls
                            type="monotone" // ровные грани
                            isAnimationActive={false}
                            dot={{ stroke: color, strokeWidth: 1, r: 3 }}
                            dataKey={selector.join('_')}
                            fill={color}
                            stroke={color}
                            strokeDasharray={selector[0] === 'second_interval' ? '5 5' : null}
                            strokeWidth={2} />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

}
const DoubleLineChart = ({ title, color, val, diff, data, dataSelector, endStr = 'шт.' }) => {
    const { dataMin, dataMax } = getChartMinMax({ data, dataSelector });
    const fullLineSelectors = getFullLineSelector({ dataSelector });

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${payload[0].payload.date} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };


    return (
        <div className={`double-bar-chart dashboard-block`}>
            <div className="double-bar-chart-label">
                {/*<div className="chart-label">{title}</div>*/}
                <div className="label-val">
                    <div className="dot-status" style={{ backgroundColor: color[0] }}>{ }</div>
                    <div className="chart-val">{formatter.format(val[0])} {endStr === 'rub' ? <span className={'rub-font-a'} /> : endStr}</div>
                    {/*<div className="label-diff">+{diff[0]}</div>*/}
                    <div className={`label-diff ${diff[0] < 0 ? 'color-red' : 'color-green'}`}>{`${diff[0] > 0 ? '+' : ''}${Math.round(diff[0])}`}</div>
                </div>
                <div className="chart-label-small">{title[0]}</div>
                <div className="label-val">
                    <div className="dot-status" style={{ backgroundColor: color[1] }}>{ }</div>
                    <div className="chart-val">{formatter.format(val[1])} {endStr === 'rub' ? <span className={'rub-font-a'} /> : endStr}</div>
                    {/*<div className="label-diff">+{diff[1]}</div>*/}
                    <div className={`label-diff ${diff[1] < 0 ? 'color-red' : 'color-green'}`}>{`${diff[1] > 0 ? '+' : ''}${Math.round(diff[1])}`}</div>
                </div>
                <div className="chart-label-small">{title[1]}</div>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart width={340}
                    height={140}
                    data={data}>
                    <CartesianGrid strokeDasharray="7 7" vertical={false} />
                    <YAxis hide={true}
                        domain={[dataMin, dataMax]}
                    />
                    {/*<XAxis dataKey="first_interval_date" interval={0} angle={30} dx={20} />*/}
                    {/*<Tooltip content={<CustomTooltip />} />*/}

                    {fullLineSelectors.map((selector, index) => (
                        <Line
                            key={selector.join('_')}
                            // connectNulls
                            type="monotone" // ровные грани
                            isAnimationActive={false}
                            dot={{ stroke: colors[index], strokeWidth: 1, r: 3 }}
                            dataKey={selector.join('_')}
                            fill={colors[index]}
                            strokeDasharray={selector[0] === 'second_interval' ? '5 5' : null}
                            stroke={colors[index]}
                            strokeWidth={2} />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

}
const DoubleBarChart = ({ title, data }) => {

    let barConf = {
        color: [
            '#1ECC6E',
            '#F97C6B',
            '#d6ffc5',
            '#fcc5bd',
        ],
        title: [
            'чистая прибыль',
            'упущенная прибыль',
        ],
        dataSelector: [
            'gross_profit',
            'lost_profit',
        ],
        data: [],
        val: [],
        diff: [],
    }
    barConf = {
        ...barConf,
        data: data.chart,
        val: [
            data.first_interval.sum_gross_profit,
            data.first_interval.sum_lost_profit,
        ],
        delta: [
            data.first_interval.gross_profit_delta,
            data.first_interval.lost_profit_delta
        ]
    }
    return (
        <div className={`double-bar-chart dashboard-block`}>
            <div className="double-bar-chart-label">
                <div className="chart-label">{title}</div>
                <div className="label-val">
                    <div className="dot-status" style={{ backgroundColor: barConf.color[0] }}>{ }</div>
                    <div className="chart-val">{formatter.format(barConf.val[0])} <span className={'rub-font-a'} /></div>
                    {/*<div className="label-diff">+{barConf.delta[0]}</div>*/}
                    <div className={`label-diff ${barConf.delta[0] < 0 ? 'color-red' : 'color-green'}`}>{`${barConf.delta[0] > 0 ? '+' : ''}${Math.round(barConf.delta[0])}`}</div>
                </div>
                <div className="chart-label-small">{barConf.title[0]}</div>
                <div className="label-val">
                    <div className="dot-status" style={{ backgroundColor: barConf.color[1] }}>{ }</div>
                    <div className="chart-val">{formatter.format(barConf.val[1])} <span className={'rub-font-a'} /></div>
                    {/*<div className="label-diff">+{barConf.delta[1]}</div>*/}
                    <div className={`label-diff ${barConf.delta[1] < 0 ? 'color-red' : 'color-green'}`}>{`${barConf.delta[1] > 0 ? '+' : ''}${Math.round(barConf.delta[1])}`}</div>
                </div>
                <div className="chart-label-small">{barConf.title[1]}</div>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={340}
                    height={140}
                    data={barConf.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="7 7" vertical={false} />
                    {/*<Tooltip />*/}
                    <Bar dataKey={`first_interval_${barConf.dataSelector[0]}`} barSize={8} fill={barConf.color[0]} />
                    <Bar dataKey={`first_interval_${barConf.dataSelector[1]}`} barSize={8} fill={barConf.color[1]} />
                    <Bar dataKey={`second_interval_${barConf.dataSelector[0]}`} barSize={8} fill={barConf.color[2]} />
                    <Bar dataKey={`second_interval_${barConf.dataSelector[1]}`} barSize={8} fill={barConf.color[3]} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )

}

const demoData = [
    {
        img: 'https://images.wbstatic.net/tm/new/12410000/12418372-1.jpg',
        name: 'test 1',
        id: 1231,
        costPrice: 1,
        price: 312,
        profit: 311,
        count: 2,
    },
    {
        img: 'https://images.wbstatic.net/tm/new/12410000/12418372-1.jpg',
        name: 'test 2',
        id: 123,
        costPrice: 123,
        price: 312312,
        profit: 3111,
        count: 2,
    },
    {
        img: 'https://images.wbstatic.net/tm/new/12410000/12418372-2.jpg',
        name: 'test 3',
        id: 333,
        costPrice: 1,
        price: 312,
        profit: 333,
        count: 2,
    },
]

export const DashBoard = () => {
    const { jwt } = useContext(AuthContext);
    const { activeToken } = useContext(TokensContext);
    const { request } = useHttp();
    const [chartData, setChartData] = useState(null);
    const [allSearchParams, setAllSearchParams] = useState([]);
    const [loading, setLoading] = useState(false);
    // console.log("moment() => ", moment().format('YYYY-MM-DD'))
    const [filterDate, setFilterDate] = useState({
        "first_interval_date_from": moment().subtract(7, 'days').format('YYYY-MM-DD'),
        "first_interval_date_to": moment().subtract(7, 'days').format('YYYY-MM-DD'),
        "second_interval_date_from": moment().format('YYYY-MM-DD'),
        "second_interval_date_to": moment().format('YYYY-MM-DD'),
        "minDate": ""
    });
    const setDateHandler = ({ date_from, date_to, searchParamName }) => {
        console.log(date_from, date_to, searchParamName);
        // setFilterDate((prev) => (
        //     {
        //         ...prev,
        //         [`${searchParamName}date_from`]: date_from,
        //         [`${searchParamName}date_to`]: date_to,
        //     }
        // ))
    }
    // console.log(filterDate);
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        let searchParams = query.keys();
        let result = [];
        let newFilterDate = {};
        for (let key of searchParams) {
            if (
                // key === 'api_key_id') ||
                key === 'first_interval_date_from' ||
                key === 'first_interval_date_to' ||
                key === 'second_interval_date_from' ||
                key === 'second_interval_date_to') {
                result.push(`${[key]}=${query.get(key)}`);
                newFilterDate[key] = query.get(key);

            }
        }
        setAllSearchParams(result);
        setFilterDate((prev) => (
            {
                ...prev,
                ...newFilterDate
            }
        ))
    }, [window.location.search])

    console.log('DASHBOARD_');
    // const intervalStr = '&first_interval_date_to=2021-07-23&first_interval_date_from=2021-07-15&second_interval_date_to=2021-07-18&second_interval_date_from=2021-07-07'
    const intervalStr = `&${allSearchParams.join('&')}`
    const getChart = async () => {
        try {
            const resp = await request(`https://${process.env.REACT_APP_DOMAIN}/api/dashboard/?api_key_id=${activeToken.id}${intervalStr}`, 'GET', null, {
                'Access-Control-Allow-Origin': '*', Authorization: `${jwt}`
            });
            const { data, min_date } = resp
            // console.log(min_date);
            setChartData(data);
            setFilterDate((prev) => ({
                ...prev,
                minDate: min_date
            }));
        } 
        catch (e) { }
        finally { setLoading(false) }
    }

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            getChart();
        }
    }, [jwt, activeToken, allSearchParams])

    if (!activeToken) {
        return null
    }
    if (!chartData) {
        // console.log(lineCOnf);
        return <Loader />
    }

    return (
        <div className="content-section">
            <FilterBarDashboard settings={{ storeName: 'DashBoard' }}
                filterBarConfig={{
                    "filter": [
                        "date-simple-first",
                        "date-simple-second",
                    ],
                }}
                minDate={filterDate.minDate}
                allSearchParams={allSearchParams}
                filterDate={filterDate}
                setDateHandler={setDateHandler}
            />

            <div className='an-p-row an-row'>
                <div className="an-col-8 h-100">
                    <div className="flex-col h-100">
                        <Orders
                            priceOne={500000}
                            countOne={500}
                            priceTwo={400000}
                            countTwo={550}
                        />
                    </div>
                </div>
                <div className="an-col-8 h-100">
                    <div className="flex-col h-100">
                        <Sales
                            priceOne={500000}
                            countOne={500}
                            priceTwo={400000}
                            countTwo={550}
                        />
                    </div>
                </div>
                <div className="an-col-8 h-100">
                    <div className="flex-col h-100">
                        <Refunds
                            priceOne={50000}
                            countOne={50}
                            priceTwo={45000}
                            countTwo={35}
                        />
                    </div>
                </div>
            </div>

            <div className='an-p-row an-row'>
                <div className="an-col-8 h-100">
                    <div className="flex-col h-100">
                        <ProfitForecast
                            priceOne={500000}
                            countOne={500}
                            priceTwo={400000}
                            countTwo={550}
                        />
                    </div>
                </div>
                <div className="an-col-8 h-100">
                    <div className="flex-col h-100">
                        <Profit
                            profit={100000}
                            profitDiff={10}
                            fees={200000}
                            feesDiff={-123}
                        />
                    </div>
                </div>
                <div className="an-col-8 h-100">
                    <div className="flex-col h-100">
                        <Remains
                            ownPrice={1000000}
                            retail={500000}
                            count={32131}
                        />
                    </div>
                </div>
            </div>

            {!!chartData &&
                <div className="an-row an-p-row">
                    {/* <div className="an-col-8">
                        <SingleLineChart title={'Средний чек'}
                            color={'#94D2FF'}
                            chartData={chartData['bills']}
                            val={chartData.bills.first_interval.average_bill_value}
                            diff={chartData.bills.first_interval.average_bill_delta}
                            data={chartData.bills.chart}
                            dataSelector={['average_bill']}
                            endStr={'rub'}
                        />
                    </div> */}
                    {/* <div className="an-col-8">
                        <SingleLineChart title={'Перечисление от маркетплейса'}
                            color={'#1ECC6E'}
                            val={chartData.marketplace_payments.first_interval.sum_marketplace_payments}
                            diff={chartData.marketplace_payments.first_interval.marketplace_payments_delta}
                            data={chartData.marketplace_payments.chart}
                            dataSelector={['marketplace_payments']}
                            endStr={'rub'}
                        />
                    </div> */}
                    {/* <div className="an-col-8">
                        <StocksChart chartData={chartData['stocks']} />
                    </div> */}
                    <div className="an-col-24">
                        <DoubleLineChart
                            title={['Заказы', 'Продажи']}
                            color={['#ffd809', '#94D2FF']}
                            val={[chartData.activity.first_interval.sum_orders, chartData.activity.first_interval.sum_sales]}
                            diff={[chartData.activity.first_interval.orders_delta, chartData.activity.first_interval.sales_delta]}
                            data={chartData.activity.chart}
                            dataSelector={['orders', 'sales']}
                            endStr={'шт.'}
                        />
                    </div>
                    <div className="an-col-24">
                        <DoubleBarChart title={'Прибыль'} data={chartData.profit} />
                    </div>
                </div>
            }

            <div className='table'>
                <TableHeader />
                <TableFilters />
                {demoData.map(x => (
                    <GoodItem key={x.id} data={x} />
                ))}
            </div>
        </div>
    )

}
