import React, {useState} from 'react'
import {useHttp} from "../hooks/http.hook";
import {useParams} from "react-router-dom";
import {Notification} from "../components/Notification";

export const ResetPasswordPage = () => {
    const {loading, error, request} = useHttp();
    const [form, setForm] = useState({new_password: ''});
    const resetToken = useParams().id;
    const activateHandler = async () => {
        if(form.new_password.length === 0) {
            Notification({msg: 'Слишком короткий пароль'})
            return
        }
        try {
            const data = await request(`https://${process.env.REACT_APP_DOMAIN}/api/reset-password/${resetToken}`, 'POST', {...form})
            console.log('data ======> ',  data)
            if (!!data) {
                document.location.href = '/';
            }
        } catch (e) {
            console.log('error => ', e);
        }
    }
    const handleChangeForm = () => (event) => {
        setForm({...form,
            new_password: event.target.value
        })
    };
    return (
        <div className="onboarding-wrapper">
            <div className="onboarding">
                <div className="onboarding-h">
                    Сброс пароля
                </div>
                <div className="onboarding-form">
                    <form className="onboarding-form" onSubmit={(e) => e.preventDefault()}>
                        <label htmlFor="login-email">Новый пароль</label>
                        <input
                            autocomplete="on"
                            className="form-input"
                            type="password"
                            name="new_password"
                            value={form.new_password}
                            id="new_password"
                            placeholder="Введите новый пароль"
                            onChange={handleChangeForm('auth')}
                        />
                        <div className="btn-block">
                            <button className="btn btn-blue" onClick={activateHandler} disabled={loading}>Отправить</button>
                            <button className="btn btn-stroke-blue" onClick={() => document.location.href = '/'} disabled={loading}>Вернуться на главную</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

