// @ts-check

import React, { useContext, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import useRoutes from './routes';
import {Navbar} from "./components/Navbar";
import Topbar from "./components/Topbar";
import {useAuth} from "./hooks/auth.hook";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tour from "reactour";
import { OnboardingContext } from './context/OnboardingContext';
import { AuthContext } from './context/AuthContext';

const AppContent = ({ routes }) => {
    const {jwt, hasActiveTariff} = useAuth();
    const isAuthenticated = !!jwt;
    const { account, supplyForecast } = useContext(OnboardingContext);
    const history = useHistory();
    const { isDevShow } = useContext(AuthContext);

    const tourConfigAcc = [
        {
            selector: '[data-tut="wb_api"]',
            content: `Надо добавить wildberries api ключ`,  
        },
        {
            selector: '[data-tut="choose_token"]',
            content: 'выберите активный токен для отображения данных',
        },
        {
            selector: '[data-tut="choose_menu"]',
            content: 'здесь вы можете выбрать интересующий раздел меню',
        },
        {
            selector: '[data-tut="delivery_forecast"]',
            content: 'давайте выберем прогноз поставок',
        },
        {
            selector: '',
            content: 'повторите эти действия и мы продолжим ознакомление на странице Прогноза поставок',
        },
    ];

    const tourConfigSupForecast = [
        {
            selector: '[data-tut="account"]',
            content: 'для настройки прогноза поставок надо перейти на страницу пользователя',
        },
        {
            selector: '[data-tut="filters"]',
            content: 'для удобства отображения данных можете использовать фильтры',
        },
        {
            selector: '[data-tut="finished_in_sale"]',
            content: 'можете выбрать одно из значений закончились / в продаже',
        },
        {
            selector: '[data-tut="view_types"]',
            content: 'поменять виды отображения данных',
        },
        {
            selector: '[data-tut="download_report"]',
            content: 'выполнить загрузку/выгрузку отчетов с выделением товаров',
            position: 'right', // TODO: doesn't work... 
        },
        {
            selector: '[data-tut="forecast_period"]',
            content: 'изменить период прогноза',
        },
        {
            selector: '',
            content: 'это всё! удачного пользования!',
        },
    ];
    useEffect(() => {
        if (!localStorage.getItem('showSupForecastOnboarding') && window.location.pathname.includes('/delivery-forecast')) {
            setTimeout(() => supplyForecast.openTour(), 0.5 * 1000);
        }
    }, [window.location.pathname])

    return (
    <>
        {
            isDevShow &&
            <Tour
                onRequestClose={() => account.closeTour()}
                steps={tourConfigAcc}
                isOpen={account.isOpenTour}
                className="helper"
                rounded={5}
                // accentColor={accentColor}
                // onAfterOpen={this.disableBody}
                // onBeforeClose={this.enableBody}
                startAt={account.step}
                goToStep={account.step}
            />
        }
        {
            isDevShow &&
            <Tour
                onRequestClose={() => supplyForecast.closeTour()}
                steps={tourConfigSupForecast}
                isOpen={supplyForecast.isOpenTour}
                className="helper"
                rounded={5}
                // accentColor={accentColor}
                // onAfterOpen={this.disableBody}
                // onBeforeClose={this.enableBody}
                startAt={supplyForecast.step}
                goToStep={supplyForecast.step}
            />
        }
        <ToastContainer />
        {isAuthenticated && <Navbar />}
        <div className="main-content">
            {isAuthenticated && <Topbar />}
            {routes}
        </div>
      </>
    )
};
export default AppContent;

